import { CombinedState, combineReducers, Reducer, ReducersMapObject } from 'redux'
import counterReducer from './counter/counterSlice'
import authenticationReducer from './auth/authenticationSlice'
import questionnaireReducer from './questionnaire/questionnaireSlice'
import userAttributeReducer from './user/userAttributesSlice'

import * as namedReducer from './namedReducer'
import thunkMiddleware from 'redux-thunk'
import safeStringify from './string/safeStringify'
// import { createStore, combineReducers, applyMiddleware } from 'redux';

// import todosReducer from './features/todos/todosSlice'
// import filtersReducer from './features/filters/filtersSlice'

// https://redux.js.org/tutorials/fundamentals/part-3-state-actions-reducers#combinereducers
// https://redux.js.org/tutorials/essentials/part-2-app-structure
// https://redux.js.org/tutorials/essentials/part-2-app-structure

const compositeReducersMap = (() => {
  const _: ReducersMapObject<{[x: string]: any}, any> = {}
  _[namedReducer.COUNTER] = counterReducer
  _[namedReducer.AUTHENTICATION] = authenticationReducer
  _[namedReducer.QUESTIONNAIRE] = questionnaireReducer
  _[namedReducer.USER_ATTRIBUTES] = userAttributeReducer

  console.log(`compositeReducersMap will be assigned to: ${safeStringify(_)}`)
  return _
})()

// unknown vs any https://stackoverflow.com/questions/51439843/unknown-vs-any
const rootReducer: Reducer<CombinedState<{[key: string]: unknown}>, never> = combineReducers(
  // Remember, the key names you give to combineReducers
  // decides what the key names of your state object will be!
  // Define a top-level state field named `todos`, handled by `todosReducer`
  // todos: todosReducer,
  // filters: filtersReducer
  compositeReducersMap
)

export default rootReducer
