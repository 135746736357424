import { useState } from 'react';
import { getLogger } from '../log/getLogger'
import { useStore } from "react-redux";
import { useSelector } from "react-redux";
import { SbhcCompositeState } from '../state/SbhcCompositeState';
const LOG = getLogger('useQuestionnaireSubmitted')


/**
 * Custom Hook
 * @returns
 */
export const useQuestionnaireSubmitted = () => {
  const store = useStore();

  const realtimeQuestionnaireSubmittedFlag: boolean | undefined = useSelector((state: SbhcCompositeState) => {
    const retval = state?.questionnaire?.questionnaireSubmittedFlag ?? undefined
    LOG.debug(`realtimeQuestionnaireSubmittedFlag() - Returning ${retval}`)
    return retval
  })
  LOG.debug(`useQuestionnaireSubmitted() - It's deemed that the questionnaire has been submitted.  (Based on state?.questionnaire?.questionnaireSubmittedFlag)  Navigating to /findings`)

  const getter = (): boolean | undefined => {
    const retval = realtimeQuestionnaireSubmittedFlag
    LOG.debug(`useQuestionnaireSubmitted()::[GETTER]: Returning ${retval}`)
    return retval
  }
  const [questionnaireSubmitted, setQuestionnaireSubmitted] = useState(getter());

  const updateFn = (value: boolean) => {
    setQuestionnaireSubmitted(value);
  };

  return [questionnaireSubmitted, updateFn] as const;
};
