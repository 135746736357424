import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  decrement,
  increment,
  // incrementByAmount,
  // incrementAsync,
  selectCount
} from './counterSlice'
// import styles from './Counter.module.css'

// https://redux.js.org/tutorials/typescript-quick-start

export function Counter () {
  const currentCountAsOfRightNow: number = useSelector(selectCount)
  const dispatch = useDispatch()
  const [incrementAmount, setIncrementAmount] = useState('2')

  return (
    <div>
      <div>
        <button
          aria-label='Increment value'
          onClick={() => dispatch(increment())}
        >
          Increment
        </button>
        <span>{currentCountAsOfRightNow}</span>
        <button
          aria-label='Decrement value'
          onClick={() => dispatch(decrement())}
        >
          Decrement
        </button>
      </div>
      {/* omit additional rendering output here */}
    </div>
  )
}
