import { createStore, applyMiddleware, Store, Action, AnyAction, CombinedState } from 'redux'
import rootReducer from './reducer'
// import thunkMiddleware, { ThunkDispatch } from 'redux-thunk'
import thunkMiddleware from 'redux-thunk'

import { ThunkMiddlewareFor } from '@reduxjs/toolkit/dist/getDefaultMiddleware'
import { SbhcCompositeState } from './state/SbhcCompositeState'
import { getLogger } from './log/getLogger'
// import logger from 'redux-logger';
// import { composeWithDevTools } from 'redux-devtools-extension'
// https://redux.js.org/tutorials/fundamentals/part-6-async-logic

// type DispatchFunctionType = ThunkDispatch<StateType, undefined, AnyAction>

// import { composeWithDevTools } from 'redux-devtools-extension'
// const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))
// https://redux.js.org/tutorials/fundamentals/part-6-async-logic
// const delayedActionMiddleware = storeAPI => next => action => {
// applyMiddleware(thunkMiddleware)
// const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))
const middlewareEnhancer = applyMiddleware(thunkMiddleware)

const LOG = getLogger('store.ts')

let persistedState: SbhcCompositeState; 
try {
  persistedState = localStorage.getItem('reduxState') 
                        ? JSON.parse(localStorage.getItem('reduxState') as string)
                        : {}
  LOG.debug(`Existing state from local/sessionStorage = ${JSON.stringify(persistedState)}`)                       
} catch(err) {
  LOG.error(`Error parsing = ${localStorage.getItem('reduxState')}.   Reason err = ${err}`)                       
  throw err
}


// createStore vs configureStore
// https://stackoverflow.com/questions/62238338/how-do-i-resolve-property-type-is-missing-in-type-asyncthunkaction-using-re
// const store: Store<Redux.Store.Definition> = createStore(rootReducer, middlewareEnhancer)
// https://stackoverflow.com/questions/65273021/unhandled-rejection-typeerror-illegal-operation-attempted-on-a-revoked-proxy
// https://stackoverflow.com/questions/35305661/where-to-write-to-localstorage-in-a-redux-app
const store = createStore(
  rootReducer,
  persistedState as unknown as {[key: string]: unknown}, 
  middlewareEnhancer as any
)

store.subscribe(()=>{
  LOG.debug(`Redux state has changed...`)     
  try {
    localStorage.setItem('reduxState', JSON.stringify(store.getState()))
  } catch (err) {
    LOG.error(`Error.  Unable to JSON.stringify reduxState = ${store.getState()}.   Reason err = ${err}`)
    throw err
  } 
})

// https://stackoverflow.com/questions/35305661/where-to-write-to-localstorage-in-a-redux-app
// https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage
export default store

// import { configureStore } from '@reduxjs/toolkit'
// import { configureStore } from '@reduxjs/toolkit'
// import counterReducer from '../features/counter/counterSlice'
// export default configureStore({
//   reducer: {
//     counter: counterReducer
//   }
// })

// https://redux.js.org/usage/usage-with-typescript

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
