
import { useSelector } from "react-redux";
import { WhoAmISvc } from "../auth/WhoAmISvc";
import { QuestionnaireAttributes } from "../commonSrc/questionnaire/allQuestionnaireAttributes";
import { DeveloperAuthDetailComponent } from "../developer/DeveloperAuthDetailComponent";
import DI_TYPES from "../diTypes";
import diContainer from '../inversify.config'
import { SbhcCompositeState } from "../state/SbhcCompositeState";

export default function RiPageComponent () {
  const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
  const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
  const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
  const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()


  const realtimeQuestionnaireSubmittedFlag: boolean | undefined = useSelector((state: SbhcCompositeState) => {
    return state?.questionnaire?.questionnaireSubmittedFlag ?? undefined
  })

  const realtimeQuestionnaireState: QuestionnaireAttributes = useSelector((state: SbhcCompositeState) => {
    return state?.questionnaire
  })

  return (
    <>
      <div>loggedInFlag = {loggedInFlag ? 'TRUE' : 'FALSE'}</div>
      <div>oauth2Username = {oauth2Username}</div>
      <div>oauth2UserId = {oauth2UserId}</div>
      <div>REACT_APP_BACKEND_BASE_URL = {process.env.REACT_APP_BACKEND_BASE_URL}</div>
      <div>REACT_APP_BACKEND_RELATIVE_API_PATH = {process.env.REACT_APP_BACKEND_RELATIVE_API_PATH}</div>
      <div>REACT_APP_AUTH0_DOMAIN = {process.env.REACT_APP_AUTH0_DOMAIN}</div>
      <div>REACT_APP_AUTH0_CLIENT_ID = {process.env.REACT_APP_AUTH0_CLIENT_ID}</div>
      <div>REACT_APP_AUTH0_SUCCESS_REDIRECT_URL = {process.env.REACT_APP_AUTH0_SUCCESS_REDIRECT_URL}</div>
      <div>REACT_APP_AUTH0_AUDIENCE = {process.env.REACT_APP_AUTH0_AUDIENCE}</div>
      <div>REACT_APP_ONLY_SAVE_NO_SUBMIT = {process.env.REACT_APP_ONLY_SAVE_NO_SUBMIT}</div>

      { realtimeQuestionnaireSubmittedFlag != null && 
        <div>realtimeQuestionnaireSubmittedFlag = {realtimeQuestionnaireSubmittedFlag.toString()}</div>
      }
      { realtimeQuestionnaireSubmittedFlag == null && 
        <div>realtimeQuestionnaireSubmittedFlag = nullish</div>
      }
      <div>
        <h1>state?.questionnaire</h1>
        <pre>
          { realtimeQuestionnaireState != null && 
            JSON.stringify(realtimeQuestionnaireState, null, 2) 
          }
        </pre>
      </div>
      <br/>
      <br/>
      <div className="card">
        <div className="card-body">
          <DeveloperAuthDetailComponent/>
        </div>
      </div>
    </>
  )
}