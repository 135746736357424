import { createSlice } from '@reduxjs/toolkit'
import * as namedReducer from '../namedReducer'

export const counterSlice = createSlice({
  name: namedReducer.COUNTER,
  initialState: {
    value: 0
  },
  reducers: {
    increment: (state: any) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
      console.log(`counterSlice::state = ${JSON.stringify(state)}`)
    },
    decrement: (state: any) => {
      state.value -= 1
    },
    incrementByAmount: (state: any, action: any) => {
      state.value += action.payload
    }
  }
})

export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default counterSlice.reducer

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCount = (state: any) => state[namedReducer.COUNTER].value
