import { getLogger } from '../log/getLogger'
import React, { useEffect, useState } from "react";
import { WhoAmISvc } from '../auth/WhoAmISvc';
import diContainer from '../inversify.config'
import DI_TYPES from "../diTypes";
import * as bearerUtil from '../commonSrc/auth/bearerUtil'
import { setMedication, selectMedication } from '../user/userAttributesSlice'
import safeStringify from '../string/safeStringify';
import { useDispatch, useSelector } from 'react-redux';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { current } from '@reduxjs/toolkit';
import { useNavigate } from "react-router";

const LOG = getLogger('medication.UpdateMedicationComponent')




export default function UpdateMedicationComponent () {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    // tryContactingCognito()
    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
    const jwtToken = whoAmISvc.getMyAuthToken()
    console.log(`jwtToken = ${jwtToken}`)
    const parsedJwtIdToken: any | null = bearerUtil.parseJwt(jwtToken as string)
    console.log(`parsedJwtIdToken = ${JSON.stringify(parsedJwtIdToken, null, 2)}`)
  }, []);
  

  
  const currentMedicationInState: string = useSelector((state: SbhcCompositeState) => {
    return selectMedication(state)
  })

  const handleSaveThenNext = async () => {
    await handleSave()
    navigate(`/agreement`, { replace: true });
  }


  const handleSave = async () => {
    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
    const jwtToken = whoAmISvc.getMyAuthToken()
    const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
    const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
    const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

    const headers: any = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    }

    if (whoAmISvc.amILoggedIn()) {
      LOG.debug(`handleSave(): User is signed in.  jwtToken = ${whoAmISvc.getMyAuthToken()}`)
      headers["Authorization"] = `Bearer ${whoAmISvc.defGetMyAuthToken()}`
    } else {
      LOG.warn(`handleSave(): Unauthorized 401.  User is NOT signed in.`)
    }

    LOG.debug(`handleSave(): currentMedicationInState = ${currentMedicationInState}`)

    const requestBodyAsString = JSON.stringify({
      medication: currentMedicationInState
    })

    const requestOptions = {
      method: 'POST',
      // mode: 'cors',
      headers: headers,
      body: requestBodyAsString
    };
    LOG.debug(`handleSave(): Sending POST request with body = ${requestBodyAsString}`)


    const url = (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:9000') + `${process.env.REACT_APP_BACKEND_RELATIVE_API_PATH}` + '/userAttributes'


    return fetch(url, requestOptions).then(response => response.json())
  }


  return (
    <>
      <header className="page-header">
        <div className="inner">
          <img src="Basic-Info.svg" alt="" />
          <div className="text-block">
            <h1>Join the Study</h1>
            <p>There is a little bit of paperwork we need to get you enrolled in the study.</p>
          </div>
        </div>
      </header>

      <section className="content-block-container">
        <div className="questionnaire-content">
          <div className="card">
            <header>
              <h2>Update Medications</h2>
            </header>
            <div className="inner">
              <div className="field">
                <label htmlFor="medication">List the medications you are taking along with the dosage and frequency</label>
                <textarea className="form-control" id="medication" rows={10} value={currentMedicationInState} onChange={(evt: React.FormEvent<HTMLTextAreaElement>) => { console.log(`medication = ${(evt.target as HTMLTextAreaElement).value}.`); dispatch(setMedication((evt.target as HTMLTextAreaElement).value as any))}}/>
              </div>  
            </div>
          </div>
          <div className="mt-4 button-block">
            <button className="btn btn-primary btn-monique" onClick={handleSaveThenNext}>
              Next
            </button>
          </div>
        </div>
      </section>
    </>
  )
}