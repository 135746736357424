const DI_TYPES = {
  ExistingDiagnosticAnswerFetcher: Symbol('ExistingDiagnosticAnswerFetcher'),
  CurrentDiagnosticAnswerSaver: Symbol('CurrentDiagnosticAnswerSaver'),
  WhoAmISvc: Symbol('WhoAmISvc'),
  WhoAmIWriteService: Symbol('WhoAmIWriteService'),
  SignInSvc: Symbol('SignInSvc'),
  SbNotificationMessageGenerator: Symbol('SbNotificationMessageGenerator'),
  SbNotificationPublisher: Symbol('SbNotificationPublisher'),
  ToastNotificationSubscriber: Symbol('ToastNotificationSubscriber'),
  InitialHypothesisFetcher: Symbol('InitialHypothesisFetcher'),
  HypothesisDecorationSvc: Symbol('HypothesisDecorationSvc'),
  UserAttributeFetchingService: Symbol('UserAttributeFetchingService'),
  NextPageFlowFinderService: Symbol('NextPageFlowFinderService')
}

export default DI_TYPES