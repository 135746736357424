
import { useSearchParams } from "react-router-dom";
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useLocation, Location } from "react-router-dom";
import diContainer from '../inversify.config'
import { WhoAmISvc } from "../auth/WhoAmISvc";
import DI_TYPES from "../diTypes";
import { SignInSvc } from "../auth/SignInSvc";
import { useDispatch } from "react-redux";
import { setIdToken } from "../auth/authenticationSlice";
import { getLogger } from "../log/getLogger";
import { fetchExistingDiagnosticAnswers } from '../questionnaire/questionnaireSlice'
import store from '../store'
import { Link } from "react-router-dom"

const LOG = getLogger('auth.AuthenticatedComponent')

export default function AuthenticatedComponent () {
  const signInSvc: SignInSvc = diContainer.get<SignInSvc>(DI_TYPES.SignInSvc)
  // This component represents the /signin/callback page
  {
    const location: Location = useLocation();
    LOG.debug(`location = ${location}`)
    const oauth2JwtToken: string | null | undefined = signInSvc.getOauth2IdToken(location)
    LOG.debug(`Will dispatch oauth2JwtToken = ${oauth2JwtToken}`)
    
    const dispatch = useDispatch()
    dispatch(setIdToken(oauth2JwtToken));
    // (store.dispatch as any)(fetchExistingDiagnosticAnswers)
    (store.dispatch as any)(fetchExistingDiagnosticAnswers)
  }

  const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
  const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
  let oauth2Username: string | undefined = whoAmISvc.getMyUsername()
  const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

  let pendingRedirectTimeout: any;

  // setTimeout( () => {
  //   window.location.href = "/"
  // }, 700);

  // useEffect(() => {
  //   // window.location.href = window.location.origin  
  //   oauth2Username = whoAmISvc.getMyUsername()
  // }, []);

  useEffect(() => {
    pendingRedirectTimeout = setTimeout(()=> {
      window.location.href = "/" 
    }, 3000)  
  }, []);

  const cancelPendingRedirectTimer = function() {
    clearTimeout(pendingRedirectTimeout);
  }

  return (
    <>
      <div><a onClick={cancelPendingRedirectTimer}><div className="loading">Completing sign-in</div></a></div>
    </>
  )
}