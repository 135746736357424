export class NextPageFlowDetail {
  public readonly whatPageShouldIBeLookingAt: string
  
  constructor( attr: NextPageFlowDetailConstructionAttr ) {
    this.whatPageShouldIBeLookingAt = attr.whatPageShouldIBeLookingAt
  }
}

export interface NextPageFlowDetailConstructionAttr {
  whatPageShouldIBeLookingAt: string
}