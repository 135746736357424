import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthenticationSliceOfState } from '../authenticate/AuthenticationSliceOfState'
import { getLogger } from '../log/getLogger'
import safeStringify from '../string/safeStringify'

// https://dev.to/skd1993/creating-a-simple-login-function-with-redux-and-thunk-in-react-native-33ib

const log = getLogger('authentication/authenticationSlice')


const INITIAL_AUTHENTICATION_SLICE_OF_STATE: AuthenticationSliceOfState = {
  idToken: ''
}

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: INITIAL_AUTHENTICATION_SLICE_OF_STATE,
  reducers: {
    setIdToken: (state: AuthenticationSliceOfState, action: PayloadAction<any>) => {
      log.debug(`setIdToken(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.idToken = action.payload
      return state
    },

    clearIdToken: (state: AuthenticationSliceOfState) => {
      log.debug(`clearIdToken(): state = ${safeStringify(state)}`)
      state.idToken = ''
      return state
    },


    // setAuthenticatedUser: (state: any, action: any) => {
    //   // state.value += action.payload
    // }

    // https://dev.to/skd1993/creating-a-simple-login-function-with-redux-and-thunk-in-react-native-33ib
    // loginReducer: (state = initialState, action) => {
    //   switch (action.type) {
    //     case t.SET_LOGIN_STATE:
    //       return {
    //         ...state,
    //         ...action.payload, // this is what we expect to get back from API call and login page input
    //         isLoggedIn: true, // we set this as true on login
    //       };
    //     default:
    //       return state;
    //   }
    // };

  }
})

export const { 
  // setAuthenticatedUser, 
  setIdToken,
  clearIdToken
} = authenticationSlice.actions

export default authenticationSlice.reducer

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUsername = (state: any) => {
  console.log(`selectUsername(): state = ${JSON.stringify(state)}`)
  return 'Bob Was Here'
}
