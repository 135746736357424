import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import diContainer from '../inversify.config'
import DI_TYPES from "../diTypes";
import { ToastNotificationSubscriber } from './ToastNotificationSubscriber';
import { SbNotification } from '../commonSrc/notification/SbNotification';
import { getLogger } from "../log/getLogger";
const LOG = getLogger('notification.ToastDisplayerComponent')

export default function ToastDisplayerComponent () {
  const toastNotificationSubscriber: ToastNotificationSubscriber = diContainer.get<ToastNotificationSubscriber>(DI_TYPES.ToastNotificationSubscriber)

  toastNotificationSubscriber.getToastBusObservable().subscribe((next: SbNotification) => {
    LOG.debug(`Sending toast message...`)
    toast(next.getHtmlMessage());
  })

  return (
    <>
      <ToastContainer />
    </>
  )
}