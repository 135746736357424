import { Link, Outlet, useSearchParams } from "react-router-dom";
import TopNavbarComponent from "../navigation/TopNavbarComponent";
import ToastDisplayerComponent from "../notification/ToastDisplayerComponent";
import { getLogger } from '../log/getLogger'
import { useDeveloperMode } from '../developer/useDeveloperMode'
import { isHasNotTrue, isHasTrue, isTrueLike, isFalseLike } from "../commonSrc/boolean/booleanUtil";
const log = getLogger('DefaultLayoutComponent')

function DefaultLayoutComponent () {
  const [searchParams, setSearchParams] = useSearchParams();
  const devModeQueryParam: string | null = searchParams.get("devmode") || searchParams.get("devMode")
  log.debug(`QuestionnaireComponent(): Entering with devModeQueryParam = ${devModeQueryParam}`)
  
  const [developerMode, updateDeveloperMode] = useDeveloperMode();
  if (isTrueLike(devModeQueryParam!)) {
    log.debug(`QuestionnaireComponent(): ENABLING developer mode`)
    updateDeveloperMode(true)
  } else if (isFalseLike(devModeQueryParam!)) {
    log.debug(`QuestionnaireComponent(): DISABLING developer mode`)
    updateDeveloperMode(false)
  }

  let removingDevMode = false
  if (searchParams.get("devmode") != null) {
    searchParams.delete('devmode');
    removingDevMode = true
  }
  if (searchParams.get("devMode") != null) {
    searchParams.delete('devMode');
    removingDevMode = true
  }

  if (removingDevMode) {
    setSearchParams(searchParams);
  }

  return (
    <>
      <ToastDisplayerComponent />
      <div>
        <TopNavbarComponent/>
      </div>
      <div style={{minHeight: '960px'}}>
        <Outlet />
      </div>
     <footer>
        <p>Copyright 2023 Secon<Link to={`/devmode`} className="no-design">d</Link> Brain Healthcare</p>   
        <ul>
          <li><a target="_blank"href="/SB-001-CL-102_Informed_Consent_Form_09Jan2024.pdf">Informed Consent</a></li>
          <li><a target="_blank" href="/SB_Website_Privacy_Policy.pdf">Privacy Policy</a></li>
        </ul> 
     </footer>
    </>
  )
}

export default DefaultLayoutComponent