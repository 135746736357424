import { getLogger } from './log/getLogger'
import React, { useEffect, useState } from "react";
import { WhoAmISvc } from './auth/WhoAmISvc';
import diContainer from './inversify.config'
import DI_TYPES from "./diTypes";
import * as bearerUtil from './commonSrc/auth/bearerUtil'
import { setMedication, selectMedication } from './user/userAttributesSlice'
import safeStringify from './string/safeStringify';
import { useDispatch, useSelector } from 'react-redux';
import { SbhcCompositeState } from './state/SbhcCompositeState';
import { current } from '@reduxjs/toolkit';

const LOG = getLogger('CommunicationPolicyComponent')

// https://stackoverflow.com/questions/33188994/scroll-to-the-top-of-the-page-after-render-in-react-js
export default function CommunicationPolicyComponent () {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="container-fluid text-section">
    <h1>Communication Policy</h1>
    <p>By accepting this Policy, I hereby give my consent for Second Brain Healthcare, Inc. (“Visana”) to communicate with me through the methods outlined in this document. I understand that these communications may involve the use and disclosure of protected health information (“PHI”) about me to carry out treatment, payment and health care operations (“TPO”). I further understand that I may change my communication preferences with Visana at any time.<br/><br/><strong>Telephone</strong><br/>With this consent, Visana may call my home number, mobile number or other alternative number and leave a message on voicemail in reference to any items that assist Visana in carrying out TPO, such as reminders, insurance items, shipment and delivery of supplies, health profile updates and any calls pertaining to my care, including, but not limited to, conversations with Second Brain Healthcare Coaches.<br/>‍<br/><strong>Text</strong><br/>With this consent, Visana may contact me via unencrypted SMS text messages in reference to any items that assist Visana in carrying out TPO, such as reminders, insurance items, shipment and delivery of supplies, health profile updates and any communications pertaining to my care, including text messages exchanged with Second Brain Healthcare Coaches and automated text message campaigns.<br/>‍<br/><strong>Email</strong><br/>With this consent, Visana may contact me via unencrypted email in reference to any items that assist Visana in carrying out TPO, such as reminders, insurance items, shipment and delivery of supplies, health profile updates and any communications pertaining to my care, including information exchanged with Second Brain Healthcare Coaches and email message campaigns.</p>

    </div>
    </>
  )
}