import 'reflect-metadata'
import { SbNotificationPublisher } from "../commonSrc/notification/SbNotificationPublisher";
import { SbNotificationCode } from "../commonSrc/notification/SbNotificationCode";
import { SbNotification } from "../commonSrc/notification/SbNotification";
import { SbNotificationImpl } from "../commonSrc/notification/SbNotificationImpl";
import { Observable, Subject } from 'rxjs'
import { ToastNotificationSubscriber } from "./ToastNotificationSubscriber";
import { injectable, inject } from "inversify";
import DI_TYPES from "../diTypes";
import { SbNotificationMessageGenerator } from "../commonSrc/notification/SbNotificationMessageGenerator";
// import container from "../inversify.config";
import * as uuid from 'uuid'

@injectable()
export class SbNotificationPubSubImpl implements SbNotificationPublisher, ToastNotificationSubscriber{
  private readonly sendToToastSubject: Subject<SbNotification>
  @inject(DI_TYPES.SbNotificationMessageGenerator) private sbNotificationMessageGenerator: SbNotificationMessageGenerator | undefined

  constructor(
    // https://github.com/vercel/next.js/issues/4707
    // https://stackoverflow.com/questions/69640894/cra-inversify-inject-module-parse-failed-unexpected-character
    // @inject(DI_TYPES.SbNotificationMessageGenerator) sbNotificationMessageGenerator: SbNotificationMessageGenerator
  ) {
    this.sendToToastSubject = new Subject<SbNotification>()
    // const sbNotificationMessageGenerator: SbNotificationMessageGenerator = container.get<SbNotificationMessageGenerator>(DI_TYPES.SbNotificationMessageGenerator)
    // this.sbNotificationMessageGenerator = sbNotificationMessageGenerator
    // this.sbNotificationMessageGenerator = container.get<SbNotificationMessageGenerator>(DI_TYPES.SbNotificationMessageGenerator)
  }

  public fireAndForgetErrorByCode(code: SbNotificationCode): void {
    // TODO:  Replace constructor with factory
    const sbNotification: SbNotification = new SbNotificationImpl({id: uuid.v4(), code: code}, this.sbNotificationMessageGenerator!)
    this.sendToToastSubject.next(sbNotification)
  }

  public getToastBusObservable(): Observable<SbNotification> {
    return this.sendToToastSubject.asObservable()
  }

}