import { useAuth0 } from "@auth0/auth0-react"
import React, { useEffect } from 'react'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'
import { Dispatch } from 'redux'
import AgreementComponent from './agreement/AgreementComponent'
import Auth0CallbackPageComponent from './auth/Auth0CallbackPageComponent'
import Auth0SignInComponent from './auth/Auth0SigninComponent'
import Auth0SignUpPageRedirector from './auth/Auth0SignUpPageRedirector'
import ProfileComponent from './auth/ProfileComponent'
import RouteRequiresLogin from './auth/RouteRequiresLogin'
import SignIn from './auth/SignIn'
import SignOutWidgetComponent from './auth/SignOutWidgetComponent'
import { WhoAmISvc } from './auth/WhoAmISvc'
import { WhoAmIWriteService } from './auth/WhoAmIWriteService'
import AuthenticatedComponent from './authenticate/AuthenticatedComponent'
import { Counter } from './counter/Counter'
import DevModeComponent from './developer/DevModeComponent'
import { Homepage } from './home/Homepage'
import InitialHypothesisComponent from './hypothesis/InitialHypothesisComponent'
import UpdateInsuranceComponent from './insurance/UpdateInsuranceComponent'
import diContainer from './inversify.config'
import DI_TYPES from './diTypes'
import DefaultLayoutComponent from './layout/DefaultLayoutComponent'
import { getLogger } from './log/getLogger'
import UpdateMedicationComponent from './medication/UpdateMedicationComponent'
import { PaidSearchLandingPageComponent } from './PaidSearchLandingPageComponent'
import PrivacyPolicyComponent from './privacy/PrivacyPolicyComponent'
import QuestionnaireComponent from './questionnaire/QuestionnaireComponent'
import { fetchExistingDiagnosticAnswers } from './questionnaire/questionnaireSlice'
import RiPageComponent from './ri/RiPageComponent'
import store from './store'
import TermsAndConditionsComponent from './term/TermsAndConditionsComponent'
import { fetchExistingUserAttributes } from './user/userAttributesSlice'
import { StudyEnrollmentComplete } from "./agreement/StudyEnrollmentComplete"
import Auth0AuthenticationCallbackLandingPage from "./auth/Auth0AuthenticationCallbackLandingPage"
import HipaaNoticeComponent from "./HipaaNoticeComponent"
import CommunicationPolicyComponent from "./CommunicationPolicyComponent"
import TelehealthInformedConsentComponent from "./TelehealthInformedConsentComponent"
import { setSignature, selectSignature } from './user/userAttributesSlice'
import { setUserSpecifiedSignatureDate, selectUserSpecifiedSignatureDate } from './user/userAttributesSlice'
import { useDispatch, useSelector } from 'react-redux';
import { SbhcCompositeState } from "./state/SbhcCompositeState"
import RedirectAsNeededComponent from "./RedirectAsNeededComponent"
const LOG = getLogger('App.tsx');
const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc);


// const currentSignatureInState: string = useSelector((state: SbhcCompositeState) => {
//   return selectSignature(state)
// });

// const currentUserSpecifiedSignatureDate: string = useSelector((state: SbhcCompositeState) => {
//   return selectUserSpecifiedSignatureDate(state)
// });

(store.dispatch as any)((dispatch: Dispatch /* TODO: AppDispatch vs Dispatch */, getState: any) => {
  if (whoAmISvc.amILoggedIn()) {
    LOG.info(`root: store.dispatch, then I am LOGGED IN`)
    fetchExistingUserAttributes(dispatch, getState)
    fetchExistingDiagnosticAnswers(dispatch, getState)
  } else {
    LOG.info(`root: store.dispatch, then I am NOT authenticated`)
  }
})

function App () {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth0();
  const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
  const whoAmIWriteService: WhoAmIWriteService = diContainer.get<WhoAmIWriteService>(DI_TYPES.WhoAmIWriteService)
  const navigate = useNavigate();

  // useEffect(() => {
  //   LOG.debug(`DeveloperAuthDetailComponent()::useEffect:  Entering`);
  // }, []);

  const captureAuthToken = async () => {
    try {
      if (isAuthenticated) {
        LOG.debug(`captureAuthToken():  I am AUTHENTICATED`);

          const authToken = await getAccessTokenSilently({
            // authorizationParams: {
            //   audience: 'https://sbhc-api', // Value in Identifier field for the API being called.
            //   // scope: 'whatever', // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
            // }
          });

          LOG.debug(`captureAuthToken():  authToken = ${authToken}`)
          // const parsed = parseJwt(authToken)
          // LOG.debug(`captureAuthToken():  parsed = ${JSON.stringify(parsed)}`)

          whoAmIWriteService.ensureAuthenticatedUserDetailsAreUpToDate({
            authenticatedFlag: true,
            authToken: authToken,
            userId: user?.sub,
            email: user?.email,
            name: user?.name,
            nickname: user?.nickname
          })
      } else {
        LOG.debug(`captureAuthToken():  I remain NOT authenticated`);
        whoAmIWriteService.ensureAuthenticatedUserDetailsAreUpToDate({
          authenticatedFlag: false,
          authToken: undefined,
          userId: undefined,
          email: undefined,
          name: undefined,
          nickname: undefined
        })
      }
    }
    catch (error) {
      LOG.error(`captureAuthToken():  error = ${(error)}`, error);
    }
  }


  useEffect(() => {
    loginHeavyLift()
  }, [getAccessTokenSilently, user?.sub])


  const loginHeavyLift = async function() {
    LOG.debug(`useEffect:  Entering`);

    await captureAuthToken()
    // setTimeout( () ={
    //   captureAuthToken()
    // }, 2000)
    
    // if (whoAmISvc.amILoggedIn()) {
    //   LOG.debug(`loginHeavyLift(): I am AUTHENTICATED.`)
    //   /**
    //    * I am logged in and ON the homepage, bounce me to the questionnaire
    //    * 
    //    */
    //    // navigate(`/questionnaire`, { replace: true });
    // } else {
    //   LOG.debug(`loginHeavyLift(): NOT authenticated.`)
    // }
  };


  return (
    <>
      <Routes>
        <Route path='' element={<Homepage />} />
        <Route path='/signin/auth0/callback' element={<Auth0AuthenticationCallbackLandingPage />} />              
        <Route path='/a' element={<RedirectAsNeededComponent />} />
        <Route path='/home' element={<RedirectAsNeededComponent />} />


        <Route path='/' element={<DefaultLayoutComponent />}>
          <Route path='g' element={<PaidSearchLandingPageComponent />} />
          {/* <Route path='signin/auth0/callback' element={<Auth0CallbackPageComponent />} /> */}
          <Route path='ri' element={<RiPageComponent />} />
          <Route path='counter' element={<Counter />} />
          <Route path='oldsignin' element={<SignIn />} />
          <Route path='profile' element={<ProfileComponent />} />
          <Route path='signin' element={<Auth0SignInComponent />} />
          <Route path='signup' element={<Auth0SignUpPageRedirector />} />
          <Route path='signin/callback' element={<AuthenticatedComponent />} />              

          <Route path='signout' element={<SignOutWidgetComponent />} />
          <Route path='questionnaire' element={<RouteRequiresLogin/>}>
            <Route path='' element={<QuestionnaireComponent/>}/>
          </Route>
          <Route path='findings' element={<RouteRequiresLogin/>}>
            <Route path='' element={<InitialHypothesisComponent/>}/>
          </Route>
          <Route path='insurance' element={<RouteRequiresLogin/>}>
            <Route path='' element={<UpdateInsuranceComponent/>}/>
          </Route>   
          <Route path='medication' element={<RouteRequiresLogin/>}>
            <Route path='' element={<UpdateMedicationComponent/>}/>
          </Route>
          <Route path='agreement' element={<RouteRequiresLogin/>}>
            <Route path='' element={<AgreementComponent/>}/>
          </Route>     
          <Route path='terms-and-conditions'>
            <Route path='' element={<TermsAndConditionsComponent/>}/>
          </Route>
          <Route path='studyenrollmentcomplete'>
            <Route path='' element={<StudyEnrollmentComplete/>}/>
          </Route> 
          <Route path='privacy-policy'>
            <Route path='' element={<PrivacyPolicyComponent/>}/>
          </Route>   
          <Route path='hipaa-notice'>
            <Route path='' element={<HipaaNoticeComponent/>}/>
          </Route> 
          <Route path='communication-policy'>
            <Route path='' element={<CommunicationPolicyComponent/>}/>
          </Route>
          <Route path='telehealth-informed-consent'>
            <Route path='' element={<TelehealthInformedConsentComponent/>}/>
          </Route>
          <Route path='devmode' element={<DevModeComponent />} />
        </Route>
      </Routes>
    </>
  )
}


export default App
