// CREATE TYPE up_to_3plus_times_per_week AS ENUM ('weekly', 'monthly', '3plus_times_per_week');

export enum UpperLowerBothCodeEnum { 
  UNSET = "unset", 
  UPPER = "upper", 
  LOWER = "lower", 
  BOTH = "both", 
}  

export enum UpperLowerBothLabelEnum { 
  UNSET = "Choose", 
  UPPER = "Upper", 
  LOWER = "Lower", 
  BOTH = "Both", 
}  