import { FetchInitialHypothesisResponse } from "../commonSrc/constant/type";
import { InitialHypothesisFetcher } from "./InitialHypothesisFetcher";
import { getLogger } from '../log/getLogger'
import { injectable } from "inversify";
import { WhoAmISvc } from "../auth/WhoAmISvc";
import diContainer from '../inversify.config'
import DI_TYPES from "../diTypes";
import { d4l } from "../commonSrc/log/logUtil";

const LOG =  getLogger('InitialHypothesisFetcherImpl')

@injectable()
export class InitialHypothesisFetcherImpl implements InitialHypothesisFetcher {
  public fetchInitialHypothesis(userId: string /* canonical UUID */): Promise<FetchInitialHypothesisResponse> {
    LOG.debug(`fetchInitialHypothesis(): Entering with userId = ${userId}`)

    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
    const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
    const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
    const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

    
    const headers: any = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    }

    if (whoAmISvc.amILoggedIn()) {
      LOG.debug(`fetchInitialHypothesis(): User is signed in.  jwtToken = ${whoAmISvc.getMyAuthToken()}`)
      headers["Authorization"] = `Bearer ${whoAmISvc.defGetMyAuthToken()}`
    } else {
      LOG.warn(`fetchInitialHypothesis(): Unauthorized 401.  User is NOT signed in.`)
    }

    const requestOptions = {
      method: 'GET',
      // mode: 'cors',
      headers: headers
    };

    const url = (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:9000') + `${process.env.REACT_APP_BACKEND_RELATIVE_API_PATH}` + '/findings'
        
    
    LOG.info(`fetchInitialHypothesis(): Attempting to fetch from url = ${d4l(url)}`)
    return fetch(url, requestOptions).then(response => {
      LOG.info(`fetchInitialHypothesis(): Wait for it...`)
      let retval;
      try {
        retval = response.json()
      } catch(err) {
        LOG.error(`fetchInitialHypothesis(): err = ${d4l(err)}`)
      }
      LOG.info(`fetchInitialHypothesis(): Returning retval = ${d4l(retval)} (${typeof retval})`)
      return retval
    })
  }
}