import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as namedReducer from '../namedReducer'
import { getLogger } from '../log/getLogger'
import { WhoAmISvc } from '../auth/WhoAmISvc'
import DI_TYPES from '../diTypes'
import diContainer from '../inversify.config'
import { UserAttributeFetchingService } from './UserAttributeFetchingService'
import { CombinedState, Dispatch } from 'redux'
import { ExistingUserAttributesResponse, SIGNATURE_DATE_USER_ATTRIBUTE_KEY, StateScalarAttribute, UserAttributesSliceOfState } from "../commonSrc/constant/type";
import safeStringify from '../string/safeStringify'
import { SbhcCompositeState } from '../state/SbhcCompositeState'

const log = getLogger('user/userAttributesSlice')

export const userAttributesSlice = createSlice({
  name: namedReducer.USER_ATTRIBUTES,
  initialState: {
      insuranceCompanyName: "",
      insurancePolicyholderName: "",
      insuranceMemberId: "",
      insuranceGroupNumber: "",
      medication: "",
      signature: "",
      userSpecifiedSignatureDate: "",
      legalName: ""
  },
  reducers: {
    setNewUserAttributesState: (state: any, action: any) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state = action.payload
      console.log(`userAttributesSlice::setNewUserAttributesState = ${JSON.stringify(state)}`)
    },

    onFetchExistingUserAttributesSuccess: (state: UserAttributesSliceOfState, action: PayloadAction<UserAttributesSliceOfState>) => {
      /*
       * user/userAttributesSlice   onFetchExistingUserAttributesSuccess(): Entering with state = {"insurance":"","medication":"","signature":""},  action = {"type":"userAttributes/onFetchExistingUserAttributesSuccess","payload":{"insurance":"PPO BCBS","medication":"1000mg of this and that","signature":"Bob C. Hope"}}
      */
      log.debug(`onFetchExistingUserAttributesSuccess(): Entering with state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      

      if ( action.payload != null ) {
        if (typeof action.payload.insuranceCompanyName != 'undefined') {
          state.insuranceCompanyName = action.payload.insuranceCompanyName
        }
        if (typeof action.payload.insurancePolicyholderName != 'undefined') {
          state.insurancePolicyholderName = action.payload.insurancePolicyholderName
        }
        if (typeof action.payload.insuranceMemberId != 'undefined') {
          state.insuranceMemberId = action.payload.insuranceMemberId
        }
        if (typeof action.payload.insuranceGroupNumber != 'undefined') {
          state.insuranceGroupNumber = action.payload.insuranceGroupNumber
        }
        if (typeof action.payload.medication != 'undefined') {
          state.medication = action.payload.medication
        }
        if (typeof action.payload.signature != 'undefined') {
          state.signature = action.payload.signature
        }
        if (typeof action.payload.userSpecifiedSignatureDate != 'undefined') {
          state.userSpecifiedSignatureDate = action.payload.userSpecifiedSignatureDate
        }
        if (typeof action.payload.legalName != 'undefined') {
          state.legalName = action.payload.legalName
        }
      }

      log.debug(`onFetchExistingUserAttributesSuccess(): Returning state = ${safeStringify(state)}`)
      return state
    },

    setInsuranceCompanyName: (state: UserAttributesSliceOfState, action: PayloadAction<string>) => {
      console.log(`setInsuranceCompanyName(): WHY DON'T WE SEE THIS?!`)
      log.debug(`setInsuranceCompanyName(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.insuranceCompanyName = action.payload
      return state
    },

    setInsurancePolicyholderName: (state: UserAttributesSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setInsurancePolicyholderName(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.insurancePolicyholderName = action.payload
      return state
    },

    setInsuranceMemberId: (state: UserAttributesSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setInsuranceMemberId(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.insuranceMemberId = action.payload
      return state
    },

    setInsuranceGroupNumber: (state: UserAttributesSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setInsuranceGroupNumber(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.insuranceGroupNumber = action.payload
      return state
    },

    setMedication: (state: UserAttributesSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setMedication(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.medication = action.payload
      return state
    },

    setSignature: (state: UserAttributesSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setSignature(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.signature = action.payload
      return state
    },

    setLegalName: (state: UserAttributesSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setLegalName(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.legalName = action.payload
      return state
    },    

    setUserSpecifiedSignatureDate: (state: UserAttributesSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setUserSpecifiedSignatureDate(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.userSpecifiedSignatureDate = action.payload
      return state
    },

    setClearMyUserAttributesDangerWillRobinson: (state: UserAttributesSliceOfState) => {
      log.warn(`setClearMyQuestionnaireDangerWillRobinson(): Entering`)
      state.signature = ""
      state.userSpecifiedSignatureDate = ""
      state.legalName = ""
      return state
    }
  }
})

export async function fetchExistingUserAttributes (dispatch: Dispatch /* TODO: AppDispatch vs Dispatch */, getState: any): Promise<any> {
  // const response = await client.get('/fakeApi/todos')
  log.debug('fetchExistingUserAttributes() - Entering')
  // const container = useContainer();
  // const existingDiagnosticAnswerFetcher: ExistingDiagnosticAnswerFetcher = container.get<ExistingDiagnosticAnswerFetcher>(DI_TYPES.ExistingDiagnosticAnswerFetcher)
  const userAttributesFetchingService: UserAttributeFetchingService = diContainer.get<UserAttributeFetchingService>(DI_TYPES.UserAttributeFetchingService)

  const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
  const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
  const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
  const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()
  log.debug(`fetchExistingUserAttributes() - oauth2UserId = ${oauth2UserId}`)


  return userAttributesFetchingService.fetchExistingUserAttributes().then((response: ExistingUserAttributesResponse) => {
    log.debug(`fetchExistingUserAttributes() - userAttributesFetchingService.fetchExistingUserAttributes(): Returned a response = ${safeStringify(response)}`)
    log.debug(`fetchExistingUserAttributes() - Preparing to dispatch to onFetchExistingUserAttributesSuccess...`)
    dispatch(onFetchExistingUserAttributesSuccess((response as unknown as ExistingUserAttributesResponse).data as unknown as UserAttributesSliceOfState))
    log.debug(`fetchExistingUserAttributes() - Dispatch complete.`)

  })
}

// export const selectInsuranceInfo = (state: CombinedState<SbhcCompositeState>): string => {
//   log.trace(`selectInsuranceInfo(): state = ${safeStringify(state)}`)
//   // const retval: StateScalarAttribute<string> = state?.questionnaire?[attrName] ?? undefined;
//   const retval: string = (state != null && state.userAttributes) ? (state.userAttributes as any)['insuranceOverall'] : undefined;
//   return retval
// }

export const selectInsuranceCompanyName = (state: CombinedState<SbhcCompositeState>): string => {
  log.debug(`selectInsuranceCompanyName(): state = ${safeStringify(state)}`)
  // const retval: StateScalarAttribute<string> = state?.questionnaire?[attrName] ?? undefined;
  const retval: string = (state != null && state.userAttributes) ? (state.userAttributes as any)['insuranceCompanyName'] : undefined;
  log.debug(`selectInsuranceCompanyName(): Returning ${safeStringify(retval)}`)
  return retval
}

export const selectInsurancePolicyholderName = (state: CombinedState<SbhcCompositeState>): string => {
  log.trace(`selectPolicyholderName(): state = ${safeStringify(state)}`)
  // const retval: StateScalarAttribute<string> = state?.questionnaire?[attrName] ?? undefined;
  const retval: string = (state != null && state.userAttributes) ? (state.userAttributes as any)['insurancePolicyholderName'] : undefined;
  return retval
}

export const selectInsuranceMemberId = (state: CombinedState<SbhcCompositeState>): string => {
  log.trace(`selectMemberId(): state = ${safeStringify(state)}`)
  // const retval: StateScalarAttribute<string> = state?.questionnaire?[attrName] ?? undefined;
  const retval: string = (state != null && state.userAttributes) ? (state.userAttributes as any)['insuranceMemberId'] : undefined;
  return retval
}

export const selectInsuranceGroupNumber = (state: CombinedState<SbhcCompositeState>): string => {
  log.trace(`selectGroupNumber(): state = ${safeStringify(state)}`)
  // const retval: StateScalarAttribute<string> = state?.questionnaire?[attrName] ?? undefined;
  const retval: string = (state != null && state.userAttributes) ? (state.userAttributes as any)['insuranceGroupNumber'] : undefined;
  return retval
}

export const selectMedication = (state: CombinedState<SbhcCompositeState>): string => {
  log.trace(`selectMedication(): state = ${safeStringify(state)}`)
  const retval: string = (state != null && state.userAttributes) ? (state.userAttributes as any)['medication'] : undefined;
  return retval
}

export const selectSignature = (state: CombinedState<SbhcCompositeState>): string => {
  log.trace(`selectSignature(): state = ${safeStringify(state)}`)
  const retval: string = (state != null && state.userAttributes) ? (state.userAttributes as any)['signature'] : undefined;
  return retval
}

export const selectUserSpecifiedSignatureDate = (state: CombinedState<SbhcCompositeState>): string => {
  log.trace(`selectUserSpecifiedSignatureDate(): state = ${safeStringify(state)}`)
  const retval: string = (state != null && state.userAttributes) ? (state.userAttributes as any)[SIGNATURE_DATE_USER_ATTRIBUTE_KEY] : undefined;
  return retval
}

export const selectLegalName = (state: CombinedState<SbhcCompositeState>): string => {
  log.trace(`selectLegalName(): state = ${safeStringify(state)}`)
  const retval: string = (state != null && state.userAttributes) ? (state.userAttributes as any)['legalName'] : undefined;
  return retval
}

export const { 
  setNewUserAttributesState,
  onFetchExistingUserAttributesSuccess, 
  setInsuranceCompanyName,
  setInsuranceGroupNumber,
  setInsuranceMemberId,
  setInsurancePolicyholderName,
  setMedication,
  setSignature,
  setLegalName,
  setUserSpecifiedSignatureDate,
  setClearMyUserAttributesDangerWillRobinson
} = userAttributesSlice.actions

export default userAttributesSlice.reducer

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getUserAttributes = (state: any) => state[namedReducer.COUNTER].value
