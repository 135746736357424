import { SignInSvc } from "./SignInSvc";
import { injectable } from "inversify";
import { getLogger } from "../log/getLogger";
import { Location } from "react-router-dom";
import safeStringify from "../string/safeStringify";

@injectable()
export class SignInSvcImpl implements SignInSvc {
  private static readonly LOG = getLogger('auth.SignInSvcImpl')
  private readonly LOG = SignInSvcImpl.LOG
  

  public getOauth2IdToken(location: Location): string | null | undefined {
    this.LOG.debug(`getOauth2IdToken(): location = ${safeStringify(location)}`)   

    // const [searchParams, setSearchParams] = useSearchParams();
    // const idToken = searchParams.get("id_token")
    // this.LOG.trace(`idToken = ${idToken}`)

    // const accessToken = searchParams.get("access_token")
    // const params = useParams();
    // this.LOG.trace(`params = ${JSON.stringify(params)}`)
    // const { hash } = useLocation();
    // this.LOG.trace(`hash = ${hash}`)
    // const location = useLocation();
    this.LOG.trace(`location = ${location}`)
    this.LOG.trace(`location.pathname = ${location.pathname}`)
    this.LOG.trace(`location.hash = ${location.hash}`)

    const hashValue = location.hash.substring(1)
    const pairs = hashValue.split('&')
    this.LOG.debug(`pairs = ${JSON.stringify(pairs)}`)

    const keyValuePairs: any = {};

    let idToken: string | undefined;
    let accessToken: string | undefined;


    for (let ii = 0; ii < pairs.length; ii++) {
      const [key, value] = pairs[ii].split('=')
      keyValuePairs[key] = value;
      sessionStorage.setItem(`${key}`, value);
      if ( key === 'id_token') {
        idToken = value;
        this.LOG.debug(`getOauth2IdToken(): found id_token = ${idToken}`)   
        break
      }
      if ( key === 'access_token') {
        accessToken = value;
        this.LOG.debug(`getOauth2IdToken(): found access_token = ${accessToken}`)   
      }
    }

    this.LOG.trace(`getOauth2IdToken(): Returning ${idToken}`)
    return idToken;
  }
}