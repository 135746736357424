// CREATE TYPE up_to_3plus_times_per_week AS ENUM ('weekly', 'monthly', '3plus_times_per_week');

export enum UpTo3PlusTimesPerWeekCodeEnum { 
  UNSET = "unset", 
  MONTHLY = "monthly", 
  WEEKLY = "weekly", 
  UP_TO_THREEPLUS_TIMES_WEEKLY = "up to 3+x weekly", 
}  

export enum UpTo3PlusTimesPerWeekLabelEnum { 
  UNSET = "Choose", 
  MONTHLY = "Monthly", 
  WEEKLY = "Weekly", 
  UP_TO_THREEPLUS_TIMES_WEEKLY = "3+ times per week", 
}  