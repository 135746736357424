/**
 * Decorate for logging
 * Turn input into debug-log friendly format
 * 
 * @param input 
 */
 export function d4l( input: any ): string {
  if (typeof input === "undefined") {
    return '<undefined> (undefined)'
  }
  if (input === null) {
    return '<null> (null)'
  }
  if (typeof input === 'string') {
    return `'${input}' (string)`
  }
  if (typeof input === 'number') {
    return `${input} (number)`
  }
  if (typeof input === 'object') {
    if (typeof ((input as any).toDebugString) === 'function' ) {
      return (input as any).toDebugString()
    }
    if (typeof ((input as any).toLogString) === 'function' ) {
      return (input as any).toLogString()
    }
    try {
      return safeStringify(input)
    } catch (err){}
  }
  
  return `${input}`
}


const safeStringify = (obj: any, indent = 0) => {
  let cache: any = []
  const retVal = JSON.stringify(
    obj,
    (key, value) =>
      typeof value === 'object' && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value,
    indent
  )
  cache = null
  return retVal
}