export function isTrueLike(input: string | boolean | number): boolean {
  if (input == null) { return false }
  const inputAsString: string = input.toString().toLowerCase();
  return ['1', 't',  'y', 'yes', 'true'].indexOf(inputAsString) >= 0
}

export function isFalseLike(input: string | boolean | number): boolean {
  if (input == null) { return false }
  const inputAsString: string = input.toString().toLowerCase();
  return ['0', 'f',  'n', 'no', 'false'].indexOf(inputAsString) >= 0
}

// alias of isTrueLike
export function isHasTrue(input: string | boolean | number): boolean {
  return isTrueLike(input)
}

export function isHasNotTrue(input: string | boolean | number): boolean {
  return !isTrueLike(input)
}

export function isOneOfStrictlyTrueOrStrictlyFalse( input: boolean | null | undefined ) {
  if ( input === true || input === false ) {
    return true
  }
  return false
}