import safeStringify from '../string/safeStringify'
import React, { useState, useReducer, useEffect } from 'react'
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import { getLogger } from '../log/getLogger'
import { StateScalarAttribute } from '../commonSrc/questionnaire/allQuestionnaireAttributes';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { setUpToThreeTimePerWeek, selectUpToThreeTimePerWeek } from '../questionnaire/questionnaireSlice';
import { CurrentPreviousNeverCodeEnum, CurrentPreviousNeverLabelEnum } from '../commonSrc/constant/currentPreviousNeverConstant';
import { UpTo3PlusTimesPerWeekCodeEnum, UpTo3PlusTimesPerWeekLabelEnum } from '../commonSrc/constant/upToThreePlusTimesPerWeekConstant';

const log = getLogger('question.UpToThreeTimePerWeekQuestionComponent')

export default function UpToThreeTimePerWeekQuestionComponent (props: any) {
  const label: string = props.label  // Nausea
  const attrName: string = props.attrName // everNausea
  const onDiagnosticAnswersChanged = props.onDiagnosticAnswersChanged
  log.debug(`props = ${JSON.stringify(props)}`)
  log.debug(`label = ${label}`)

  const dispatch = useDispatch()




  const onChange = async (event: React.ChangeEvent<HTMLInputElement> | any): Promise<void> => {
    log.debug(`onChange() - Entering with event = ${safeStringify(event)}`)
    dispatch(setUpToThreeTimePerWeek({eventValue: event.target.value, attrName: attrName}))
    onDiagnosticAnswersChanged()
  }

  const currentValue: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => {
    return selectUpToThreeTimePerWeek(attrName, state)
  })

  return (
    <>
      <div className="up-to-three-times-per-week-question-component">
        <fieldset>
          <label>{label}</label>
          <div className="radio-group">
            <label>
              <input type='radio' name={attrName} value={UpTo3PlusTimesPerWeekCodeEnum.MONTHLY} checked={currentValue === UpTo3PlusTimesPerWeekCodeEnum.MONTHLY} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event)}/>
              <span>{UpTo3PlusTimesPerWeekLabelEnum.MONTHLY}</span>
            </label>
            <label>
              <input type='radio' name={attrName} value={UpTo3PlusTimesPerWeekCodeEnum.WEEKLY} checked={currentValue === UpTo3PlusTimesPerWeekCodeEnum.WEEKLY} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event)}/>
              <span>{UpTo3PlusTimesPerWeekLabelEnum.WEEKLY}</span>
            </label>
            <label>
              <input type='radio' name={attrName} value={UpTo3PlusTimesPerWeekCodeEnum.UP_TO_THREEPLUS_TIMES_WEEKLY} checked={currentValue === UpTo3PlusTimesPerWeekCodeEnum.UP_TO_THREEPLUS_TIMES_WEEKLY} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event)}/>
              <span>{UpTo3PlusTimesPerWeekLabelEnum.UP_TO_THREEPLUS_TIMES_WEEKLY}</span>
            </label>
          </div>
        </fieldset> 
      </div>
    </>
  )
}