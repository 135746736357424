import 'reflect-metadata'
import { createSlice, createAsyncThunk, PayloadAction, ActionReducerMapBuilder } from '@reduxjs/toolkit'
import * as namedReducer from '../namedReducer'
import safeStringify from '../string/safeStringify'
import { useSelector, useDispatch } from 'react-redux'
import { getLogger } from '../log/getLogger'
import { CurrentPreviousNeverCodeEnum, CurrentPreviousNeverLabelEnum } from '../commonSrc/constant/currentPreviousNeverConstant';
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { UpTo3PlusTimesPerWeekCodeEnum } from '../commonSrc/constant/upToThreePlusTimesPerWeekConstant';
import { TThunkApi, TUndefinedParams, TReduxAction, StateScalarAttribute, TypedReduxAction, DefaultReactInputValue, QuestionnaireAnswersDataFromTheBackend, FetchDiagnosticAnswersResponse, SaveDiagnosticAnswersResponse, ClearMyQuestionnaireDangerWillRobinsonResponse, ClearMyFeedbackDangerWillRobinsonResponse, UnsubmitDangerWillRobinsonResponse, UserAttributesSliceOfState, ClearMyUserAttributesDangerWillRobinsonResponse } from '../commonSrc/constant/type';
import { QuestionnaireSliceOfState } from '../commonSrc/questionnaire/QuestionnaireSliceOfState';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { CombinedState, Dispatch } from 'redux'
import { AppDispatch, RootState } from '../store'
import { useContainer } from 'inversify-react'
import { ExistingDiagnosticAnswerFetcher } from '../existingDiagnosticAnswerFetcher/ExistingDiagnosticAnswerFetcher'
import DI_TYPES from '../diTypes'
import diContainer from '../inversify.config'
import { CurrentDiagnosticAnswerSaver } from '../currentDiagnosticAnswerSaver/CurrentDiagnosticAnswerSaver'
import { WhoAmISvc } from '../auth/WhoAmISvc'
import { QuestionnaireAttributes, QUESTIONNAIRE_ATTRIBUTES_LIST } from "../commonSrc/questionnaire/allQuestionnaireAttributes"
import { YesOrNoCodeEnum } from '../commonSrc/constant/yesOrNoConstant';
import { tryToNumber} from '../commonSrc/number/numberUtil'
import { UserAttributeFetchingServiceImpl } from '../user/UserAttributeFetchingServiceImpl'
import { UserAttributeFetchingService } from '../user/UserAttributeFetchingService'

const log = getLogger('questionnaire/questionnaireSlice')

// https://redux.js.org/tutorials/essentials/part-5-async-logic
// https://stackoverflow.com/questions/67968873/how-to-use-createasyncthunk-from-redux-toolkit-with-typescript-correctly
// https://github.com/reduxjs/redux-toolkit/issues/486
type AsyncThunkConfig = {
  state?: unknown;
  dispatch?: Dispatch; // vs AppDispatch
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
};

export interface IThunkApi extends AsyncThunkConfig {
  // getState: () => CombinedState<SbhcCompositeState>
  getState: () => unknown
}

export const syncDiagnosticAnswersToBackEndThunk = createAsyncThunk<
  any, // return type
  void, // args type
  IThunkApi // thunkAPI type
  >(
  // https://www.newline.co/@bespoyasov/how-to-use-thunks-with-redux-toolkit-and-typescript--1e65fc64
  // The first argument is the action name:
  `${namedReducer.QUESTIONNAIRE}/syncDiagnosticAnswersToBackEndThunk`,
  // The payload creator receives the partial `{title, content, user}` object

  // https://stackoverflow.com/questions/63516716/redux-toolkit-is-it-possible-to-dispatch-other-actions-from-the-same-slice-in-o
  // params vs payload
  // https://redux-toolkit.js.org/api/createAsyncThunk
  // TODO: Adding types here causes Expected 1 arguments, but got 0 TS2554
  // https://stackoverflow.com/questions/61231182/react-redux-dispatch-function-expected-0-arguments-but-got-1-ts2554
  // The second one is a function
  // called payload creator.
  // It contains async logic of a side-effect.
  // We can perform requests here,
  // work with device API, 
  // or any other async APIs we need to.
  // async (params: TUndefinedParams, thunkApi: TThunkApi) => {
  // https://stackoverflow.com/questions/67968873/how-to-use-createasyncthunk-from-redux-toolkit-with-typescript-correctly
  // https://gitanswer.com/thunkapi-getstate-with-typescript-737086169
  async (params: void, thunkApi: IThunkApi): Promise<SaveDiagnosticAnswersResponse> => {
    // works, initialValue => {
    // works, (initialValue) => {
    // fails (_: any, initialValue: any) => {
    // fails (...initialValue: any[]) => {
    log.debug(`syncDiagnosticAnswersToBackEndThunk(): being invoked.  params = ${safeStringify(params)}, thunkApi = ${safeStringify(thunkApi)}`)
    
    // https://stackoverflow.com/questions/43246890/is-using-getstate-in-a-redux-thunk-good-practice
    // https://blog.isquaredsoftware.com/2017/01/idiomatic-redux-thoughts-on-thunks-sagas-abstraction-and-reusability/
    // https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator/35674575#35674575
    // https://github.com/reduxjs/redux-toolkit/issues/563
    // https://redux-toolkit.js.org/api/createAsyncThunk
    // https://stackoverflow.com/questions/64793504/cannot-set-getstate-type-to-rootstate-in-createasyncthunk

    // This doesn't work
    // const state = store.getState()
    // log.debug(`syncDiagnosticAnswersToBackEndThunk(): state = ${safeStringify(state)}, params = ${safeStringify(params)}, thunkApi = ${safeStringify(thunkApi)}`)

    // compositeState vs combinedState vs rootState
    const rootState: CombinedState<SbhcCompositeState> = thunkApi.getState() as CombinedState<SbhcCompositeState>
    // const state: RootState = thunkApi.getState() as unknown as RootState

    log.debug(`syncDiagnosticAnswersToBackEndThunk(): rootState = ${safeStringify(rootState)}, params = ${safeStringify(params)}, thunkApi = ${safeStringify(thunkApi)}`)

    const questionnaireSliceOfState: QuestionnaireSliceOfState = rootState.questionnaire

    const currentDiagnosticAnswerSaver: CurrentDiagnosticAnswerSaver = diContainer.get<CurrentDiagnosticAnswerSaver>(DI_TYPES.CurrentDiagnosticAnswerSaver)
    const promise: Promise<SaveDiagnosticAnswersResponse> = currentDiagnosticAnswerSaver.save(questionnaireSliceOfState as unknown as QuestionnaireAnswersDataFromTheBackend);
    const saveDiagnosticAnswersResponse: SaveDiagnosticAnswersResponse = await promise
    log.debug(`syncDiagnosticAnswersToBackEndThunk(): Returning saveDiagnosticAnswersResponse = ${safeStringify(saveDiagnosticAnswersResponse)}`)
    return saveDiagnosticAnswersResponse
  }
)











export const clearMyQuestionnaireUpstreamDangerWillRobinsonThunk = createAsyncThunk<
  any, // return type
  void, // args type
  IThunkApi // thunkAPI type
  >(
  // The first argument is the action name:
  `${namedReducer.QUESTIONNAIRE}/clearMyQuestionnaireDangerWillRobinson`,

  async (params: void, thunkApi: IThunkApi): Promise<ClearMyQuestionnaireDangerWillRobinsonResponse> => {

    log.debug(`clearMyQuestionnaireUpstreamDangerWillRobinsonThunk(): being invoked.  params = ${safeStringify(params)}, thunkApi = ${safeStringify(thunkApi)}`)
    const rootState: CombinedState<SbhcCompositeState> = thunkApi.getState() as CombinedState<SbhcCompositeState>

    log.debug(`clearMyQuestionnaireUpstreamDangerWillRobinsonThunk(): rootState = ${safeStringify(rootState)}, params = ${safeStringify(params)}, thunkApi = ${safeStringify(thunkApi)}`)

    const questionnaireSliceOfState: QuestionnaireSliceOfState = rootState.questionnaire

    const currentDiagnosticAnswerSaver: CurrentDiagnosticAnswerSaver = diContainer.get<CurrentDiagnosticAnswerSaver>(DI_TYPES.CurrentDiagnosticAnswerSaver)
    const promise: Promise<ClearMyQuestionnaireDangerWillRobinsonResponse> = currentDiagnosticAnswerSaver.clearMyQuestionnaireDangerWillRobinson();
    const clearMyQuestionnaireDangerWillRobinsonResponse: ClearMyQuestionnaireDangerWillRobinsonResponse = await promise
    log.debug(`clearMyQuestionnaireUpstreamDangerWillRobinsonThunk(): Returning clearMyQuestionnaireDangerWillRobinsonResponse = ${safeStringify(clearMyQuestionnaireDangerWillRobinsonResponse)}`)
    return currentDiagnosticAnswerSaver
  }
);

export const clearMyUserAttributesDangerWillRobinsonThunk = createAsyncThunk<
  any, // return type
  void, // args type
  IThunkApi // thunkAPI type
  >(
  // The first argument is the action name:
  `${namedReducer.USER_ATTRIBUTES}/clearMyUserAttributesDangerWillRobinsonThunk`,
  async (params: void, thunkApi: IThunkApi): Promise<ClearMyUserAttributesDangerWillRobinsonResponse> => {

    log.debug(`clearMyUserAttributesDangerWillRobinsonThunk(): being invoked.  params = ${safeStringify(params)}, thunkApi = ${safeStringify(thunkApi)}`)
    const rootState: CombinedState<SbhcCompositeState> = thunkApi.getState() as CombinedState<SbhcCompositeState>

    log.debug(`clearMyUserAttributesDangerWillRobinsonThunk(): rootState = ${safeStringify(rootState)}, params = ${safeStringify(params)}, thunkApi = ${safeStringify(thunkApi)}`)

    const userAttributesSliceOfState: UserAttributesSliceOfState = rootState.userAttributes

    // const currentDiagnosticAnswerSaver: CurrentDiagnosticAnswerSaver = diContainer.get<CurrentDiagnosticAnswerSaver>(DI_TYPES.CurrentDiagnosticAnswerSaver)
    const userAttributeFetchingService: UserAttributeFetchingService = diContainer.get<UserAttributeFetchingService>(DI_TYPES.UserAttributeFetchingService)

    const promise: Promise<ClearMyUserAttributesDangerWillRobinsonResponse> = userAttributeFetchingService.clearMyUserAttributesDangerWillRobinson();
    const clearMyUserAttributesDangerWillRobinsonResponse: ClearMyUserAttributesDangerWillRobinsonResponse = await promise
    log.debug(`clearMyUserAttributesDangerWillRobinsonThunk(): Returning clearMyUserAttributesDangerWillRobinsonResponse = ${safeStringify(clearMyUserAttributesDangerWillRobinsonResponse)}`)
    return clearMyUserAttributesDangerWillRobinsonResponse
  }
);


export const clearMyFeedbackUpstreamDangerWillRobinsonThunk = createAsyncThunk<
  any, // return type
  void, // args type
  IThunkApi // thunkAPI type
  >(
  // The first argument is the action name:
  `${namedReducer.QUESTIONNAIRE}/clearMyFeedbackUpstreamDangerWillRobinsonThunk`,

  async (params: void, thunkApi: IThunkApi): Promise<ClearMyFeedbackDangerWillRobinsonResponse> => {

    log.debug(`clearMyFeedbackUpstreamDangerWillRobinsonThunk(): being invoked.  params = ${safeStringify(params)}, thunkApi = ${safeStringify(thunkApi)}`)
    const rootState: CombinedState<SbhcCompositeState> = thunkApi.getState() as CombinedState<SbhcCompositeState>

    log.debug(`clearMyFeedbackUpstreamDangerWillRobinsonThunk(): rootState = ${safeStringify(rootState)}, params = ${safeStringify(params)}, thunkApi = ${safeStringify(thunkApi)}`)

    const questionnaireSliceOfState: QuestionnaireSliceOfState = rootState.questionnaire

    const currentDiagnosticAnswerSaver: CurrentDiagnosticAnswerSaver = diContainer.get<CurrentDiagnosticAnswerSaver>(DI_TYPES.CurrentDiagnosticAnswerSaver)
    const promise: Promise<ClearMyFeedbackDangerWillRobinsonResponse> = currentDiagnosticAnswerSaver.clearMyFeedbackDangerWillRobinson();
    const clearMyFeedbackDangerWillRobinsonResponse: ClearMyFeedbackDangerWillRobinsonResponse = await promise
    log.debug(`clearMyQuestionnaireUpstreamDangerWillRobinsonThunk(): Returning clearMyFeedbackDangerWillRobinsonResponse = ${safeStringify(clearMyFeedbackDangerWillRobinsonResponse)}`)
    return currentDiagnosticAnswerSaver
  }
)


export const unsubmitUpstreamDangerWillRobinsonThunk = createAsyncThunk<
  any, // return type
  void, // args type
  IThunkApi // thunkAPI type
  >(
  // The first argument is the action name:
  `${namedReducer.QUESTIONNAIRE}/unsubmitUpstreamDangerWillRobinsonThunk`,

  async (params: void, thunkApi: IThunkApi): Promise<ClearMyFeedbackDangerWillRobinsonResponse> => {

    log.debug(`unsubmitUpstreamDangerWillRobinsonThunk(): being invoked.  params = ${safeStringify(params)}, thunkApi = ${safeStringify(thunkApi)}`)
    const rootState: CombinedState<SbhcCompositeState> = thunkApi.getState() as CombinedState<SbhcCompositeState>

    log.debug(`unsubmitUpstreamDangerWillRobinsonThunk(): rootState = ${safeStringify(rootState)}, params = ${safeStringify(params)}, thunkApi = ${safeStringify(thunkApi)}`)

    const questionnaireSliceOfState: QuestionnaireSliceOfState = rootState.questionnaire

    const currentDiagnosticAnswerSaver: CurrentDiagnosticAnswerSaver = diContainer.get<CurrentDiagnosticAnswerSaver>(DI_TYPES.CurrentDiagnosticAnswerSaver)
    const promise: Promise<UnsubmitDangerWillRobinsonResponse> = currentDiagnosticAnswerSaver.unsubmitDangerWillRobinson();
    const unsubmitDangerWillRobinsonResponse: UnsubmitDangerWillRobinsonResponse = await promise
    log.debug(`unsubmitUpstreamDangerWillRobinsonThunk(): Returning unsubmitDangerWillRobinsonResponse = ${safeStringify(unsubmitDangerWillRobinsonResponse)}`)
    return currentDiagnosticAnswerSaver
  }
)

// A component is changing an uncontrolled input to be controlled. This is likely caused by the value changing from undefined to a defined value, which should not happen. Decide between using a controlled or uncontrolled input element for the lifetime of the component. More info: https://reactjs.org/link/controlled-components
// https://reactjs.org/docs/forms.html#controlled-components
// const INITIAL_QUESTIONNAIRE_SLICE_OF_STATE: QuestionnaireSliceOfState = {
//   // TODO: Should these be null or empty string? Or undefined?
//   heightInInches: DefaultReactInputValue,
//   weightInLbs: DefaultReactInputValue,
//   ageInYears: DefaultReactInputValue,
//   everNausea: DefaultReactInputValue,
//   everVomiting: DefaultReactInputValue,
//   everAbdominalPain: DefaultReactInputValue,
//   abdominalPainHowOften: DefaultReactInputValue
// }

// const INITIAL_QUESTIONNAIRE_SLICE_OF_STATE: QuestionnaireSliceOfState = {} as unknown as QuestionnaireSliceOfState

const INITIAL_QUESTIONNAIRE_SLICE_OF_STATE: QuestionnaireSliceOfState = {
  // heightInInches: '',
  // weightInLbs: '',
  // ageInYears: '',
  // TODO: string vs number
  // TODO: wait 0?!
  // fouc
  // flash of zeros
  // Using null here causes A component is changing an uncontrolled input to be controlled. This is likely caused by the value changing from undefined to a defined value, which should not happen. Decide between using a controlled or uncontrolled input element for the lifetime of the component. More info: https://reactjs.org/link/controlled-components
  // https://reactjs.org/docs/forms.html#controlled-components
  /*
Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by the value changing from undefined to a defined value, which should not happen. Decide between using a controlled or uncontrolled input element for the lifetime of the component. More info: https://reactjs.org/link/controlled-components
input
label
form
QuestionnaireComponent@http://localhost:3000/static/js/main.chunk.js:1591:150
Routes@http://localhost:3000/static/js/vendors~main.chunk.js:43960:7
Router@http://localhost:3000/static/js/vendors~main.chunk.js:43897:7
BrowserRouter@http://localhost:3000/static/js/vendors~main.chunk.js:43410:7
App
Provider@http://localhost:3000/static/js/vendors~main.chunk.js:40584:15
a@http://localhost:3000/static/js/vendors~main.chunk.js:4814:19 index.js:1
    e index.js:1
    React 11
    unstable_runWithPriority scheduler.development.js:468
    React 4
    unstable_runWithPriority scheduler.development.js:468
*/    
  // https://stackoverflow.com/questions/47642449/initializing-react-number-input-control-with-blank-value
  
  heightInInches: null, // TODO: 0 vs null
  weightInLbs: null, // TODO: 0 vs null
  ageInYears: null,  // TODO: 0 vs null
  everNausea: '',
  everVomiting: '',
  everAbdominalPain: '',
  abdominalPainHowOften: '',
  regularlyConsumeChicoryRootOrInulin: '',
  regularlyConsumeSugarAlcohols: '',
  everLiquidFromBottomToiletOrNot: '',
  bestDescriptionAfterBowelMovement: '',
  whatBothersYouMost: ''
} as unknown as QuestionnaireSliceOfState

export const questionnaireSlice = createSlice({
  name: namedReducer.QUESTIONNAIRE,
  initialState: INITIAL_QUESTIONNAIRE_SLICE_OF_STATE,
  reducers: {
    clearAllQuestionnaire: (state: QuestionnaireSliceOfState) => {
      log.debug(`clearAllQuestionnaire(): state = ${safeStringify(state)}`)
      state = INITIAL_QUESTIONNAIRE_SLICE_OF_STATE
      return state
    },

    onFetchExistingDiagnosticAnswersSuccess: (state: QuestionnaireSliceOfState, action: PayloadAction<QuestionnaireSliceOfState>) => {
      log.debug(`onFetchExistingDiagnosticAnswersSuccess(): Entering with state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      
      if ( action.payload != null ) {
        QUESTIONNAIRE_ATTRIBUTES_LIST.forEach(namedAttribute => {
          log.trace(`onFetchExistingDiagnosticAnswersSuccess(): Considering namedAttribute = '${namedAttribute}'`)

          if ( action.payload.hasOwnProperty(namedAttribute)) {
            const sourceAttributeValue = (action.payload as any)[namedAttribute];
            if (typeof sourceAttributeValue !== 'undefined') {
              (state as any)[namedAttribute] = sourceAttributeValue
            }
          }
        });
      }
      // state.heightInInches = action.payload.heightInInches
      // state.weightInLbs = action.payload.weightInLbs
      // state.ageInYears = action.payload.ageInYears
      // state.everNausea = action.payload.everNausea
      // state.everVomiting = action.payload.everVomiting
      // state.everAbdominalPain = action.payload.everAbdominalPain
      // state.abdominalPainHowOften = action.payload.abdominalPainHowOften
      log.debug(`onFetchExistingDiagnosticAnswersSuccess(): Returning state = ${safeStringify(state)}`)
      return state
    },

    setHeightInInches: (state: QuestionnaireSliceOfState, action: PayloadAction<number>) => {
      log.debug(`setHeightInInches(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.heightInInches = action.payload
      // setTimeout( () => handleQuestionnaireAnswersJustUpdated(state) );
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    setAgeInYears: (state: QuestionnaireSliceOfState, action: PayloadAction<number>) => {
      log.debug(`setAgeInYears(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.ageInYears = action.payload
      // setTimeout( () => handleQuestionnaireAnswersJustUpdated(state) );
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },    
    setWeightInLbs: (state: QuestionnaireSliceOfState, action: PayloadAction<number>) => {
      log.debug(`setWeightInLbs(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.weightInLbs = action.payload
      // setTimeout( () => handleQuestionnaireAnswersJustUpdated(state) );
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    setEverNausea: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverNausea(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everNausea = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    }, 

    // DRYer
    setEverString: (state: QuestionnaireSliceOfState, action: PayloadAction<any>) => {
      log.debug(`setEverString(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`);
      const attrName: string = (action.payload as any).attrName;
      (state as any)[attrName] = action.payload.eventValue
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },  

    setRawString: (state: QuestionnaireSliceOfState, action: PayloadAction<any>) => {
      log.debug(`setRawString(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`);
      const attrName: string = (action.payload as any).attrName;
      (state as any)[attrName] = action.payload.eventValue
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    }, 

    setRawNumber: (state: QuestionnaireSliceOfState, action: PayloadAction<any>) => {
      const attrName: string = (action.payload as any).attrName;
      log.debug(`setRawNumber(): state = ${safeStringify(state)},  action = ${safeStringify(action)},  eventValue = ${action.payload.eventValue} (${typeof action.payload.eventValue})},  attrName = '${attrName}'`);

      (state as any)[attrName] = action.payload.eventValue
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    }, 

    setEverVomiting: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverVomiting(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everVomiting = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },  
    setEverAbdominalPain: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverAbdominalPain(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everAbdominalPain = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },

    setAbdominalPainHowOften: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setAbdominalPainHowOften(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.abdominalPainHowOften = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },

    setAbdominalPainWhere: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setAbdominalPainWhere(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.abdominalPainWhere = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    }, 
    
    setClearMyQuestionnaireDangerWillRobinson: (state: QuestionnaireSliceOfState) => {
      log.warn(`setClearMyQuestionnaireDangerWillRobinson(): Entering`)
      handleQuestionnaireAnswersJustUpdated({} as unknown as QuestionnaireAttributes)
      return {} as unknown as QuestionnaireAttributes
    },

    setClearMyFeedbackDangerWillRobinson: (state: QuestionnaireSliceOfState) => {
      log.warn(`setClearMyFeedbackDangerWillRobinson(): Entering`)
      // return {} as unknown as QuestionnaireAttributes
      return state
    },

    setUnsubmitDangerWillRobinson: (state: QuestionnaireSliceOfState) => {
      log.warn(`setUnsubmitDangerWillRobinson(): Entering`)
      // return {} as unknown as QuestionnaireAttributes
      return state
    },

    setBowelTroubleHowOften: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setBowelTroubleHowOften(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.bowelTroubleHowOften = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },

    setUpToThreeTimePerWeek: (state: QuestionnaireSliceOfState, action: PayloadAction<any>) => {
      log.debug(`setUpToThreeTimePerWeek(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`);
      const attrName: string = (action.payload as any).attrName;
      (state as any)[attrName] = action.payload.eventValue
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },  
    
    setUpperLowerBoth: (state: QuestionnaireSliceOfState, action: PayloadAction<any>) => {
      log.debug(`setUpperLowerBoth(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`);
      const attrName: string = (action.payload as any).attrName;
      (state as any)[attrName] = action.payload.eventValue
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },  
    
    setAlwaysSometimesNever: (state: QuestionnaireSliceOfState, action: PayloadAction<any>) => {
      log.debug(`setAlwaysSometimesNever(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`);
      const attrName: string = (action.payload as any).attrName;
      (state as any)[attrName] = action.payload.eventValue
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    }, 
    
    setOneToThreeXDailyMoreOrLess: (state: QuestionnaireSliceOfState, action: PayloadAction<any>) => {
      log.debug(`setOneToThreeXDailyMoreOrLess(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`);
      const attrName: string = (action.payload as any).attrName;
      (state as any)[attrName] = action.payload.eventValue
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },      
    
    setOneToTenValue: (state: QuestionnaireSliceOfState, action: PayloadAction<any>) => {
      log.debug(`setOneToTenValue(): state = ${safeStringify(state)},  action = ${safeStringify(action)},  action.payload = ${safeStringify(action.payload)}`);
      const attrName: string = (action.payload as any).attrName;
      (state as any)[attrName] = tryToNumber(action.payload.eventValue)
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },       
    

    setYesOrNo: (state: QuestionnaireSliceOfState, action: PayloadAction<any>) => {
      log.debug(`setYesOrNo(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`);
      const attrName: string = (action.payload as any).attrName;

      const yesOrNoString: string = action.payload.eventValue;
      const trueOrFalseBoolean: boolean | null = ( () => {
        if (yesOrNoString === YesOrNoCodeEnum.YES) {
          return true;
        }
        if (yesOrNoString === YesOrNoCodeEnum.NO) {
          return false;
        }
        return null;
      })() as boolean | null


      (state as any)[attrName] = trueOrFalseBoolean
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },  

    setQuestionnaireSubmittedFlag: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setQuestionnaireSubmittedFlag(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.questionnaireSubmittedFlag = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },

    setHasCurrentlyBloodInStool: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasCurrentlyBloodInStool(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasCurrentlyBloodInStool = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverUnexpectedWeightLoss10In3: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverUnexpectedWeightLoss10In3(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everUnexpectedWeightLoss10In3 = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setExcessiveWeightGain: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setExcessiveWeightGain(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everExcessiveWeightGain = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setWaterRetention: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setWaterRetention(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everWaterRetention = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasSymptomsWorsenWithGlutenIngestion: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasSymptomsWorsenWithGlutenIngestion(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasSymptomsWorsenWithGlutenIngestion = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setStressLevel1To10: (state: QuestionnaireSliceOfState, action: PayloadAction<number>) => {
      log.debug(`setStressLevel1To10(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.stressLevel1To10 = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setAnxietyLevel1To10: (state: QuestionnaireSliceOfState, action: PayloadAction<number>) => {
      log.debug(`setAnxietyLevel1To10(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.anxietyLevel1To10 = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    // setEverAngerIrritability: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
    //   log.debug(`setEverAngerIrritability(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    //   state.everAngerIrritability = action.payload
    //   handleQuestionnaireAnswersJustUpdated(state)
    //   return state
    // },
    
    // setEverMoodSwings: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
    //   log.debug(`setEverMoodSwings(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    //   state.everMoodSwings = action.payload
    //   handleQuestionnaireAnswersJustUpdated(state)
    //   return state
    // },
    
    setEverDepression: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverDepression(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everDepression = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverSluggishness: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverSluggishness(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everSluggishness = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverRestlessness: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverRestlessness(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everRestlessness = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverBrainFog: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverBrainFog(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everBrainFog = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverSleepDisturbances: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverSleepDisturbances(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everSleepDisturbances = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    // setEverHyperactivity: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
    //   log.debug(`setEverHyperactivity(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    //   state.everHyperactivity = action.payload
    //   handleQuestionnaireAnswersJustUpdated(state)
    //   return state
    // },
    
    // setEverAcne: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
    //   log.debug(`setEverAcne(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    //   state.everAcne = action.payload
    //   handleQuestionnaireAnswersJustUpdated(state)
    //   return state
    // },
    
    setEverFlushing: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverFlushing(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everFlushing = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverHivesRashes: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverHivesRashes(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everHivesRashes = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverExcessiveSweating: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverExcessiveSweating(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everExcessiveSweating = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverHairLoss: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverHairLoss(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everHairLoss = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverFrequentIllness: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverFrequentIllness(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everFrequentIllness = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverFrequentUrgeToUrinate: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverFrequentUrgeToUrinate(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everFrequentUrgeToUrinate = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverGenitalItchOrDischarge: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverGenitalItchOrDischarge(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everGenitalItchOrDischarge = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    // setEverExcessiveSweatingAgain: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
    //   log.debug(`setEverExcessiveSweatingAgain(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    //   state.everExcessiveSweatingAgain = action.payload
    //   handleQuestionnaireAnswersJustUpdated(state)
    //   return state
    // },
    
    setHasHistoryOfAnemia: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasHistoryOfAnemia(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasHistoryOfAnemia = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasDifficultyOrPainSwallowing: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasDifficultyOrPainSwallowing(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasDifficultyOrPainSwallowing = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasPalpableMassOnAbdomen: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasPalpableMassOnAbdomen(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasPalpableMassOnAbdomen = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverStuffyNose: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverStuffyNose(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everStuffyNose = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverSinusProblems: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverSinusProblems(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everSinusProblems = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverHayFever: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverHayFever(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everHayFever = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverWateryOrItchyEyes: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverWateryOrItchyEyes(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everWateryOrItchyEyes = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverBagsOrDarkCirclesUnderEyes: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverBagsOrDarkCirclesUnderEyes(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everBagsOrDarkCirclesUnderEyes = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverSwollenRedOrStickyEyelids: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverSwollenRedOrStickyEyelids(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everSwollenRedOrStickyEyelids = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverBlurredVision: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverBlurredVision(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everBlurredVision = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverFrequentCoughing: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverFrequentCoughing(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everFrequentCoughing = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverSwollenAndOrOrDiscoloredTongue: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverSwollenAndOrOrDiscoloredTongue(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everSwollenAndOrOrDiscoloredTongue = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverFrequentNeedToClearThroat: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverFrequentNeedToClearThroat(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everFrequentNeedToClearThroat = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverCankerSores: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverCankerSores(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everCankerSores = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverExcessiveMucus: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverExcessiveMucus(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everExcessiveMucus = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverHorseness: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverHorseness(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everHorseness = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverTonguePain: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverTonguePain(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everTonguePain = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverItchyEars: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverItchyEars(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everItchyEars = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverDrainageFromEarOrWaxyBuildup: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverDrainageFromEarOrWaxyBuildup(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everDrainageFromEarOrWaxyBuildup = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverEarAchesOrInfections: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverEarAchesOrInfections(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everEarAchesOrInfections = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverRingingInEars: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverRingingInEars(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everRingingInEars = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverHeadaches: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverHeadaches(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everHeadaches = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverFaintnessOrLightheadedness: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverFaintnessOrLightheadedness(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everFaintnessOrLightheadedness = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverDizziness: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverDizziness(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everDizziness = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    // setEverPoorMemory: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
    //   log.debug(`setEverPoorMemory(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    //   state.everPoorMemory = action.payload
    //   handleQuestionnaireAnswersJustUpdated(state)
    //   return state
    // },
    
    // setEverDififficultyInMakingDecisions: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
    //   log.debug(`setEverDififficultyInMakingDecisions(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    //   state.everDififficultyInMakingDecisions = action.payload
    //   handleQuestionnaireAnswersJustUpdated(state)
    //   return state
    // },
    
    // setEverConfusion: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
    //   log.debug(`setEverConfusion(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    //   state.everConfusion = action.payload
    //   handleQuestionnaireAnswersJustUpdated(state)
    //   return state
    // },
    
    // setEverPoorConcentration: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
    //   log.debug(`setEverPoorConcentration(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    //   state.everPoorConcentration = action.payload
    //   handleQuestionnaireAnswersJustUpdated(state)
    //   return state
    // },
    
    // setEverSlurredSpeech: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
    //   log.debug(`setEverSlurredSpeech(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    //   state.everSlurredSpeech = action.payload
    //   handleQuestionnaireAnswersJustUpdated(state)
    //   return state
    // },
    
    // setEverPoorCoordination: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
    //   log.debug(`setEverPoorCoordination(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    //   state.everPoorCoordination = action.payload
    //   handleQuestionnaireAnswersJustUpdated(state)
    //   return state
    // },
    
    setEverIrregularHeartbeat: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverIrregularHeartbeat(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everIrregularHeartbeat = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverRapidOrPoundingHeartbeat: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverRapidOrPoundingHeartbeat(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everRapidOrPoundingHeartbeat = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverChestPain: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverChestPain(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everChestPain = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverShortnessOfBreath: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverShortnessOfBreath(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everShortnessOfBreath = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverAsthmaBronchitis: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverAsthmaBronchitis(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everAsthmaBronchitis = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverDifficultyBreathing: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverDifficultyBreathing(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everDifficultyBreathing = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverPainsOrAchesInJoints: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverPainsOrAchesInJoints(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everPainsOrAchesInJoints = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    // setEverPainsOrAchesInMuscles: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
    //   log.debug(`setEverPainsOrAchesInMuscles(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    //   state.everPainsOrAchesInMuscles = action.payload
    //   handleQuestionnaireAnswersJustUpdated(state)
    //   return state
    // },
    
    setEverArthritisJointSwelling: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverArthritisJointSwelling(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everArthritisJointSwelling = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    // setEverFeelingOfWeaknessOrTired: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
    //   log.debug(`setEverFeelingOfWeaknessOrTired(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    //   state.everFeelingOfWeaknessOrTired = action.payload
    //   handleQuestionnaireAnswersJustUpdated(state)
    //   return state
    // },
    
    setEverStiffOrLimitationOfMovement: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverStiffOrLimitationOfMovement(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everStiffOrLimitationOfMovement = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasFamilyHistoryOfGiCancer: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasFamilyHistoryOfGiCancer(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasFamilyHistoryOfGiCancer = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasHypertension: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasHypertension(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasHypertension = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasHyperlipidemia: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasHyperlipidemia(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasHyperlipidemia = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasType2Diabetes: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasType2Diabetes(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasType2Diabetes = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasType1Diabetes: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasType1Diabetes(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasType1Diabetes = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasAlcoholUse: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasAlcoholUse(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasAlcoholUse = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasOpiodUse: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasOpiodUse(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasOpiodUse = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasBowelObstruction: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasBowelObstruction(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasBowelObstruction = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasObesity: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasObesity(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasObesity = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasRadiationToIntestines: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasRadiationToIntestines(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasRadiationToIntestines = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasTerminalIlealResection: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasTerminalIlealResection(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasTerminalIlealResection = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasIleocecalValveResection: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasIleocecalValveResection(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasIleocecalValveResection = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasAmyloidosis: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasAmyloidosis(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasAmyloidosis = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasScleroderma: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasScleroderma(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasScleroderma = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasBowelStrictureOrAdhesions: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasBowelStrictureOrAdhesions(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasBowelStrictureOrAdhesions = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasSmallBowelDiverticulosis: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasSmallBowelDiverticulosis(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasSmallBowelDiverticulosis = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasAbdominalSurgeryHistory: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasAbdominalSurgeryHistory(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasAbdominalSurgeryHistory = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasIleocectomyHistory: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasIleocectomyHistory(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasIleocectomyHistory = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasCommonVariableImmunodeficiency: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasCommonVariableImmunodeficiency(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasCommonVariableImmunodeficiency = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasIgaDeficency: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasIgaDeficency(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasIgaDeficency = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasHivOrAids: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasHivOrAids(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasHivOrAids = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasLongTermProtonPumpInhibiterUse: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasLongTermProtonPumpInhibiterUse(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasLongTermProtonPumpInhibiterUse = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasAtrophicGastritis: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasAtrophicGastritis(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasAtrophicGastritis = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasPancreaticDisease: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasPancreaticDisease(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasPancreaticDisease = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasLiverDisease: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasLiverDisease(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasLiverDisease = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasCholecystectomy: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasCholecystectomy(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasCholecystectomy = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    // setHasDysphagia: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
    //   log.debug(`setHasDysphagia(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    //   state.hasDysphagia = action.payload
    //   handleQuestionnaireAnswersJustUpdated(state)
    //   return state
    // },
    
    setEverRegurgitation: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverRegurgitation(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everRegurgitation = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverSourOrBitterTaste: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverSourOrBitterTaste(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everSourOrBitterTaste = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasEsophogealCancer: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasEsophogealCancer(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasEsophogealCancer = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverFeversOrChills: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverFeversOrChills(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everFeversOrChills = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverLossOfAppetite: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverLossOfAppetite(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everLossOfAppetite = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasHiatalHernia: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasHiatalHernia(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasHiatalHernia = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setEverBoneFractureOsteoporosis: (state: QuestionnaireSliceOfState, action: PayloadAction<string>) => {
      log.debug(`setEverBoneFractureOsteoporosis(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.everBoneFractureOrOsteoporosis = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasFamilyHistoryOfCeliac: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasFamilyHistoryOfCeliac(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasFamilyHistoryOfCeliac = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    },
    
    setHasThyroidDisease: (state: QuestionnaireSliceOfState, action: PayloadAction<boolean>) => {
      log.debug(`setHasThyroidDisease(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
      state.hasThyroidDisease = action.payload
      handleQuestionnaireAnswersJustUpdated(state)
      return state
    }
    

    

    // ,

    // onFlushedAnswersToBackend: (state: any, action: any) => {
    //   log.debug(`onFlushedAnswersToBackend(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    //   return state
    // }
  },
  extraReducers (builder: ActionReducerMapBuilder<QuestionnaireSliceOfState>) {
    // omit posts loading reducers
    builder.addCase(syncDiagnosticAnswersToBackEndThunk.fulfilled, (questionnaireSliceOfState: QuestionnaireSliceOfState, action: PayloadAction) => {
      log.debug(`syncDiagnosticAnswersToBackEndThunk.fulfilled callback: questionnaireSliceOfState = ${safeStringify(questionnaireSliceOfState)},  action = ${safeStringify(action)}`)

      // We can directly add the new post object to our posts array
      // state.posts.push(action.payload)
    })
    builder.addCase(syncDiagnosticAnswersToBackEndThunk.pending, (questionnaireSliceOfState: QuestionnaireSliceOfState, action: PayloadAction) => {
      log.debug(`syncDiagnosticAnswersToBackEndThunk.pending callback: questionnaireSliceOfState = ${safeStringify(questionnaireSliceOfState)},  action = ${safeStringify(action)}`)

      // We can directly add the new post object to our posts array
      // state.posts.push(action.payload)
    })
    // https://github.com/reduxjs/redux-toolkit/issues/766#issuecomment-711415387
    // TODO: action type here?
    builder.addCase(syncDiagnosticAnswersToBackEndThunk.rejected, (state: QuestionnaireSliceOfState, action: unknown) => {
      log.warn(`syncDiagnosticAnswersToBackEndThunk.rejected callback: state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    })
    // builder.addCase(syncDiagnosticAnswersToBackEndThunk.loading, (state: any, action: any) => {
    //   log.debug(`syncDiagnosticAnswersToBackEndThunk.loading callback: state = ${safeStringify(state)},  action = ${safeStringify(action)}`)
    // })

    // builder.addCase(syncDiagnosticAnswersToBackEndThunk.succeeded, (state: any, action: any) => {
    //   log.debug(`syncDiagnosticAnswersToBackEndThunk.succeeded callback: state = ${safeStringify(state)},  action = ${safeStringify(action)}`)

    //   // We can directly add the new post object to our posts array
    //   // state.posts.push(action.payload)
    // })
    // builder.addCase(syncDiagnosticAnswersToBackEndThunk.failed, (state: any, action: any) => {
    //   log.debug(`syncDiagnosticAnswersToBackEndThunk.failed callback: state = ${safeStringify(state)},  action = ${safeStringify(action)}`)

    //   // We can directly add the new post object to our posts array
    //   // state.posts.push(action.payload)
    // })
    // builder.addCase(syncDiagnosticAnswersToBackEndThunk.idle, (state: any, action: any) => {
    //   log.debug(`syncDiagnosticAnswersToBackEndThunk.idle callback: state = ${safeStringify(state)},  action = ${safeStringify(action)}`)

    //   // We can directly add the new post object to our posts array
    //   // state.posts.push(action.payload)
    // })
  }
})

// Thunk function
// export type WonderWhatThisThunkShouldReturn = any
export async function fetchExistingDiagnosticAnswers (dispatch: Dispatch /* TODO: AppDispatch vs Dispatch */, getState: any): Promise<any> {
  // const response = await client.get('/fakeApi/todos')
  log.debug('fetchExistingDiagnosticAnswers() - Entering')
  // const container = useContainer();
  // const existingDiagnosticAnswerFetcher: ExistingDiagnosticAnswerFetcher = container.get<ExistingDiagnosticAnswerFetcher>(DI_TYPES.ExistingDiagnosticAnswerFetcher)
  const existingDiagnosticAnswerFetcher: ExistingDiagnosticAnswerFetcher = diContainer.get<ExistingDiagnosticAnswerFetcher>(DI_TYPES.ExistingDiagnosticAnswerFetcher)

  const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
  const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
  const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
  const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()
  log.debug(`fetchExistingDiagnosticAnswers() - oauth2UserId = ${oauth2UserId}`)


  return existingDiagnosticAnswerFetcher.fetchExistingAnswers(oauth2UserId as string).then((response: FetchDiagnosticAnswersResponse) => {
    log.debug(`fetchExistingDiagnosticAnswers() - existingDiagnosticAnswerFetcher.fetchExistingAnswers(): Returned a response = ${safeStringify(response)}`)

    // dispatch({ type: 'todos/todosLoaded', payload: response })
    dispatch(onFetchExistingDiagnosticAnswersSuccess(response.data as unknown as QuestionnaireSliceOfState))
  })
}

// export async function flushChangedAnswersToBackend(dispatch: any, getState: any) {
export async function flushChangedAnswersToBackend (dispatch: any, getState: any) {
  log.debug('flushChangedAnswersToBackend() - Entering')
  new Promise(async (resolve, reject) => {
    await setTimeout(() => {
      log.debug('flushChangedAnswersToBackend() - Simulating AJAX response now')
      resolve('ok')
    }, 200)
  }).then((response: unknown) => {
    log.debug(`flushChangedAnswersToBackend() - response = ${safeStringify(response)}`)

    // dispatch({ type: 'todos/todosLoaded', payload: response })
    dispatch(onFetchExistingDiagnosticAnswersSuccess(response as QuestionnaireSliceOfState))
  })
}

export const { 
  clearAllQuestionnaire,
  setHeightInInches, 
  setAgeInYears, 
  setWeightInLbs, 
  setEverNausea,

  // DRY
  setEverString,
  setUpToThreeTimePerWeek,
  setUpperLowerBoth,
  setAlwaysSometimesNever,
  setOneToThreeXDailyMoreOrLess,
  setYesOrNo,
  setOneToTenValue,
  setRawString,
  setRawNumber,
  
  setEverVomiting, 
  setEverAbdominalPain,
  setAbdominalPainHowOften,
  setAbdominalPainWhere,
  setClearMyQuestionnaireDangerWillRobinson,
  setClearMyFeedbackDangerWillRobinson,
  setUnsubmitDangerWillRobinson,
  setEverStiffOrLimitationOfMovement,
  onFetchExistingDiagnosticAnswersSuccess,
  setQuestionnaireSubmittedFlag,
  setBowelTroubleHowOften, 
} = questionnaireSlice.actions

export default questionnaireSlice.reducer
// https://redux.js.org/tutorials/fundamentals/part-6-async-logic

// const initialState = []

// export default function todosReducer(state = initialState, action) {
//   switch (action.type) {
//     // omit other reducer cases
//     case 'todos/todosLoaded': {
//       // Replace the existing state entirely by returning the new value
//       return action.payload
//     }
//     default:
//       return state
//   }
// }

// TODO: How to do this?
export const selectEntireQuestionnaireSliceOfState = (state: CombinedState<SbhcCompositeState>): QuestionnaireSliceOfState => {
  log.trace(`selectEntireQuestionnaireSliceOfState(): state = ${safeStringify(state)}`)
  return state?.questionnaire
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectHeightInInches = (state: any) => state[namedReducer.QUESTIONNAIRE].setHeightInInches.value
export const selectHeightInInches = (state: CombinedState<SbhcCompositeState>): StateScalarAttribute<number> => {
  log.trace(`selectHeightInInches(): state = ${safeStringify(state)}`)
  const retval: StateScalarAttribute<number> = state?.questionnaire?.heightInInches ?? undefined
  // log.debug(`selectHeightInInches(): Returning ${retval}`)
  // return retval
  return retval
  // return 14
}

export const selectAgeInYears = (state: CombinedState<SbhcCompositeState>): StateScalarAttribute<number> => {
  log.trace(`selectAgeInYears(): state = ${safeStringify(state)}`)
  const retval: StateScalarAttribute<number> = state?.questionnaire?.ageInYears ?? undefined
  return retval
}

// TODO: Should this return a string or a number
export const selectWeightInLbs = (state: CombinedState<SbhcCompositeState>): StateScalarAttribute<number> => {
  log.trace(`selectWeightInLbs(): state = ${safeStringify(state)}`)
  const retval: StateScalarAttribute<number> = state?.questionnaire?.weightInLbs ?? undefined
  return retval
}

export const selectEverNausea = (state: CombinedState<SbhcCompositeState>): StateScalarAttribute<string> => {
  log.trace(`selectEverNausea(): state = ${safeStringify(state)}`)
  const retval: StateScalarAttribute<string> = state?.questionnaire?.everNausea ?? undefined
  return retval
}

// DRYer?
export const selectEverString = (attrName: string, state: CombinedState<SbhcCompositeState>): StateScalarAttribute<string> => {
  log.trace(`selectEverString(): attrName = ${attrName},  state = ${safeStringify(state)}`)
  // const retval: StateScalarAttribute<string> = state?.questionnaire?[attrName] ?? undefined;
  const retval: StateScalarAttribute<string> = (state != null && state.questionnaire) ? (state.questionnaire as any)[attrName] : undefined;
  return retval
}

export const selectRawString = (attrName: string, state: CombinedState<SbhcCompositeState>): StateScalarAttribute<string> => {
  log.debug(`selectRawString(): attrName = ${attrName},  state = ${safeStringify(state)}`)
  // const retval: StateScalarAttribute<string> = state?.questionnaire?[attrName] ?? undefined;
  const retval: StateScalarAttribute<string> = (state != null && state.questionnaire != null) ? ((state.questionnaire as any)?.[attrName] != null ? (state.questionnaire as any)?.[attrName].toString() : undefined) : undefined;
  return retval
}

export const selectUpToThreeTimePerWeek = (attrName: string, state: CombinedState<SbhcCompositeState>): StateScalarAttribute<string> => {
  log.trace(`selectUpToThreeTimePerWeek(): attrName = ${attrName},  state = ${safeStringify(state)}`)
  // const retval: StateScalarAttribute<string> = state?.questionnaire?[attrName] ?? undefined;
  const retval: StateScalarAttribute<string> = (state != null && state.questionnaire) ? (state.questionnaire as any)[attrName] : undefined;
  return retval
}

export const selectUpperLowerBoth = (attrName: string, state: CombinedState<SbhcCompositeState>): StateScalarAttribute<string> => {
  log.trace(`selectUpperLowerBoth(): attrName = ${attrName},  state = ${safeStringify(state)}`)
  // const retval: StateScalarAttribute<string> = state?.questionnaire?[attrName] ?? undefined;
  const retval: StateScalarAttribute<string> = (state != null && state.questionnaire) ? (state.questionnaire as any)[attrName] : undefined;
  return retval
}

export const selectAlwaysSometimesNever = (attrName: string, state: CombinedState<SbhcCompositeState>): StateScalarAttribute<string> => {
  log.trace(`selectAlwaysSometimesNever(): attrName = ${attrName},  state = ${safeStringify(state)}`)
  // const retval: StateScalarAttribute<string> = state?.questionnaire?[attrName] ?? undefined;
  const retval: StateScalarAttribute<string> = (state != null && state.questionnaire) ? (state.questionnaire as any)[attrName] : undefined;
  return retval
}

export const selectOneToThreeXDailyMoreOrLess = (attrName: string, state: CombinedState<SbhcCompositeState>): StateScalarAttribute<string> => {
  log.trace(`selectOneToThreeXDailyMoreOrLess(): attrName = ${attrName},  state = ${safeStringify(state)}`)
  // const retval: StateScalarAttribute<string> = state?.questionnaire?[attrName] ?? undefined;
  const retval: StateScalarAttribute<string> = (state != null && state.questionnaire) ? (state.questionnaire as any)[attrName] : undefined;
  return retval
}

export const selectOneToTenValue = (attrName: string, state: CombinedState<SbhcCompositeState>): StateScalarAttribute<number> => {
  log.trace(`selectOneToTenValue(): attrName = ${attrName},  state = ${safeStringify(state)}`)
  const retval: StateScalarAttribute<number> = (state != null && state.questionnaire) ? (state.questionnaire as any)[attrName] : undefined;
  return retval
}

export const selectYesOrNo = (attrName: string, state: CombinedState<SbhcCompositeState>): StateScalarAttribute<string> => {
  log.trace(`selectYesOrNo(): attrName = ${attrName},  state = ${safeStringify(state)}`)
  // const retval: StateScalarAttribute<string> = state?.questionnaire?[attrName] ?? undefined;
  const booleanValue: boolean | null = (state != null && state.questionnaire) ? (state.questionnaire as any)[attrName] : undefined;
  if ( booleanValue === true ) {
    return YesOrNoCodeEnum.YES
  }
  if ( booleanValue === false ) {
    return YesOrNoCodeEnum.NO
  }

  return YesOrNoCodeEnum.UNSET
}


export const selectQuestionniareSubmittedFlag = (state: CombinedState<SbhcCompositeState>): StateScalarAttribute<boolean> => {
  log.trace(`selectQuestionniareSubmittedFlag(): state = ${safeStringify(state)}`)
  const retval: StateScalarAttribute<boolean> = state?.questionnaire?.questionnaireSubmittedFlag ?? undefined
  return retval
}

export const selectEverVomiting = (state: CombinedState<SbhcCompositeState>): StateScalarAttribute<string> => {
  log.trace(`selectEverVomiting(): state = ${safeStringify(state)}`)
  const retval: StateScalarAttribute<string> = state?.questionnaire?.everVomiting ?? undefined
  return retval
}

export const selectEverAbdominalPain = (state: CombinedState<SbhcCompositeState>): StateScalarAttribute<string> => {
  log.trace(`selectEverAbdominalPain(): state = ${safeStringify(state)}`)
  const retval: StateScalarAttribute<string> = state?.questionnaire?.everAbdominalPain ?? undefined
  return retval
}

export const selectAbdominalPainHowOften = (state: CombinedState<SbhcCompositeState>): StateScalarAttribute<string> => {
  log.trace(`selectAbdominalPainHowOften(): state = ${safeStringify(state)}`)
  const retval: StateScalarAttribute<string> = state?.questionnaire?.abdominalPainHowOften ?? undefined
  return retval
}

export const selectAbdominalPainWhere = (state: CombinedState<SbhcCompositeState>): StateScalarAttribute<string> => {
  log.trace(`selectAbdominalPainWhere(): state = ${safeStringify(state)}`)
  const retval: StateScalarAttribute<string> = state?.questionnaire?.abdominalPainWhere ?? undefined
  return retval
}

export const selectBowelTroubleHowOften = (state: CombinedState<SbhcCompositeState>): StateScalarAttribute<string> => {
  log.trace(`selectBowelTroubleHowOften(): state = ${safeStringify(state)}`)
  const retval: StateScalarAttribute<string> = state?.questionnaire?.bowelTroubleHowOften ?? undefined
  return retval
}

export const selectEverStiffOrLimitationOfMovement = (state: CombinedState<SbhcCompositeState>): StateScalarAttribute<string> => {
  log.trace(`selectEverStiffOrLimitationOfMovement(): state = ${safeStringify(state)}`)
  const retval: StateScalarAttribute<string> = state?.questionnaire?.everStiffOrLimitationOfMovement ?? undefined
  return retval
}

// https://redux-toolkit.js.org/usage/usage-guide
// https://github.com/reduxjs/redux-thunk
// https://stackoverflow.com/questions/66195408/sending-requests-from-react-redux-to-backend-api
// https://redux.js.org/tutorials/essentials/part-5-async-logic
const handleQuestionnaireAnswersJustUpdated = (state: QuestionnaireSliceOfState) => {
  // log.debug(`handleQuestionnaireAnswersJustUpdated() - Entering with typeof state = ${typeof state}`)
  log.debug(`handleQuestionnaireAnswersJustUpdated() - Entering with state = ${JSON.stringify(state)}`)
  // TODO: get state
  // const state = store.getState() // [namedReducer.QUESTIONNAIRE]
  // log.debug(`handleQuestionnaireAnswersJustUpdated() - state = ${safeStringify(store.getState())}`)

  // const action = undefined // I dont know yet
  // log.debug(`handleQuestionnaireAnswersJustUpdated(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)

  // flushChangedAnswersToBackend(state, action);

  // const currentHeightInInches = useDispatch(flushChangedAnswersToBackend)
  // const currentHeightInInches = useSelector(flushChangedAnswersToBackend)
  // https://redux.js.org/faq/reducers#how-do-i-share-state-between-two-reducers-do-i-have-to-use-combinereducers
  // https://stackoverflow.com/questions/34333979/accessing-other-parts-of-the-state-when-using-combined-reducers
  // https://github.com/reduxjs/redux-thunk
  // https://redux.js.org/api/combinereducers
  // https://github.com/redux-utilities/reduce-reducers
  // follow the basic rules of reducers: (state, action) => newState
  // return (dispatch: any, getState: any) => {
  //   log.debug("handleQuestionnaireAnswersJustUpdated() - Entering Inner callback")

  // }
}
