import diContainer from '../inversify.config'
import { WhoAmISvc } from "../auth/WhoAmISvc";
import DI_TYPES from "../diTypes";
import { SignInSvc } from "../auth/SignInSvc";
import { useDispatch } from "react-redux";
import { clearIdToken } from "../auth/authenticationSlice";
import { clearAllQuestionnaire } from '../questionnaire/questionnaireSlice';
import { useEffect } from 'react';
import { WhoAmIWriteService } from './WhoAmIWriteService';

function SignOutWidgetComponent () {
  const dispatch = useDispatch()
  dispatch(clearIdToken())
  dispatch(clearAllQuestionnaire())
  sessionStorage.removeItem(`id_token`);
  const whoAmIWriteService: WhoAmIWriteService = diContainer.get<WhoAmIWriteService>(DI_TYPES.WhoAmIWriteService);
  whoAmIWriteService.logout()
  // window.location.href = window.location.origin
  // setTimeout( () => {
  //   window.location.href = "/"
  // }, 700);

  // useEffect(() => {
  //   window.location.href = window.location.origin  
  // }, []);

  


  return (
<>
You are now signed out.
</>
  ) 
}

export default SignOutWidgetComponent
