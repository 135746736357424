import safeStringify from '../string/safeStringify'
import React, { useState, useReducer, useEffect } from 'react'
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import { getLogger } from '../log/getLogger'
import { StateScalarAttribute } from '../commonSrc/questionnaire/allQuestionnaireAttributes';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { setAlwaysSometimesNever, selectAlwaysSometimesNever } from '../questionnaire/questionnaireSlice';
import { AlwaysSometimesNeverCodeEnum, AlwaysSometimesNeverLabelEnum } from '../commonSrc/constant/alwaysSometimesNeverConstant';

const log = getLogger('question.AlwaysSometimesNeverQuestionComponent')

export default function AlwaysSometimesNeverQuestionComponent (props: any) {
  const label: string = props.label  // Nausea
  const attrName: string = props.attrName // everNausea
  const onDiagnosticAnswersChanged = props.onDiagnosticAnswersChanged
  log.debug(`props = ${JSON.stringify(props)}`)
  log.debug(`label = ${label}`)

  const dispatch = useDispatch()




  const onChange = async (event: React.ChangeEvent<HTMLInputElement> | any): Promise<void> => {
    log.debug(`onChange() - Entering with event = ${safeStringify(event)}`)
    dispatch(setAlwaysSometimesNever({eventValue: event.target.value, attrName: attrName}))
    onDiagnosticAnswersChanged()
  }

  const currentValue: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => {
    return selectAlwaysSometimesNever(attrName, state)
  })

  return (
    <>
      <div className="always-sometimes-never-question-component">
        <fieldset>
          <label>{label}</label>
          <div className="radio-group">
            <label>
              <input type='radio' name={attrName} value={AlwaysSometimesNeverCodeEnum.ALWAYS} checked={currentValue === AlwaysSometimesNeverCodeEnum.ALWAYS} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event)}/>
              <span>{AlwaysSometimesNeverLabelEnum.ALWAYS}</span>
            </label>
            <label>
              <input type='radio' name={attrName} value={AlwaysSometimesNeverCodeEnum.SOMETIMES} checked={currentValue === AlwaysSometimesNeverCodeEnum.SOMETIMES} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event)}/>
              <span>{AlwaysSometimesNeverLabelEnum.SOMETIMES}</span>
            </label>
            <label>
              <input type='radio' name={attrName} value={AlwaysSometimesNeverCodeEnum.NEVER} checked={currentValue === AlwaysSometimesNeverCodeEnum.NEVER} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event)}/>
              <span>{AlwaysSometimesNeverLabelEnum.NEVER}</span>
            </label>
          </div>
        </fieldset> 
      </div>
    </>
  )
}