
import { useState } from "react";
import { WhoAmISvc } from "../auth/WhoAmISvc";
import DI_TYPES from "../diTypes";
import diContainer from '../inversify.config'
import { getLogger } from '../log/getLogger'
import safeStringify from "../string/safeStringify";
import { useDeveloperMode } from "./useDeveloperMode";
import {
  setClearMyFeedbackDangerWillRobinson,
  clearMyFeedbackUpstreamDangerWillRobinsonThunk,
  setUnsubmitDangerWillRobinson,
  unsubmitUpstreamDangerWillRobinsonThunk,
  clearMyQuestionnaireUpstreamDangerWillRobinsonThunk,
  IThunkApi,
  setQuestionnaireSubmittedFlag,
  setClearMyQuestionnaireDangerWillRobinson,
  clearMyUserAttributesDangerWillRobinsonThunk,
} from '../questionnaire/questionnaireSlice'
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import { Link, useNavigate } from "react-router-dom";
import { setClearMyUserAttributesDangerWillRobinson } from "../user/userAttributesSlice";


const LOG = getLogger('DevModeComponent')

export default function DevModeComponent () {
  const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
  const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
  const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
  const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

  const dispatch = useDispatch()


  // const currentValueForDeveloperMode: boolean = (() => {
  //   const devModeCookieValue = getCookie("developerMode")
  //   if ((devModeCookieValue as any) === true || devModeCookieValue === 'true') {
  //     return true;
  //   } 

  //   return false;
  // })()

  const [developerMode, updateDeveloperMode] = useDeveloperMode();
  const onToggleDeveloperMode = async (event: React.ChangeEvent<HTMLInputElement> | any): Promise<void> => {
    LOG.debug(`onToggleDeveloperMode() - Entering with event.target.value = ${event.target.value} (${typeof event.target.value}),   event.target.checked = ${event.target.checked} (${typeof event.target.checked}),  event = ${safeStringify(event)}`, event)
  
    if (developerMode) {
      updateDeveloperMode(false)
    } else {
      updateDeveloperMode(true)
    }
  }

  const onClearMyQuestionnaireDangerWillRobinsonUpstream = () => {
    LOG.warn('onClearMyQuestionnaireDangerWillRobinsonUpstream(): Entering')

    try {
      // setSyncDiagnosticAnswersToBackEndStatus('pending')

      LOG.debug(`onClearMyQuestionnaireDangerWillRobinsonUpstream(): Preparing to dispatch a syncDiagnosticAnswersToBackEndThunk()...`)
      const originalPromiseResult: AsyncThunkAction<any, void, IThunkApi> = dispatch(clearMyQuestionnaireUpstreamDangerWillRobinsonThunk())
      LOG.debug(`onClearMyQuestionnaireDangerWillRobinsonUpstream(): Finished dispatching a clearMyQuestionnaireUpstreamDangerWillRobinsonThunk().  originalPromiseResult = ${safeStringify(originalPromiseResult)}`)
    } catch (err) {
      LOG.error('onClearMyQuestionnaireDangerWillRobinsonUpstream(): err = ', err)
    } finally {
     //  setSyncDiagnosticAnswersToBackEndStatus('idle')
    }
  };

  const onClearMyFeedbackDangerWillRobinsonUpstream = () => {
    LOG.warn('onClearMyFeedbackDangerWillRobinsonUpstream(): Entering')

    try {
      // setSyncDiagnosticAnswersToBackEndStatus('pending')

      LOG.debug(`onClearMyFeedbackDangerWillRobinsonUpstream(): Preparing to dispatch a syncDiagnosticAnswersToBackEndThunk()...`)
      const originalPromiseResult: AsyncThunkAction<any, void, IThunkApi> = dispatch(clearMyFeedbackUpstreamDangerWillRobinsonThunk())
      LOG.debug(`onClearMyFeedbackDangerWillRobinsonUpstream(): Finished dispatching a clearMyQuestionnaireUpstreamDangerWillRobinsonThunk().  originalPromiseResult = ${safeStringify(originalPromiseResult)}`)
    } catch (err) {
      LOG.error('onClearMyFeedbackDangerWillRobinsonUpstream(): err = ', err)
    } finally {
      // setSyncDiagnosticAnswersToBackEndStatus('idle')
    }
  };

  const onUnsubmitDangerWillRobinsonUpstream = () => {
    LOG.warn('onUnsubmitDangerWillRobinsonUpstream(): Entering')

    try {
      // setSyncDiagnosticAnswersToBackEndStatus('pending')

      LOG.debug(`onUnsubmitDangerWillRobinsonUpstream(): Preparing to dispatch a syncDiagnosticAnswersToBackEndThunk()...`)
      const originalPromiseResult: AsyncThunkAction<any, void, IThunkApi> = dispatch(unsubmitUpstreamDangerWillRobinsonThunk())
      LOG.debug(`onUnsubmitDangerWillRobinsonUpstream(): Finished dispatching a unsubmitUpstreamDangerWillRobinsonThunk().  originalPromiseResult = ${safeStringify(originalPromiseResult)}`)
    } catch (err) {
      LOG.error('onUnsubmitDangerWillRobinsonUpstream(): err = ', err)
    } finally {
      // setSyncDiagnosticAnswersToBackEndStatus('idle')
    }
  };

  const navigate = useNavigate();

  const onUnsubmitAndClearHypothesesDangerWillRobinson = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    LOG.debug(`onUnsubmitAndClearHypothesesDangerWillRobinson() - Entering with event = ${safeStringify(event)}`)
    dispatch(setUnsubmitDangerWillRobinson())
    onUnsubmitDangerWillRobinsonUpstream()
    dispatch(setClearMyFeedbackDangerWillRobinson())
    onClearMyFeedbackDangerWillRobinsonUpstream()
    dispatch(setQuestionnaireSubmittedFlag(false))
    // navigate(`/questionnaire`, { replace: true });
  }

  const onClearMyUserAttributesDangerWillRobinson = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    LOG.debug(`onClearMyUserAttributesDangerWillRobinson() - Entering with event = ${safeStringify(event)}`)
    dispatch(setClearMyUserAttributesDangerWillRobinson())

    try {
      LOG.debug(`onClearMyUserAttributesDangerWillRobinson(): Preparing to dispatch a clearMyUserAttributesDangerWillRobinsonThunk()...`)
      const originalPromiseResult: AsyncThunkAction<any, void, IThunkApi> = dispatch(clearMyUserAttributesDangerWillRobinsonThunk())
      LOG.debug(`onClearMyUserAttributesDangerWillRobinson(): Finished dispatching a clearMyUserAttributesDangerWillRobinsonThunk().  originalPromiseResult = ${safeStringify(originalPromiseResult)}`)
    } catch (err) {
      LOG.error('onClearMyUserAttributesDangerWillRobinson(): err = ', err)
    } finally {
     //  setSyncDiagnosticAnswersToBackEndStatus('idle')
    }

  }

  const onClearMyQuestionnaireDangerWillRobinson = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    LOG.debug(`onClearMyQuestionnaireDangerWillRobinson() - Entering with event = ${safeStringify(event)}`)
    dispatch(setClearMyQuestionnaireDangerWillRobinson())
    onClearMyQuestionnaireDangerWillRobinsonUpstream()
    dispatch(setQuestionnaireSubmittedFlag(false))
    // navigate(`/questionnaire`, { replace: true });

  }

  const onClearMyFeedbackDangerWillRobinson = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    LOG.debug(`onClearMyFeedbackDangerWillRobinson() - Entering with event = ${safeStringify(event)}`)
    dispatch(setClearMyFeedbackDangerWillRobinson())
    onClearMyFeedbackDangerWillRobinsonUpstream()
    dispatch(setQuestionnaireSubmittedFlag(false))
    // navigate(`/questionnaire`, { replace: true });
  }

  const onUnsubmitDangerWillRobinson = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    LOG.debug(`onUnsubmitDangerWillRobinson() - Entering with event = ${safeStringify(event)}`)
    dispatch(setUnsubmitDangerWillRobinson())
    onUnsubmitDangerWillRobinsonUpstream()
    dispatch(setQuestionnaireSubmittedFlag(false))
    // navigate(`/questionnaire`, { replace: true });
  }

  // const clearMyUserAttributesDangerWillRobinson = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
  //   LOG.debug(`clearMyUserAttributesDangerWillRobinson() - Entering with event = ${safeStringify(event)}`)
  //   dispatch(setClearMyUserAttributesDangerWillRobinson())
  //   clearMyUserAttributesDangerWillRobinson()
  //   // navigate(`/questionnaire`, { replace: true });
  // }
  
  return (
    <>
      <div className="form-check form-switch">
        <input className="form-check-input" type="checkbox" id="devModeToggleSwitch"  checked={developerMode} onChange={async (event) => await onToggleDeveloperMode(event)}/>
        <label className="form-check-label" htmlFor="devModeToggleSwitch">Developer Mode</label>
      </div>

      <div>Developer Mode is 
        {developerMode === true && 
          <span>&nbsp;ENABLED</span>
        }
        {developerMode !== true && 
          <span>&nbsp;DISABLED</span>
        }
      </div>
      <div>
        ({developerMode ? 'TRUE' : 'FALSE'})
      </div>

      <div className="mt-2">
        <div className="card">
          <div className="card-header developer-mode">
            Danger Will Robinson
          </div>
          <div className="card-body">
            <i>Reload your browser after clicking any of these buttons</i><br /><br />
            <span>(Sometimes you have to Click button, reload-browser, click same button again)</span><br /><br />

            <button className="btn btn-developer-mode btn-sm" onClick={(event) => { event.preventDefault(); onClearMyFeedbackDangerWillRobinson(event);  return false;}}>Clear Hypothesis Feedback</button>
        &nbsp;
        <button className="btn btn-developer-mode btn-sm" onClick={(event) => { event.preventDefault(); onUnsubmitDangerWillRobinson(event);  return false;}}>Unsubmit</button>
        &nbsp;
            <button className="btn btn-developer-mode btn-sm" onClick={(event) => { event.preventDefault(); onUnsubmitAndClearHypothesesDangerWillRobinson(event); return false; }}>Unsubmit &amp; Clear Hypotheses</button>
            &nbsp;
            <button className="btn btn-developer-mode btn-sm" onClick={(event) => { event.preventDefault(); onClearMyQuestionnaireDangerWillRobinson(event); return false; }}>Clear Questionnaire Answers</button>
            &nbsp;
            <button className="btn btn-developer-mode btn-sm" onClick={(event) => { event.preventDefault(); onClearMyUserAttributesDangerWillRobinson(event); return false; }}>Clear My User Attributes</button>
            &nbsp;
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" id="devModeToggleSwitch" checked={developerMode} onChange={async (event) => await onToggleDeveloperMode(event)} />
              <label className="form-check-label" htmlFor="devModeToggleSwitch">Developer Mode</label>
            </div>
          </div>
        </div>
      </div>

      <div>
        <ul>
          <li><Link to={`/`}>Go to /</Link></li>
          <li><Link to={`/agreement`}>Go to /agreement</Link></li>
          <li><Link to={`/questionnaire`}>Go to /questionnaire</Link></li>
          <li><Link to={`/findings`}>Go to /findings</Link></li>
        </ul>
      </div>
    </>
  )
}