import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function Auth0SignUpPageRedirector() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect( () => {
    if (!isAuthenticated) {
      loginWithRedirect({
        authorizationParams: {
          // screen_hint: 'signup'
        }
      })    
    }  
  }, []);

  return (
    <></>
  );
}
