import { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { Link } from "react-router-dom"
import WhoAmINavbarWidgetComponent from "../auth/WhoAmINavbarWidgetComponent"
import { WhoAmISvc } from "../auth/WhoAmISvc"
import DI_TYPES from "../diTypes";
import diContainer from '../inversify.config'
import { useDeveloperMode } from "../developer/useDeveloperMode";
import * as Rx from "rxjs";

function TopNavbarComponent () {
  const [developerMode] = useDeveloperMode();


  const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
  const [isLoggedIn, setIsLoggedIn] = useState(whoAmISvc.amILoggedIn())
  const store = useStore();

  const observable: Rx.Observable<void> = whoAmISvc.getStateChangeObservable()
  let subscription: Rx.Subscription

  useEffect(() => {
    console.log('TopNavbarComponent mounted');

    const subscriptionToStore = store.subscribe(()=>{
      // LOG.debug(`Redux state has changed...`)   
      setIsLoggedIn(whoAmISvc.amILoggedIn())                    
    })

    subscription = observable.subscribe(() => {
      handleAutenticatedUserStateChange()
    })

    return () => {
      console.log('unmounting...');
      subscriptionToStore()
      subscription.unsubscribe()
    }
   
  }, [])  // <-- add this empty array here
  

  const handleAutenticatedUserStateChange = () => {
    setIsLoggedIn(whoAmISvc.amILoggedIn())
  }

  return (
<nav className="navbar navbar-expand-md navbar-dark bg-dark" aria-label="Fourth navbar example">
  <div className="container-fluid">
    <Link className="navbar-brand" to={`https://www.secondbrainhealthcare.com/`}>Second Brain</Link>

    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>

    <div className="collapse navbar-collapse">
      <ul className="navbar-nav me-auto mb-2 mb-md-0">
        <li className="nav-item">
          <Link className="nav-link active" to={`/questionnaire`}>Questionnaire</Link>
        </li>   
        {developerMode && 
          <>         
            {/* <li className="nav-item">
              <Link className="nav-link nav-link-developer-mode" to={`/ri`}>/ri</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link nav-link-developer-mode" to={`/devmode`}>/devmode</Link>
            </li> */}
            {/* <li className="nav-item">
              <Link className="nav-link nav-link-developer-mode" to={`/findings`}>/findings</Link>
            </li> */}
          </> 
        }
      </ul>
      {/* <ul className="navbar-nav">
        {isLoggedIn ? (
          <WhoAmINavbarWidgetComponent/>
        ) : (
          <>
            <li className="nav-item">
              <Link className="nav-link" to={`/signin`}>Sign In</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={`/signup`}>Sign Up</Link>
            </li>
            </>
        )}
      </ul> */}
    </div>
  </div>
</nav>
) 
}

// https://stackoverflow.com/questions/40477245/is-it-possible-to-use-if-else-statement-in-react-render-function


export default TopNavbarComponent
