// var log4js = require('../log4js.combined')
// import log4js from '../log4js.combined'
// import Log4js from "../log4js.combined.";
// var log4js = require('../log4js.combined')
// var log4javascript = require('log4javascript')

// log4js.configure({
//   appenders: { 'out': { type: 'stdout' } },
//   categories: { default: { appenders: ['out'], level: 'trace' } }
// });

const YES_DEBUG_PLEASE: boolean = true

export class Logger {
  private readonly m_name: string

  public constructor (name: string) {
    this.m_name = name
  }

  public trace (arg0: any, ...more: any[]): void {
    // if (more.length == 0) {
    //   console.log(`${this.m_name}   ${arg0}`)
    // } else if (more.length > 0) {
    //   console.log(`${this.m_name}   ${arg0}`, more)
    // }
  }

  public debug (arg0: any, ...more: any[]): void {
    if (YES_DEBUG_PLEASE) {
      if (more.length == 0) {
        console.log(`${new Date().toISOString()} [ DEBUG] ${this.m_name}   ${arg0}`)
      } else if (more.length > 0) {
        console.log(`${new Date().toISOString()} [ DEBUG] ${this.m_name}   ${arg0}`, more)
      }
    }
  }

  public info (arg0: any, ...more: any[]): void {
    if (YES_DEBUG_PLEASE) {
      if (more.length == 0) {
        console.log(`${new Date().toISOString()} [  INFO] ${this.m_name}   ${arg0}`)
      } else if (more.length > 0) {
        console.log(`${new Date().toISOString()} [  INFO] ${this.m_name}   ${arg0}`, more)
      }
    }
  }

  public warn (arg0: any, ...more: any[]): void {
    if (more.length == 0) {
      console.log(`${new Date().toISOString()} [  WARN] ${this.m_name}   ${arg0}`)
    } else if (more.length > 0) {
      console.log(`${new Date().toISOString()} [  WARN] ${this.m_name}   ${arg0}`, more)
    }
  }

  public error (arg0: any, ...more: any[]): void {
    if (more.length == 0) {
      console.log(`${new Date().toISOString()} [ ERROR] ${this.m_name}   ${arg0}`)
    } else if (more.length > 0) {
      console.log(`${new Date().toISOString()} [ ERROR] ${this.m_name}   ${arg0}`, more)
    }
  }
}

export class LoggerFactory {
  private static readonly m_loggers: Map<string, Logger> = new Map()

  public static getLogger (name: string): Logger {
    if (!this.m_loggers.has(name)) {
      const logger = new Logger(name)
      this.m_loggers.set(name, logger)
    }

    return this.m_loggers.get(name) as Logger
  }
}

// export const getLogger = (loggerName: string) => {
//   const log = log4javascript.getLogger(loggerName);
//   log.setLevel(log4javascript.Level.TRACE)
//   var appender = new log4javascript.BrowserConsoleAppender();
//   var layout = new log4javascript.PatternLayout("%d{HH:mm:ss} %-5p - %m%n");
//   appender.setLayout(layout);
//   log.addAppender(appender);
//   return log
// }

export const getLogger = (loggerName: string) => {
  return LoggerFactory.getLogger(loggerName)
}

