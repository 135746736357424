import { isHasNotTrue, isHasTrue, isTrueLike } from "../boolean/booleanUtil";
import { QuestionnaireSliceOfState } from "./QuestionnaireSliceOfState";
import { CurrentPreviousNeverCodeEnum, everCurrentOrPreviously, everPreviousOrNever, everYesCurrently } from "../constant/currentPreviousNeverConstant";
import { FrequentlySometimesRarelyCodeEnum } from "../constant/frequentlySometimesRarelyConstant";
import { UpTo3PlusTimesPerWeekCodeEnum } from "../constant/upToThreePlusTimesPerWeekConstant";
import { OneToSixPlusMonthsCodeEnum } from "../constant/oneToSixPlusMonthsConstant";

export function hasPoopTypeSeparateHardLumps(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isHasTrue(questionnaireSliceOfState.hasPoopTypeSeparateHardLumps)
}

export function hasPoopTypeFluffyRaggedMushy(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isHasTrue(questionnaireSliceOfState.hasPoopTypeFluffyRaggedMushy)
}

export function hasPoopTypeSausageShapedButLumpy(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isHasTrue(questionnaireSliceOfState.hasPoopTypeSausageShapedButLumpy)
}

export function hasPoopTypeSausageWithCracks(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isHasTrue(questionnaireSliceOfState.hasPoopTypeSausageWithCracks)
}

export function hasPoopTypeSoftBlobsClearEdges(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isHasTrue(questionnaireSliceOfState.hasPoopTypeSoftBlobsClearEdges)
}

export function hasPoopTypeWatery(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isHasTrue(questionnaireSliceOfState.hasPoopTypeWatery)
}

export function hasPoopTypeSnakeSmooth(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isHasTrue(questionnaireSliceOfState.hasPoopTypeSnakeSmooth)
}

export function hasBowelTrouble(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return hasConstipationOrHasDiarrheaOrHasSteatorrhea(questionnaireSliceOfState)
}

export function hasBowelTrouble3PlusTimesPerWeek(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return hasBowelTrouble(questionnaireSliceOfState) && questionnaireSliceOfState.bowelTroubleHowOften === UpTo3PlusTimesPerWeekCodeEnum.UP_TO_THREEPLUS_TIMES_WEEKLY
}

export function hasOrHadBowelTroubleOneTo3PlusTimesPerWeek(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return hasBowelTrouble(questionnaireSliceOfState) && (questionnaireSliceOfState.bowelTroubleHowOften === UpTo3PlusTimesPerWeekCodeEnum.UP_TO_THREEPLUS_TIMES_WEEKLY || questionnaireSliceOfState.bowelTroubleHowOften === UpTo3PlusTimesPerWeekCodeEnum.WEEKLY)
}

export function hasOrHadAbdominalPain(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return (questionnaireSliceOfState.everAbdominalPain === CurrentPreviousNeverCodeEnum.CURRENT) || (questionnaireSliceOfState.everAbdominalPain === CurrentPreviousNeverCodeEnum.PREVIOUS)
}

export function hasOrHadAbdominalPain3PlusTimesPerWeek(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return hasOrHadAbdominalPain(questionnaireSliceOfState) && questionnaireSliceOfState.abdominalPainHowOften === UpTo3PlusTimesPerWeekCodeEnum.UP_TO_THREEPLUS_TIMES_WEEKLY
}

export function hasOrHadAbdominalPainOneTo3PlusTimesPerWeek(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return hasOrHadAbdominalPain(questionnaireSliceOfState) && (questionnaireSliceOfState.abdominalPainHowOften === UpTo3PlusTimesPerWeekCodeEnum.UP_TO_THREEPLUS_TIMES_WEEKLY || questionnaireSliceOfState.abdominalPainHowOften === UpTo3PlusTimesPerWeekCodeEnum.WEEKLY)
}

export function hasAtLeastOneToThreeTimesPerWeek(code: string) {
  if (code === UpTo3PlusTimesPerWeekCodeEnum.WEEKLY || code === UpTo3PlusTimesPerWeekCodeEnum.UP_TO_THREEPLUS_TIMES_WEEKLY) {
    return true
  }
  return false
}

export function hasConstipationOrHasDiarrhea(questionnaireSliceOfState: QuestionnaireSliceOfState) {
  return isTrueLike(questionnaireSliceOfState.hasConstipation) || isTrueLike(questionnaireSliceOfState.hasDiarrhea)
}

export function hasDiarrhea(questionnaireSliceOfState: QuestionnaireSliceOfState) {
  return isTrueLike(questionnaireSliceOfState.hasDiarrhea)
}

export function hasConstipationOrHasDiarrheaOrHasSteatorrhea(questionnaireSliceOfState: QuestionnaireSliceOfState) {
  return isTrueLike(questionnaireSliceOfState.hasConstipation) || isTrueLike(questionnaireSliceOfState.hasDiarrhea) || isTrueLike(questionnaireSliceOfState.hasSteatorrhea) 
}

export function hasDiarrheaOrHasSteatorrhea(questionnaireSliceOfState: QuestionnaireSliceOfState) {
  return isTrueLike(questionnaireSliceOfState.hasDiarrhea) || isTrueLike(questionnaireSliceOfState.hasSteatorrhea) 
}

export function hasConstipation(questionnaireSliceOfState: QuestionnaireSliceOfState) {
  return isTrueLike(questionnaireSliceOfState.hasConstipation)
}

export function hasSteatorrhea(questionnaireSliceOfState: QuestionnaireSliceOfState) {
  return isTrueLike(questionnaireSliceOfState.hasSteatorrhea)
}

export function hasNarrowingStoolLast6Months(questionnaireSliceOfState: QuestionnaireSliceOfState) {
  return isTrueLike(questionnaireSliceOfState.hasNarrowingStoolLast6Months)
}


export function hasBowelMovementFrequencyChangedLast6Months(questionnaireSliceOfState: QuestionnaireSliceOfState) {
  return isTrueLike(questionnaireSliceOfState.hasBowelMovementFrequencyChangedLast6Months) 
}

export function is108Current(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return everYesCurrently(questionnaireSliceOfState.everHorseness) 

}

export function is121No(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isHasNotTrue(questionnaireSliceOfState.hasGiDoctorConversation)
}


export function is40Yes_hasDifficultyOrPainSwallowing(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isTrueLike(questionnaireSliceOfState.hasDifficultyOrPainSwallowing) 
}

export function is111Yes_hasEsophogealCancer(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isTrueLike(questionnaireSliceOfState.hasEsophogealCancer) 
}

// export function is112Yes(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
//   // TODO: What the?!
//   return false
// }

export function isYesTo6b_68_105_107_49_or_108(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return is6Current(questionnaireSliceOfState)  // TODO: Does this mean yes to 6b?
  || is68Current(questionnaireSliceOfState)     // TODO: Does this mean yes to 68?
  || is105Current(questionnaireSliceOfState)   
  || is107Current(questionnaireSliceOfState)
  || is49Current(questionnaireSliceOfState)
  || is108Current(questionnaireSliceOfState)
}

// TODO: is this question #119?
export function is119Yes(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isTrueLike(questionnaireSliceOfState.hasEarlyFullnessDuringMeal) 
}


export function is119a3MonthsOrMore(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return is119Yes(questionnaireSliceOfState) && (questionnaireSliceOfState.earlyFullnessSymptomsHowLong === OneToSixPlusMonthsCodeEnum.THREE_MONTHS ||
    questionnaireSliceOfState.earlyFullnessSymptomsHowLong === OneToSixPlusMonthsCodeEnum.SIX_PLUS_MONTHS) 
}

export function is120Yes(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isTrueLike(questionnaireSliceOfState.hasEpigastricPainOrBurning) 
}

export function is120a3MonthsOrMore(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return is120Yes(questionnaireSliceOfState) && (questionnaireSliceOfState.epigastricPainOrBurningHowLong === OneToSixPlusMonthsCodeEnum.THREE_MONTHS ||
    questionnaireSliceOfState.epigastricPainOrBurningHowLong === OneToSixPlusMonthsCodeEnum.SIX_PLUS_MONTHS) 
}

export function is105Current(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return everYesCurrently(questionnaireSliceOfState.everRegurgitation)
}

export function is49Current(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return everYesCurrently(questionnaireSliceOfState.everFrequentCoughing)
}
export function is107Current(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return everYesCurrently(questionnaireSliceOfState.everSourOrBitterTaste)
}

export function is14Current(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return everYesCurrently(questionnaireSliceOfState.everUnexpectedWeightLoss10In3)
}

export function is68Current(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return everYesCurrently(questionnaireSliceOfState.everRapidOrPoundingHeartbeat)
}

export function is95Yes(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  // TODO:   FIXME:  Ileocecectomy History
  return isTrueLike(questionnaireSliceOfState.hasIleocectomyHistory) // or hasIleocectomyValveSomething
}

export function is114Yes_hasHiatalHernia(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isTrueLike(questionnaireSliceOfState.hasHiatalHernia)
}

export function isGreasyStool(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  // TODO:   FIXME
  return false
}

export function is19Yes(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isHasTrue(questionnaireSliceOfState.hasSymptomsWorsenWithGlutenIngestion)
}

export function haveBeenExperiencingAbdominalPainForAtLeast3Months(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return questionnaireSliceOfState.abdominalPainHowLong === OneToSixPlusMonthsCodeEnum.THREE_MONTHS || questionnaireSliceOfState.abdominalPainHowLong === OneToSixPlusMonthsCodeEnum.SIX_PLUS_MONTHS
}
// export function currentStoolTypeNumber(questionnaireSliceOfState: QuestionnaireSliceOfState): number {
//   return questionnaireSliceOfState.currentStoolTypeNumber
// }

// is19Yes(questionnaireSliceOfState), //19="Yes"
// is32Current(questionnaireSliceOfState), //32="Current"
// is115Current(questionnaireSliceOfState), //115="Current"
// is116CurrentOrPast(questionnaireSliceOfState), //116="Current" or 116="Past"
// is39Yes_hasHistoryOfAnemia(questionnaireSliceOfState), //39="Yes"
// is117Yes(questionnaireSliceOfState), //117="Yes"
// is82Yes(questionnaireSliceOfState), //82="Yes"
// is118Yes(questionnaireSliceOfState), //118="Yes"

export function is32Current(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return questionnaireSliceOfState.everHivesRashes === CurrentPreviousNeverCodeEnum.CURRENT
}

export function is115Current(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return questionnaireSliceOfState.everTonguePain === CurrentPreviousNeverCodeEnum.CURRENT
}

export function is116CurrentOrPast(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return (questionnaireSliceOfState.everBoneFractureOrOsteoporosis === CurrentPreviousNeverCodeEnum.CURRENT) || (questionnaireSliceOfState.everBoneFractureOrOsteoporosis === CurrentPreviousNeverCodeEnum.PREVIOUS)
}

export function is39Yes_hasHistoryOfAnemia(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isHasTrue(questionnaireSliceOfState.hasHistoryOfAnemia)
}

export function is117Yes(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isHasTrue(questionnaireSliceOfState.hasFamilyHistoryOfCeliac)
}

export function is82Yes(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isHasTrue(questionnaireSliceOfState.hasType1Diabetes)
}

export function is118Yes(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isHasTrue(questionnaireSliceOfState.hasThyroidDisease)
}

export function is13cYes(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return hasConstipationOrHasDiarrheaOrHasSteatorrhea(questionnaireSliceOfState) && isTrueLike(questionnaireSliceOfState.hasBowelMovementFrequencyChangedLast6Months)
}

export function is13eYes_narrowingStoolsLast6Months(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return is13cYes(questionnaireSliceOfState) && isTrueLike(questionnaireSliceOfState.hasNarrowingStoolLast6Months)
}

export function is13hYes_hasConstipationAndHasStrainingBowelMovement(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isTrueLike(questionnaireSliceOfState.hasConstipation) && isTrueLike(questionnaireSliceOfState.hasStrainingBowelMovement)
}

export function is13iYes(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isTrueLike(questionnaireSliceOfState.hasConstipation) && isTrueLike(questionnaireSliceOfState.hasHardOrLumpyStools)
}

export function is13jYes(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isTrueLike(questionnaireSliceOfState.hasConstipation) && isTrueLike(questionnaireSliceOfState.hasSensationIncompleteBowelMovement)
}

export function is13kYes(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isTrueLike(questionnaireSliceOfState.hasConstipation) && isTrueLike(questionnaireSliceOfState.hasSensationOfAnorectalBlockageOrObstruction)
}

export function is13lYes(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isTrueLike(questionnaireSliceOfState.hasConstipation) && isTrueLike(questionnaireSliceOfState.hasFewerThan3SpontaneousBowelMovementsWeekly)
}

// export function doYouEverExperienceLooseStools(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
//   // TODO: Loose Stools is an FSR question so all answers are true
//   return false
// }

export function isFsrRarely( fsrCode: FrequentlySometimesRarelyCodeEnum | string) {
  return fsrCode === FrequentlySometimesRarelyCodeEnum.RARELY
}

export function is13mRarely(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isTrueLike(questionnaireSliceOfState.hasConstipation) && isFsrRarely(questionnaireSliceOfState.fsrLooseStools)
}



export function is6CurrentOrPrevious_abdominalPain(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return everCurrentOrPreviously(questionnaireSliceOfState.everAbdominalPain)
}

export function is6a_abdominalPain_oneDayPerWeekOrOneDayPerMonth(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return is6CurrentOrPrevious_abdominalPain(questionnaireSliceOfState) && (questionnaireSliceOfState.abdominalPainHowOften === UpTo3PlusTimesPerWeekCodeEnum.MONTHLY || questionnaireSliceOfState.abdominalPainHowOften === UpTo3PlusTimesPerWeekCodeEnum.WEEKLY)
}

export function is6a_abdominalPain_moreThanThreeTimesPerWeek(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return is6CurrentOrPrevious_abdominalPain(questionnaireSliceOfState) && (questionnaireSliceOfState.abdominalPainHowOften === UpTo3PlusTimesPerWeekCodeEnum.UP_TO_THREEPLUS_TIMES_WEEKLY)
}

export function is13nYes(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return isHasTrue(questionnaireSliceOfState.hasConstipation) && isHasTrue(questionnaireSliceOfState.hasManeuversToAchieveBowelMovement)
}

export function is13o__bowelTrouble__moreThanThreeTimesPerWeek(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  if (hasConstipationOrHasDiarrheaOrHasSteatorrhea(questionnaireSliceOfState)
      && questionnaireSliceOfState.bowelTroubleHowOften === UpTo3PlusTimesPerWeekCodeEnum.UP_TO_THREEPLUS_TIMES_WEEKLY) {
    return true;
  }
  return false;
}

export function is6Current(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return everYesCurrently(questionnaireSliceOfState.everAbdominalPain)
}

export function is7Current(questionnaireSliceOfState: QuestionnaireSliceOfState): boolean {
  return questionnaireSliceOfState.everBloating === CurrentPreviousNeverCodeEnum.CURRENT
}