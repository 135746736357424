export function isBlank( input: string ): boolean {
  if (input == null) {
    return true
  }
  if (input.trim().length === 0) {
    return true
  }
  return false
}

export function isPresent( input: string ): boolean {
  return !isBlank(input)
}

export function isEmpty( input: string ): boolean {
  if (input == null) {
    return true
  }
  if (input.length === 0) {
    return true
  }
  return false
}