/**
 * Dependency Injection
 */

import 'reflect-metadata'
import { Container } from 'inversify'
import DI_TYPES from './diTypes'
import { ExistingDiagnosticAnswerFetcher } from './existingDiagnosticAnswerFetcher/ExistingDiagnosticAnswerFetcher'
import { ExistingDiagnosticAnswerFetcherFakeImpl } from './existingDiagnosticAnswerFetcher/ExistingDiagnosticAnswerFetcherFakeImpl'
import { CurrentDiagnosticAnswerSaver } from './currentDiagnosticAnswerSaver/CurrentDiagnosticAnswerSaver'
// import { CurrentDiagnosticAnswerSaverFakeImpl } from './currentDiagnosticAnswerSaver/CurrentDiagnosticAnswerSaverFakeImpl'
import { CurrentDiagnosticAnswerSaverRealImpl } from './currentDiagnosticAnswerSaver/CurrentDiagnosticAnswerSaverRealImpl'
import { CurrentDiagnosticAnswerSaverFakeImpl } from './currentDiagnosticAnswerSaver/CurrentDiagnosticAnswerSaverFakeImpl'
import { WhoAmISvc } from './auth/WhoAmISvc'
import { WhoAmISvcImpl } from './auth/WhoAmISvcImpl'
import { SignInSvc } from './auth/SignInSvc'
import { SignInSvcImpl } from './auth/SignInSvcImpl'
import { ExistingDiagnosticAnswerFetcherRealImpl } from './existingDiagnosticAnswerFetcher/ExistingDiagnosticAnswerFetcherRealImpl'
import { SbNotificationPublisher } from './notification/SbNotificationPublisher';
import { SbNotificationPubSubImpl } from './notification/SbNotificationPubSubImpl';
import { ToastNotificationSubscriber } from './notification/ToastNotificationSubscriber';
import { SbNotificationMessageGenerator } from './commonSrc/notification/SbNotificationMessageGenerator';
import { SbNotificationMessageGeneratorImpl } from './notification/SbNotificationMessageGeneratorImpl';
import { InitialHypothesisFetcher } from './hypothesis/InitialHypothesisFetcher'
import { InitialHypothesisFetcherImpl } from './hypothesis/InitialHypothesisFetcherImpl'
import { HypothesisDecorationSvcImpl } from './hypothesis/HypothesisDecorationSvcImpl'
import { HypothesisDecorationSvc } from './hypothesis/HypothesisDecorationSvc'
import { UserAttributeFetchingServiceImpl } from './user/UserAttributeFetchingServiceImpl'
import { UserAttributeFetchingService } from './user/UserAttributeFetchingService'
import { NextPageFlowFinderService } from './NextPageFlowFinderService'
import { NextPageFlowFinderServiceImpl } from './NextPageFlowFinderServiceImpl'
import { WhoAmIWriteService } from './auth/WhoAmIWriteService'


const container = new Container()
console.log("Creating a new DI Container...")
// Use the brute-force impl for the mvp
// container.bind<ExistingDiagnosticAnswerFetcher>(DI_TYPES.ExistingDiagnosticAnswerFetcher).to(ExistingDiagnosticAnswerFetcherFakeImpl).inSingletonScope()
container.bind<ExistingDiagnosticAnswerFetcher>(DI_TYPES.ExistingDiagnosticAnswerFetcher).to(ExistingDiagnosticAnswerFetcherRealImpl).inSingletonScope()

// vs
// container.bind<NextDirectionGuesserFactory>(DI_TYPES.NextDirectionGuesserFactory).to(NextDirectionGuesserFactorySlopeOfVectorImpl).inSingletonScope();

// container.bind<CurrentDiagnosticAnswerSaver>(DI_TYPES.CurrentDiagnosticAnswerSaver).to(CurrentDiagnosticAnswerSaverFakeImpl).inSingletonScope()
container.bind<CurrentDiagnosticAnswerSaver>(DI_TYPES.CurrentDiagnosticAnswerSaver).to(CurrentDiagnosticAnswerSaverRealImpl).inSingletonScope() 
container.bind<WhoAmISvc>(DI_TYPES.WhoAmISvc).to(WhoAmISvcImpl).inSingletonScope()
const alsoWhoAmIWriteService: WhoAmIWriteService = container.get<any>(DI_TYPES.WhoAmISvc)
container.bind<WhoAmIWriteService>(DI_TYPES.WhoAmIWriteService).toConstantValue(alsoWhoAmIWriteService)

container.bind<SignInSvc>(DI_TYPES.SignInSvc).to(SignInSvcImpl).inSingletonScope() 

container.bind<SbNotificationMessageGenerator>(DI_TYPES.SbNotificationMessageGenerator).to(SbNotificationMessageGeneratorImpl).inSingletonScope() 

container.bind<SbNotificationPublisher>(DI_TYPES.SbNotificationPublisher).to(SbNotificationPubSubImpl).inSingletonScope()
const alsoToastNotificationSubscriber = container.get<any>(DI_TYPES.SbNotificationPublisher)
container.bind<ToastNotificationSubscriber>(DI_TYPES.ToastNotificationSubscriber).toConstantValue(alsoToastNotificationSubscriber)
// container.bind<ToastNotificationSubscriber>(DI_TYPES.ToastNotificationSubscriber).to(SbNotificationPubSubImpl).inSingletonScope()

container.bind<InitialHypothesisFetcher>(DI_TYPES.InitialHypothesisFetcher).to(InitialHypothesisFetcherImpl).inSingletonScope()
container.bind<HypothesisDecorationSvc>(DI_TYPES.HypothesisDecorationSvc).to(HypothesisDecorationSvcImpl).inSingletonScope()

container.bind<UserAttributeFetchingService>(DI_TYPES.UserAttributeFetchingService).to(UserAttributeFetchingServiceImpl).inSingletonScope()
container.bind<NextPageFlowFinderService>(DI_TYPES.NextPageFlowFinderService).to(NextPageFlowFinderServiceImpl).inSingletonScope()

export default container
 