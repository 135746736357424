export enum LabRequestCodeEnum { 
  UNSET = "Choose", 

  // These have IGA in the name
  TTG_IGA = "ttg-iga", 
  // TOTAL_IGA_LEVEL_POSITIVE = "total-iga-level-positive",
  IGA = "iga",
  ANTI_WHEAT_IGA = "anti-wheat-iga" ,

  // These have IGG in the name
  TTG_IGG = "ttg-igg", 
  // TTG_IGG_CELIAC_TESTING = "ttg-igg-celiac-testing",
  UPPER_ENDOSCOPY_IF_TTG_IGG_IS_POSITIVE = "upper-endoscopy-if-tgg-igg-is-positive",

  // Is Celiac Sprue testing a dup of something else?
  CELIAC_SPRUE_TESTING = "celiac-sprue-testing",

  FECAL_FAT = 'fecal-fat',
  ELEVATED_PT_INR = "elevated-pt-inr",
  FECAL_CALPROTEIN = "fecal-calprotectin",
  C_REACTIVE_PROTEIN = "c-reactive-protein",
  STOOL_TESTING_FOR_CULTURE = "stool-testing-for-culture",
  STOOL_O_AND_P = "stool-o-and-p",
  STOOL_C_DIFF = "stool-c-diff",
  LACTULOSE_BREATH_TEST="lactulose-breath-test",
}  

export enum LabRequestLabelEnum { 
  UNSET = "Choose", 
  TTG_IGA = "TTG IgA", 
  TOTAL_IGA_LEVEL_POSITIVE = "total IgA Level: positive",
  ANTI_WHEAT_IGA = "anti-wheat IgA" ,
  FECAL_FAT = 'Fecal fat',
  ELEVATED_PT_INR = "Elevated PT-INR",
  FECAL_CALPROTEIN = "Fecal calprotectin",
  C_REACTIVE_PROTEIN = "C-reactive protein",
  IGA = "IgA",
  CELIAC_SPRUE_TESTING = "Celiac sprue testing",
  TTG_IGG = "Serum TTG IgG", 
  TTG_IGG_CELIAC_TESTING = "TTG IgG (celiac testing)",
  STOOL_TESTING_FOR_CULTURE = "Stool testing for culture",
  STOOL_O_AND_P = "Stool O& P",
  STOOL_C_DIFF = "Stool C. diff",
  LACTULOSE_BREATH_TEST="Lactulose breath test",
  UPPER_ENDOSCOPY_IF_TTG_IGG_IS_POSITIVE = "Upper endoscopy (if TTG IgG is positive)"
}  