import * as cookieUtil from '../commonSrc/cookie/cookieUtil';
import { useState } from 'react';
import { getLogger } from '../log/getLogger'
const LOG = getLogger('useDeveloperMode')



/**
 * Custom Hook
 * @returns
 */
export const useDeveloperMode = () => {
  const getter = () => {

    const devModeCookieValue = cookieUtil.getCookie("developerMode")
    if ((devModeCookieValue as any) === true || devModeCookieValue === 'true') {
      LOG.debug(`useDeveloperMode::getter() - Returning TRUE`)
      return true;
    } 

    LOG.debug(`useDeveloperMode::getter() - Returning FALSE`)
    return false;
  }
  const [developerMode, setDeveloperMode] = useState(getter());

  const updateFn = (value: boolean) => {
    setDeveloperMode(value);
    if (value === true) {
      document.cookie = `developerMode=true; expires=Thu, 18 Dec 2030 12:00:00 UTC; path=/; SameSite=Lax`;
    } else {
      // delete cookie with old date
      document.cookie = "developerMode=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax";
    }
  };

  // https://stackoverflow.com/questions/65680316/this-expression-is-not-callable-not-all-constituents-of-type-string-search
  return [developerMode, updateFn] as const;
};