export function PaidSearchLandingPageComponent () {
  // useEffect(() => {
  //   window.location.href = "/questionnaire" as string  
  // }, []);

  return (
    <>
      <p>
        You followed a Google Ad to get here.
      </p>
      <p>
        Here we will attempt a conversion.
      </p>

      <p>
        We are glad you are here.   This is the paid search landing page (NOT the default landing page) for the trial
      </p>

      <p>
        First... you have to create an account.   Then fill out there questionaire. Next...
      </p>

      <div className="mt-2">
        <button className="btn btn-primary btn-monique">
          Let's Get Started
        </button>
      </div>
    </>
  )
}