import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectUsername } from './authenticationSlice'

export default function SignIn () {
  const currentAuthenticatedUsername: string = useSelector(selectUsername)

  return (
    <>
      <form>
        <label>
          <p>Username</p>
          <input type='text' />
        </label>
        <label>
          <p>Password</p>
          <input type='password' />
        </label>
        <div>
          <button type='submit'>Submit</button>
        </div>
      </form>

      <div>Fake username = {currentAuthenticatedUsername}</div>
    </>
  )
}
