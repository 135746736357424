export function tryToNumber(input: string | boolean | number | undefined): number | undefined | null {
  if ( input == null ) { return null; }
  if ( typeof input === 'number' ) {
    return input as number
  }
  if ( typeof input === 'string' ) {
    if (input === '') {
      return undefined;
    }
    if (!isNaN(input as unknown as number)) {
      return Number(input as unknown as string);
    }
  }

  return undefined
}