import { HypothesisDecorationSvc } from "./HypothesisDecorationSvc";
import { HypothesisCodeEnum } from '../commonSrc/hypothesis/HypothesisCodeEnum';
import { injectable } from "inversify";
import { LabRequestCodeEnum, LabRequestLabelEnum } from '../commonSrc/lab/LabRequestCodeEnum';

@injectable()
export class HypothesisDecorationSvcImpl implements HypothesisDecorationSvc {
  public getNameForHypothesisCode(code: HypothesisCodeEnum): string {
    switch(code) {
      case HypothesisCodeEnum.GLUTEN_SENSITIVITY: {
        return "Gluten Sensitivity"
      }
      case HypothesisCodeEnum.BILE_SALT_MALABSORPTION: {
        return "Bile Salt Malabsorption"
      }
      case HypothesisCodeEnum.CONSTIPATION: {
        return "Constipation"
      }
      case HypothesisCodeEnum.LACTOSE_FRUCTOSE_SUCROSE_MALABSORPTION: {
        return "Lactose, Fructose, Sucrose Malabsorption"
      }
      case HypothesisCodeEnum.BLOATING: {
        return "Bloating"
      }
      case HypothesisCodeEnum.GERD: {
        return "GERD"
      }
      case HypothesisCodeEnum.IBS_D: {
        return "IBS-D"
      }
      case HypothesisCodeEnum.IBS_C: {
        return "IBS-C"
      }
      case HypothesisCodeEnum.IBS_M: {
        return "IBS-M"
      }
      case HypothesisCodeEnum.SIBO: {
        return "SIBO"
      }
      case HypothesisCodeEnum.CELIAC: {
        return "Celiac Disease"
      }
      case HypothesisCodeEnum.FUNCTIONAL_DYSPEPSIA: {
        return "Functional Dyspepsia"
      }
      default: {
        return code
      }
    }
  }

  /*
   *   
  SERUM_TTG_IGG = "Serum TTG IgG", 
  TTG_IGA = "TTG IgA", 
  TOTAL_IGA_LEVEL_POSITIVE = "total IgA Level: positive",
  ANTI_WHEAT_IGA = "anti-wheat IgA" ,
  FECAL = 'fecal',
  ELEVATED_PT_INR = "elevated PT-INR",
  CELIAC_SPRUE_TESTING = "Celiac sprue testing",
  FECAL_CALPROTEIN = "Fecal calprotectin",
  C_REACTIVE_PROTEIN = "C-reactive protein",
  IGA = "IgA",
  TTG_IGG_CELIAC_TESTING = "TTG IgG (celiac testing)",
  STOOL_TESTING_FOR_CULTURE = "Stool testing for culture",
  STOOL_O_AND_P = "Stool O& P",
  STOOL_C_DIFF = "Stool C. diff",
  LACTULOSE_BREATH_TEST="Lactulose breath test",
  UPPER_ENDOSCOPY_IF_TTG_IGG_IS_POSITIVE = "Upper endoscopy (if TTG IgG is positive)"
  */


  private getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : '';
  }

  public getNameForLabCode(code: LabRequestCodeEnum): string {
    // console.log(`getNameForLabCode(): Entering with code = ${code}`)
    const enumKey: string = this.getEnumKeyByEnumValue(LabRequestCodeEnum, code)
    // console.log(`getNameForLabCode(): Entering with enumKey = ${enumKey}`)
    const retval = (LabRequestLabelEnum as any)[enumKey]
    // console.log(`getNameForLabCode(): Returning ${retval}`)

    return retval;
  }

}