// CREATE TYPE up_to_3plus_times_per_week AS ENUM ('weekly', 'monthly', '3plus_times_per_week');

export enum OneToThreeXDailyMoreOrLessCodeEnum { 
  UNSET = "unset", 
  LESS_THAN_ONCE_DAILY = "less than once daily", 
  ONE_TO_THREE_X_DAILY = "1-3x daily", 
  MORE_THAN_THREE_X_DAILY = "more than 3x daily", 
}  

export enum OneToThreeXDailyMoreOrLessLabelEnum { 
  UNSET = "Choose", 
  LESS_THAN_ONCE_DAILY = "Less than once daily", 
  ONE_TO_THREE_X_DAILY = "1 - 3 times daily", 
  MORE_THAN_THREE_X_DAILY = "More than 3 times daily", 
}  