import safeStringify from '../string/safeStringify'
import React, { useState, useReducer, useEffect } from 'react'
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import { getLogger } from '../log/getLogger'
import { StateScalarAttribute } from '../commonSrc/questionnaire/allQuestionnaireAttributes';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { setRawString, selectRawString } from '../questionnaire/questionnaireSlice';
import { YesOrNoCodeEnum, YesOrNoLabelEnum } from '../commonSrc/constant/yesOrNoConstant';
import { handleTooltipClick } from '../tooltip/tooltipUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

const log = getLogger('question.FreeFormTextQuestionComponent')

export default function FreeFormTextQuestionComponent (props: any) {
  const label: string = props.label  // Nausea
  const attrName: string = props.attrName // everNausea
  const tooltipText: string = props.tooltipText
  const onDiagnosticAnswersChanged = props.onDiagnosticAnswersChanged
  log.debug(`props = ${JSON.stringify(props)}`)
  log.debug(`label = ${label}`)

  const dispatch = useDispatch()




  const onChange = async (event: React.ChangeEvent<HTMLInputElement> | any): Promise<void> => {
    log.debug(`onChange() - Entering with event = ${safeStringify(event)}`)
    dispatch(setRawString({eventValue: event.target.value, attrName: attrName}))
    onDiagnosticAnswersChanged()
  }

  const currentValue: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => {
    return selectRawString(attrName, state)
  })

  const onClickTooltip = function(event: any): void {
    handleTooltipClick(event, attrName)
  }

  return (
    <>
      <div className="free-form-text-question-component">
        <fieldset>
          <label>
            {label}
            {tooltipText != null && tooltipText.length > 0 && <>&nbsp;<a onClick={onClickTooltip}><FontAwesomeIcon className="tooltip-click-me-icon" icon={faQuestionCircle}/></a></>}
          </label>
          <div id={attrName.toString() + "-tip"} className="tooltip-content" style={{display: 'none'}}>
            {tooltipText}
          </div>
          <div className="no-idea" >
            <input className="w-100" type='text' name={attrName} value={currentValue} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event)}/>
          </div>
        </fieldset> 
      </div>
    </>
  )
}