import { getLogger } from '../log/getLogger'
import React, { useEffect, useState } from "react";
import { WhoAmISvc } from '../auth/WhoAmISvc';
import diContainer from '../inversify.config'
import DI_TYPES from "../diTypes";
import * as bearerUtil from '../commonSrc/auth/bearerUtil'
import { setLegalName, setSignature, selectSignature, selectUserSpecifiedSignatureDate, setUserSpecifiedSignatureDate, selectLegalName } from '../user/userAttributesSlice'
import safeStringify from '../string/safeStringify';
import { useDispatch, useSelector } from 'react-redux';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { useNavigate } from "react-router";
import { d4l, stringUtil } from '@jgithub/ts-gist-pile'

const LOG = getLogger('agreement.AgreementComponent')




export default function AgreementComponent () {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    // tryContactingCognito()
    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
    const jwtToken = whoAmISvc.getMyAuthToken()
    console.log(`jwtToken = ${jwtToken}`)
    const parsedJwtIdToken: any | null = bearerUtil.parseJwt(jwtToken as string)
    console.log(`parsedJwtIdToken = ${JSON.stringify(parsedJwtIdToken, null, 2)}`)
  }, []);
  

  
  const currentSignatureInState: string = useSelector((state: SbhcCompositeState) => {
    return selectSignature(state)
  })

  const currentUserSpecifiedSignatureDate: string = useSelector((state: SbhcCompositeState) => {
    return selectUserSpecifiedSignatureDate(state)
  })

  const currentLegalNameInState: string = useSelector((state: SbhcCompositeState) => {
    return selectLegalName(state)
  })

  function agreeButtonEnabled(): boolean {
    // stringUtil.isPresent(phone) &&
    // stringUtil.isPresent(firstName) &&
    // stringUtil.isPresent(lastName)
    return stringUtil.isPresent(currentUserSpecifiedSignatureDate) && stringUtil.isPresent(currentSignatureInState) && stringUtil.isPresent(currentLegalNameInState)
  }

  const handlehandleIAgreeThenNext = async () => {
    await handleIAgree()
    navigate(`/`, { replace: true });
  }

  const handleIAgree = async () => {
    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
    const jwtToken = whoAmISvc.getMyAuthToken()
    const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
    const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
    const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

    const headers: any = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    }

    if (whoAmISvc.amILoggedIn()) {
      LOG.debug(`handleIAgree(): User is signed in.  jwtToken = ${whoAmISvc.getMyAuthToken()}`)
      headers["Authorization"] = `Bearer ${whoAmISvc.defGetMyAuthToken()}`
    } else {
      LOG.warn(`handleIAgree(): Unauthorized 401.  User is NOT signed in.`)
    }

    LOG.debug(`handleIAgree(): currentSignature = ${currentSignatureInState}`)

    const requestBodyAsString = JSON.stringify({
      signature: currentSignatureInState,
      legalName: currentLegalNameInState,
      userSpecifiedSignatureDate: currentUserSpecifiedSignatureDate
    })

    const requestOptions = {
      method: 'POST',
      // mode: 'cors',
      headers: headers,
      body: requestBodyAsString
    };

    const url = (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:9000') + `${process.env.REACT_APP_BACKEND_RELATIVE_API_PATH}` + '/userAttributes'

    LOG.debug(`handleIAgree(): Sending POST request to url = ${d4l(url)},  with body = ${requestBodyAsString}`)


    return fetch(url, requestOptions).then(response => response.json())
  }


  return (
    <>
      <header className="page-header">
        <div className="inner">
          <img src="Basic-Info.svg" alt="" />
          <div className="text-block">
            <h1>Informed Consent Form Electronic Signature</h1>
            {/* <p>There is a little bit of paperwork we need to get you enrolled in the study.</p> */}
          </div>
        </div>
      </header>

      <section className="content-block-container">
        <div className="questionnaire-content">
          <div className="card">
            {/* <header>
              <h2>Agreement</h2>
            </header> */}
            <div className="inner">
              <p>By signing this consent form, you are confirming your age to be 18-55 years old, that you have read and understood the study information, that you understand you will not give up any of your legal rights by signing this consent document, and that you voluntarily agree to participate in this research study.  You will receive a signed and dated copy of this consent document.</p>
              <div className="field">
                <label htmlFor="fullname">Full Name</label>
                <input className="form-control input-legalName" id="legalName" value={currentLegalNameInState} onInput={(evt: React.FormEvent<HTMLInputElement>) => dispatch(setLegalName((evt.target as HTMLInputElement).value as any))}/>
              </div>               
              <div className="field">
                <label htmlFor="signature">Your Digital Signature</label>
                <input className="form-control input-signature" id="signature" value={currentSignatureInState} onInput={(evt: React.FormEvent<HTMLInputElement>) => dispatch(setSignature((evt.target as HTMLInputElement).value as any))}/>
              </div> 
              <div className="field">
                <label htmlFor="userSpecifiedSignatureDate">Date (MM/DD/YYYY)</label>
                <input className="form-control input-signatureData" id="userSpecifiedSignatureDate" value={currentUserSpecifiedSignatureDate} onInput={(evt: React.FormEvent<HTMLInputElement>) => dispatch(setUserSpecifiedSignatureDate((evt.target as HTMLInputElement).value as any))}/>
              </div>
            </div>
          </div>
          <div className="mt-4 button-block">
            <button disabled={!agreeButtonEnabled()} className="btn btn-primary btn-monique" onClick={handlehandleIAgreeThenNext}>
              I agree
            </button>
          </div>
        </div>
      </section>
    </>
  )
}