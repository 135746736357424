import { getLogger } from '../log/getLogger'
import React, { useEffect, useState } from "react";
import { WhoAmISvc } from '../auth/WhoAmISvc';
import diContainer from '../inversify.config'
import DI_TYPES from "../diTypes";
import * as bearerUtil from '../commonSrc/auth/bearerUtil'
import { setMedication, selectMedication } from '../user/userAttributesSlice'
import safeStringify from '../string/safeStringify';
import { useDispatch, useSelector } from 'react-redux';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { current } from '@reduxjs/toolkit';

const LOG = getLogger('privacy.PrivacyPolicyComponent')

// https://stackoverflow.com/questions/33188994/scroll-to-the-top-of-the-page-after-render-in-react-js
export default function PrivacyPolicyComponent () {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="container-fluid text-section">
    <h1>Privacy Policy</h1>
    <p>Last Updated: 12/30/22<br/><br/>This Privacy Policy (this “Policy”) describes how Visana
  Health, Inc., or its subsidiaries, divisions, and affiliates (“Visana,” “we,”“us,” or “our”), collects, uses,
  communicates, discloses, and otherwise manages the personal information that you provide to us by engaging our
  services on our websites, email, text, orother electronic messages between you and any employee or agent of Visana
  Health, mobile apps, and other online services on which this Policy is posted &nbsp;(collectively the “Service”).
  Visana is deeply committed to protecting the privacy and security of our customers’ data, and to keeping your health
  information safe.&nbsp;<br/><br/>This Policy is written in the English language. We do not guarantee the accuracy of any
  translated versions of this Policy. To the extentthat any translated versions of this Policy conflict with the English
  language version, the English language version of this Policy shall control.&nbsp;<br/><br/>As used in this Privacy
  Policy, the terms “using” and “processing” information may include, amongst other things, subjecting such information
  to analysis, using cookies, and managing information in a number of ways, including but not limited to: collection,
  storing, evaluating, modifying,deleting, using, combining, and/or disclosing. Some of the information we collect may
  constitute protected health information (“PHI”) under the HealthInsurance Portability and Accountability Act of 1996
  (“HIPAA”), as amended by the Health Information Technology for Economic and Clinical Health Act of 2009(“HITECH”). For
  more information about your rights under HIPAA, please refer to Second Brain Healthcare's <a
    href="https://www.visanahealth.com/policies/hipaa">HIPAA Notice and Consent to Share and Release
    Information.</a>&nbsp;<br/><br/>‍<strong>Children and Individuals Under the Age of Eighteen&nbsp;<br/><br/>‍</strong>The
  Service is not meant for individuals under the age of eighteen, and the Service may use mechanisms to prevent the
  registration and use of the Service by such underage individuals. Visana will never knowingly request or collect
  personal information from any child. If you are under eighteen years old, please do not provide your information on
  our Service. Upon notification that a child has provided us with personally identifiable information, we will delete
  the child’s personally identifiable information from our records. If you believe we might have any information from a
  child, please contact us.&nbsp;<br/>‍<br/>‍<strong>Collection of Personal Information
    &nbsp;&nbsp;<br/><br/>‍</strong><em>Information you provide to us<br/><br/>&nbsp;</em>Visana will collect personal
  information from you directly when you interact with our Service, contact us, use our services, create anaccount,
  subscribe, share reviews, sign up to receive offers or emails, text messages, or other electronic messages from us, or
  complete surveys.&nbsp;<br/><br/>‍<em>Information we obtain indirectly&nbsp;<br/><br/>‍</em>We may receive personal
  information about you from our third-party affiliates or partners and from companies that provide us with
  suchinformation as a part of their relationship with us.&nbsp;We may combine this with data that we already have
  collected about you.&nbsp;<br/><br/>‍<em>Information collected automatically&nbsp;<br/><br/>‍</em>When you use our
  Service, we collect certain information about you automatically through our use of cookies and similar
  technologies.<br/><br/>&nbsp;<strong>Categories of Personal Information and Purpose
    forCollection&nbsp;<br/><br/>‍</strong>Visana collects the following categories of personal information: (1) contact
  information including your name, phone number, email address, street address, city, state, zip code; (2)
  authentication information, including the user name and password that you use to register an account on the Service;
  (3) financial information for payment processing purposes; (4)comments, reviews, suggestions; (5) personal
  characteristics including age, date of birth, gender, marital status, and nationality; (6) personal preferences
  including product preferences, online preferences, and interests;(7) online behavior information including online
  activity, preferences, andtime spent viewing features; and (8) IP address or mobile network information. If you use a
  mobile device to access the Service, Visana may also collect additional information from your mobile device, such as
  unique device identification numbers or geo-location information.&nbsp;<br/><br/>Visana may also collect certain
  sensitive personal information including your precise geolocation and personal informationcollected and analyzed
  concerning your health, such as symptoms, that youprovide in connection with use of our products or services. This
  information iscollected and used without the purpose of inferring characteristics about you.&nbsp;'<br/><br/>Beyond
  this, unless stated otherwise in a separate agreement, Visana does not collect sensitive personal information such as
  your social security, driver’s license, state identification card, or passport number; your account login, financial
  account, debit card, or credit card number in combination with any required security or access code, password, or
  credentials allowing access to an account; the contents of your mail, email and text messages, unless we are the
  intended recipient of the communication; your genetic data; and your biometric information for the purpose of uniquely
  identifying you. We ask that you not send nor disclose this sensitive personal information to us.&nbsp;Visana only
  collects and processes the minimum amount of personal information and sensitive personal information from you that is
  necessary to the purposes of our information processing activities and retains such information only if required to
  fulfill such purposes. <br/><br/>We may use information we collect about you to:·&nbsp;&nbsp;&nbsp;&nbsp;<br/>Administer
  your account·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/>Allow your Second Brain Healthcare Coach to personalize your
  experience;<br/>Provide you with access to particular tools and services;<br/>Respond to your inquiries and send you
  administrative communications;·&nbsp;&nbsp;&nbsp;<br/>Obtain your feedback on our websites and our
  offerings;&nbsp;<br/>Statisticallyanalyze user behavior and activity;&nbsp;<br/>Provide you with more relevant
  content;<br/>&nbsp;Conduct research and measurement activities;&nbsp;&nbsp;&nbsp;&nbsp;<br/>Send you personalized emails
  or secure electronic messages pertaining to your health information; or&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/>Contact you
  about the products and services that we offer.·&nbsp;&nbsp;&nbsp;&nbsp;<br/>Customer communications and
  support·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/>User verification and payment
  processing·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/>Service maintenance and improvements&nbsp;<br/><br/>Where applicable,
  if Visana intends to further process your personal information for a purpose other than that for which the personal
  information was initially collected, Visana shall, prior to such processing, provide you with any relevant information
  on such additional purpose, and, tothe extent required by applicable law, obtain your consent for
  this.<br/><br/>‍<strong>Disclosure of Personal Information&nbsp;<br/><br/>‍</strong>Visana does not trade, rent, or sell
  your personal information to third parties or share your personal information with third parties for cross-context
  behavioral advertising. We may share or disclose your personal information for the following limited
  purposes:&nbsp;<br/><br/>Visana may share aggregate, non-personal information about website or mobile application usage
  with unaffiliated third parties. This aggregate information does not contain any personal information about our users.
  &nbsp;&nbsp;<br/><br/>Visana may employ third party companies and individuals to facilitate or improve the Service, to
  provide the Service on its behalf, to process payment, provide customer support, to perform website-related services
  (e.g., without limitation, maintenance services, database management, web analytics, and improvement of the Service’s
  features), or to assist Visana in analyzing how the Service is used. These third parties have access to your personal
  information only to perform these tasks on Visana’s behalf and are obligated not to disclose or use it for any other
  purpose other than the purpose for which we disclose it to them.&nbsp;<br/><br/>Visana cooperates with government and
  law enforcement officials and private parties to enforce and comply with the law. Visana will disclose any information
  about you to government or law enforcement officials or private parties as Visana, in its sole discretion, believes
  necessary or appropriate to respond to claims and legal process (including but not limited to subpoenas), to protect
  the property and rights of Visana, a user of the Service, or a third party, to protect the safety of the public or any
  person, or to prevent or stop activity Visana considers to be or to have the potential to be, illegal, unethical, or
  legally actionable. In addition, Visana may disclose information about you to counter or prevent any malicious access
  or use of the Service or to prevent harm or loss to any user of the Service.&nbsp;<br/><br/>‍<strong>Do Not Track
    Signals, Cookies, and Other Technologies&nbsp;<br/>‍<br/>‍</strong>We may use cookies, web beacons, pixel tags, mobile
  analytics software, log files, or other technologies to collect certain information about your online activity and
  interactions with our emails, mobile applications, and to allow us to keep track of analytics and certain statistical
  information that enables Visana to improve our services and provide you with more relevant content. You may disallow
  receiving cookies at any timethrough your web browser, but some features of the Service, such as account logins, may
  not work properly or you may not be able to use the Service at all.Similarly, you may set your mobile device to refuse
  to provide certain information, but certain features of the Service may not work without such
  information.&nbsp;<br/><br/>It is not our intention to use cookies to retrieve information that is unrelated to our
  Service or your interaction with our Service.&nbsp;At this time, we do not respond to browser “do not track” or “DNT”
  signals.&nbsp;<br/><br/>‍<strong>Links and Third-Party Websites&nbsp;<br/>‍<br/>‍</strong>Our Service may contain links to
  other websites owned and operated by third parties. When you click on a link to any other website or location, you
  will leave our Service and go to another site, and another entity may collect personal data or anonymous data from
  you. We have no control over, do not review, and cannot be responsible for, these outside websites or their content.
  Please be aware that the terms of this Privacy Policy do not apply to these outside websites or content, or to any
  collection of your personal information by third parties through the linked sites. &nbsp;Visana is not responsible for
  the privacy practices or the content of such other websites. Visana expressly disclaims any liability that may arise
  from your use or provision of any of your information whatsoever to third-party websites. You understand and agree
  that by clicking on a link to another site or service that you have left our Service.&nbsp;<br/><br/>‍<strong>Opting Out
    of Certain Emails&nbsp;<br/>‍<br/>‍</strong>When you register, subscribe, create an account, sign up to receive offers
  or emails from us, or otherwise communicate with us through our Service, you may “opt out” of receiving future
  communications from us by indicating that you decline such communications. You may choose to stop receiving our
  newsletter or marketing emails by following the unsubscribe instructions included in these emails or contacting us at
  members@visanahealth.com. <br/><br/>&nbsp;<strong>Notification of Privacy Policy Changes</strong>&nbsp;<br/><br/>We may
  update this Policy to reflect changes to our information practices. If we make any material changes, we will notify
  you by email (sent to the email address specified in your account) or by means of a notice on the Service prior to the
  change becoming effective. Use of information we collect now is subject to the Policy in effect at the time such
  information is used. You are bound by any changes to our Policy when you use the Service after such changes have been
  first posted. We encourage you to periodically review this policy for the latest information on our privacy
  practices.<br/><br/>u&nbsp;<strong>Security&nbsp;</strong>If you choose to provide us with your personal information,
  you understand that we are transferring it to Visana’s locations and systems inthe United States. Visana uses
  reasonable security measures to protect theloss, misuse, and alteration of the information under its control. For
  example,we use encryption protocols for sensitive information, such as healthinformation. Such measures may change
  from time to time, depending on industrystandards and perceived security risks. However, despite such
  securitymeasures, Visana cannot guarantee the security of your information anddisclaims liability for loss, misuse,
  and alteration of your information underits control to the maximum extent permitted under applicable law. By using
  theService, you agree to assume any security risk related to personal information.You should report any security
  violations to us by sending an email to members@visanahealth.com.&nbsp;The security of your Visana account relies on
  yourprotection of your user profile information. You are responsible formaintaining the security of your user profile
  information, including yourpassword and for all activities that occur under your account. You may notshare your
  password with anyone. &nbsp;We willnever ask you to send your password or other sensitive information to us in
  anemail. Any email or other communication purporting to be from one of ourwebsites requesting your password or asking
  you to provide sensitive accountinformation via email, should be treated as unauthorized and suspicious andshould be
  reported to us immediately. If you believe someone else has obtainedaccess to your password, please change it
  immediately by logging in to youraccount and report it immediately by contacting us via email.&nbsp;<strong>Special
    Notice to CaliforniaResidents&nbsp;</strong>If you wouldlike to submit a Consumer Request, you can contact Visana at
  members@visanahealth.com. If you choose to submit a Consumer Request, youmust provide us with enough information to
  identify you and enough specificityon the requested data. Visana will only use the information it receives torespond
  to your request. Visana will not be able to disclose information if itcannot verify that the person making the
  Consumer Request is the person aboutwhom we collected information, or someone authorized to act on such
  person’sbehalf. &nbsp;“Personalinformation” means information that identifies, relates to, describes, iscapable of
  being associated with, or could reasonably be linked, directly orindirectly, with a particular consumer or household.
  “Personal information”does not include publicly available information. &nbsp;<strong>1.&nbsp;&nbsp;&nbsp;&nbsp;Request
    to Correct.</strong>You may correct or update your personal informationat any time by logging into your account or
  by contacting us.<br/><br/>&nbsp;<strong>2.&nbsp;&nbsp;&nbsp;&nbsp;Request to Access.</strong> You may submit a Consumer
  Request to obtain a copy of oraccess to the personal information that Visana has collected on you.
  &nbsp;<br/>‍<br/>3.&nbsp;&nbsp;&nbsp;&nbsp;<strong>Request to Know.</strong> You may submit a Consumer Request to
  receive informationabout Visana’s data collection practices.
  &nbsp;·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/><br/>‍<em>Right to Know what Personal Information is being
    Collected. </em>You may request information on the categories of personal information Visana has collected about
  you; the categories of sources from which the personal information is collected; Visana’s business or commercial
  purpose for collecting, selling, or sharing personal information; the categories of third parties to whom Visana
  discloses personal information, if any; and the specific pieces of personal information it has collected about you.
  &nbsp;·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/><br/><em>Right to Know what Personal Information is Sold or Shared
    and to Whom. </em>You may also request the categories of personal information that Visana has collected about you;
  the categories of personal information that Visana sold or shared about you and the categories of third parties to
  whom the personal information was sold or shared, by category or categories of personal information for each category
  of third parties to whom the personal information was sold or shared, and the categories of personal information that
  Visana disclosed about you for a business purpose and the categories of persons to whom it was disclosed for a
  business purpose.&nbsp;<br/><br/>Please note that the categories of personal information, sources, and disclosure will
  not exceed what is contained in this Policy. Additionally, Visana is not required to retain any information about you
  if it is only used for a one-time transaction and would not be maintained in the ordinary course of business. Visana
  is also not required to reidentify personal information if it is not stored in that manner already, nor is it required
  to provide the personal information to you more than twice in a twelve-month
  period.&nbsp;<br/><br/>‍<strong>4.&nbsp;&nbsp;&nbsp;&nbsp;Request to Delete.</strong> <br/><br/>You may request that
  Visana delete personal information it has collected from you. Subject to certain exceptions set out below we will, on
  receipt of a verifiable Consumer Request, delete your personal information from our records, direct any service
  providers to do the same, and notify all third parties to whom Visana has shared your personal information to delete
  it unless this proves impossible or involves disproportionate effort.&nbsp;<br/><br/>Please note that we may not delete
  your personal information if it is necessary to:&nbsp;<br/>‍<br/>Complete the transaction for which the personal
  information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with
  federal law, provide a good or service requested by the consumer, or reasonably anticipated by the consumer within the
  context of a business’ ongoing business relationship with the consumer, or otherwise perform a contract between the
  business and the consumer.<br/>‍<br/>Help to ensure security and integrity to the extent the use of the consumer’s
  personal information is reasonably necessary and proportionate for those purposes.&nbsp;&nbsp;&nbsp;<br/>‍<br/>Debug to
  identify and repair errors that impair existing intended functionality.&nbsp;&nbsp;&nbsp;&nbsp;<br/>‍<br/>Exercise free
  speech, ensure the right of another consumer to exercise that consumer’s right of free speech, or exercise another
  right provided for by law.<br/>‍<br/>Comply with the California Electronic Communications Privacy Act pursuant to
  Chapter 3.6 (commencing with Section 1546) of Title 12of Part 2 of the Penal
  Code.&nbsp;&nbsp;&nbsp;&nbsp;<br/>‍<br/>Engage in public or peer-reviewed scientific, historical, or statistical
  research that conforms or adheres to all other applicable ethics and privacy laws, when the business’ deletion of the
  information is likely to render impossible or seriously impair the ability to complete such research, if the consumer
  has provided informed consent.<br/>‍<br/>To enable solely internal uses that are reasonably aligned with the
  expectations of the consumer based on the consumer’s relationship with the business and compatible with the context in
  which the consumer provided the information.<br/>‍<br/>Comply with a legal obligation.<br/><br/>&nbsp;Visana may not, and
  will not, treat you differently because of your Consumer Request activity. As a result of your Consumer Request
  activity, we may not and will not deny goods or services to you; charge different rates for goods or services; provide
  a different level quality of goods or services; or suggest any of the preceding will occur. However, we can and may
  charge you a different rate, or provide a different level of quality, if the difference is reasonably related to the
  value provided by your personal information. <br/><br/>&nbsp;<strong>Questions and How to Contact
    Us<br/><br/>&nbsp;</strong>If you have any questions, concerns, complaints or suggestions regarding this Privacy
  Policy or otherwise need to contact us, please email us at&nbsp;<a
    href="mailto:members@visanahealth.com">members@visanahealth.com</a>or contact us by United States mail at 5123 W
  98th St #1345, &nbsp;Minneapolis, MN 55437<strong>.</strong></p>


    </div>
    </>
  )
}