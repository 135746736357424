import React, { useState } from "react"
import { getLogger } from '../log/getLogger'
import { d4l } from "../commonSrc/log/logUtil";
import { debounce } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import * as stringUtil from "../commonSrc/string/stringUtil"
import { useFirstNameMemory, useLastNameMemory, useEmailMemory, usePhoneMemory } from '../cookie/useProgressiveDisclosureMemory'


const LOG = getLogger('auth.SignupLoginHintCaptureFormComponent')


export function SignupLoginHintCaptureFormComponent () {
  const [loginHint, setLoginHint] = useEmailMemory()
  const [phone, setPhone] = usePhoneMemory()
  const [firstName, setFirstName] = useFirstNameMemory()
  const [lastName, setLastName] = useLastNameMemory()

  const { isAuthenticated, loginWithRedirect } = useAuth0();


  function isCallToActionEnabled(): boolean {
    return stringUtil.isPresent(loginHint) &&
    stringUtil.isPresent(phone) &&
    stringUtil.isPresent(firstName) &&
    stringUtil.isPresent(lastName)
  }

  // const debounceFn = React.useRef(debounce( () => {
  //   LOG.debug(`setLoginHintWrapper(): Forwarding debounced input = ${d4l(input)}`)
  // }, 300)).current;

  const debounceFn = React.useRef(debounce(setLoginHintDebounced, 600)).current;



  function setLoginHintDebounced(input: string) {
    LOG.debug(`setLoginHintDebounced(): Forwarding debounced input = ${d4l(input)}`)
  }


  const setLoginHintWrapper = (input: string) => {
    LOG.debug(`setLoginHintWrapper(): Entering with input = ${d4l(input)}`)
    setLoginHint(input)
    debounceFn(input)
  };

  const handleSubmit = async () => {
    LOG.debug(`handleSubmit(): Entering with loginHint = ${d4l(loginHint)}`)
    
    try {
      const headers: any = {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
      }

      const requestOptions = {
        method: 'POST',
        // mode: 'cors',
        headers: headers,
        body: JSON.stringify({
          email: loginHint,
          firstName: firstName,
          lastName: lastName,
          phone: phone
        })
      };
      
      const url = (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:9000') + `${process.env.REACT_APP_BACKEND_RELATIVE_API_PATH}` + '/contact'
      LOG.debug(`handleSubmit(): Sending POST to ${url},   with body = ${d4l(requestOptions.body)}`)

      const response: Response = await fetch(url, requestOptions)
      const payload = await response.json();
    } catch(error) {
      LOG.warn(`handleSubmit(): Email capture failed.  Reason: ${d4l(error)}`)
    }

    LOG.debug(`handleSubmit(): Performing a auth0.loginWithRedirect()...`)

    // https://auth0.github.io/auth0-vue/interfaces/RedirectLoginOptions.html
    // https://community.auth0.com/t/cannot-specify-passwordless-as-connection-using-auth0-react-and-loginwithredirect/69905
    // https://auth0.com/docs/login/universal-login/passwordless-login/email-or-sms 39
    // https://auth0.com/docs/login/universal-login/new-universal-login-vs-classic-universal-login
    // https://auth0.com/docs/authenticate/login/auth0-universal-login/new-universal-login-vs-classic-universal-login
    loginWithRedirect({
      authorizationParams: {
        login_hint: loginHint,
        // screen_hint: 'signup'
      }
    })
  };
  

  return (
    <>
    <form onSubmit={(event) => { event.preventDefault(); handleSubmit() }}>
      <div>
        <label htmlFor="firstName">First Name</label>
        <input className="form-control" id="firstName" placeholder={firstName || ''} value={firstName} onInput={(evt: React.FormEvent<HTMLInputElement>) => setFirstName((evt.target as HTMLInputElement).value as any)}/>
      </div>
      <div>
        <label htmlFor="lastName">Last Name</label>
        <input className="form-control" id="lastName" placeholder={lastName || ''} value={lastName} onInput={(evt: React.FormEvent<HTMLInputElement>) => setLastName((evt.target as HTMLInputElement).value as any)}/>
      </div>
      <div>
        <label htmlFor="loginHint">Email</label>
        <input className="form-control" id="loginHint" placeholder={loginHint || ''} value={loginHint} onInput={(evt: React.FormEvent<HTMLInputElement>) => setLoginHintWrapper((evt.target as HTMLInputElement).value as any)}/>
      </div>
      <div>
        <label htmlFor="phone">Phone</label>
        <input className="form-control" id="phone" placeholder={phone || ''} value={phone} onInput={(evt: React.FormEvent<HTMLInputElement>) => setPhone((evt.target as HTMLInputElement).value as any)}/>
      </div>
      <p className="small">
        <p><i>By clicking Get Started, you agree to the <a href="SB-001-CL-102_Informed_Consent_Form_09Jan2024.pdf" target="_blank">Informed Consent</a> and the <a href="SB_Website_Privacy_Policy.pdf" target="_blank">Privacy Policy</a></i></p>                
      </p>
      <button disabled={!isCallToActionEnabled()} className="btn btn-primary btn-monique" type="submit">Get Started</button>
    </form>
    </>
  )
}