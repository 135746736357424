import { getLogger } from "../log/getLogger";
const LOG = getLogger('bearerUtil.ts')


export function amILoggedIn(jwtToken: string): boolean {
  return !!(getMyUserId(jwtToken) != null)
}

export function getMyUserId(jwtToken: string): string | undefined  /* canonical UUID */ {
  const parsedJwtIdToken: any | null = parseJwt(jwtToken as string)
  if ( parsedJwtIdToken == null ) {
    LOG.warn(`isJwtTokenWellFormed(): parsedJwtIdToken is NULL`)
    return
  }

  // LOG.debug(`isJwtTokenWellFormed(): parsedJwtIdToken = ${JSON.stringify(parsedJwtIdToken, null, 2)}`)


  const sub: string | undefined = (parsedJwtIdToken != null) ? parsedJwtIdToken["sub"] : undefined
  return sub
}

export function getMyUsername(jwtToken: string): string | undefined /* username or email address of some kind */ {
  const parsedJwtIdToken: any | null = parseJwt(jwtToken as string)
  const oauth2Username: string | undefined = (parsedJwtIdToken != null) ? parsedJwtIdToken["cognito:username"] : undefined
  return oauth2Username
}

export function getMyCustomDateOfBirth(jwtToken: string): string | undefined /* username or email address of some kind */ {
  const parsedJwtIdToken: any | null = parseJwt(jwtToken as string)
  const oauth2Username: string | undefined = (parsedJwtIdToken != null) ? parsedJwtIdToken["custom:dob"] : undefined
  return oauth2Username
}

// Also clientId
export function getAudience(jwtToken: string): string | undefined {
  const parsedJwtIdToken: any | null = parseJwt(jwtToken as string)
  const retval: string | undefined = (parsedJwtIdToken != null) ? parsedJwtIdToken["aud"] : undefined
  return retval
}

export function parseJwt(jwtToken: string): JSON | null {
  if (typeof jwtToken === 'undefined' || jwtToken == null) { return jwtToken as null; }
  try {
    // https://stackoverflow.com/questions/23097928/node-js-throws-btoa-is-not-defined-error/47890385#47890385
    // return JSON.parse(atob(jwtToken.split('.')[1]));
    try {
      return JSON.parse(atob(jwtToken.split('.')[1]))
    } catch (err: any) {
      return JSON.parse(Buffer.from(jwtToken.split('.')[1], 'base64').toString('binary'))
    }
  } catch (e) {
    LOG.error(`parseJwt(): Failed to parse jwtToken = ${jwtToken}.   Reason: ${e}`)
    return null;
  }
};  

export function isJwtTokenWellFormed(jwtToken: string): boolean {
  LOG.debug(`isJwtTokenWellFormed(): Entering with jwtToken = '${jwtToken}'`)

  if (jwtToken != null && jwtToken.startsWith("eyJ")) {
    LOG.debug(`isJwtTokenWellFormed(): It's valid. jwtToken = '${jwtToken}'`)
    return true
  }

  LOG.warn(`isJwtTokenWellFormed(): INVALID!! jwtToken = '${jwtToken}'`)
  return false;
}


