export enum HypothesisCodeEnum { 
  UNSET = "Choose", 
  GLUTEN_SENSITIVITY = 'gluten-sensitivity',
  BILE_SALT_MALABSORPTION = 'bile-salt-malabsorption', 
  CONSTIPATION = 'constipation', 
  LACTOSE_FRUCTOSE_SUCROSE_MALABSORPTION = 'lactose-fructose-sucrose-malabsorption',
  BLOATING = "bloating",
  GERD = "gerd",
  IBS_D = "ibs-d",
  IBS_C = "ibs-c",
  IBS_M = "ibs-m",
  SIBO = "sibo",
  CELIAC = "celiac",
  FUNCTIONAL_DYSPEPSIA = "functional dyspepsia"
}  

/*
Gluten Sensitivity
Bile Salt Malabsorption
Constipation
Lactose, Fructose, Sucrose Malabsorption
Bloating
GERD
IBS-D
IBS-C
IBS-M
SIBO
Constipation
Celiac
Functional Dyspepsia
*/