import safeStringify from '../string/safeStringify'
import React, { useState, useReducer, useEffect } from 'react'
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import { getLogger } from '../log/getLogger'
import { StateScalarAttribute } from '../commonSrc/questionnaire/allQuestionnaireAttributes';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { setYesOrNo, selectYesOrNo, setRawString, setRawNumber } from '../questionnaire/questionnaireSlice';
import { YesOrNoCodeEnum, YesOrNoLabelEnum } from '../commonSrc/constant/yesOrNoConstant';
import { handleTooltipClick } from '../tooltip/tooltipUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { isHasNotTrue, isHasTrue, isTrueLike } from "../commonSrc/boolean/booleanUtil";
import { 
  hasPoopTypeSeparateHardLumps, 
  hasPoopTypeFluffyRaggedMushy,
  hasPoopTypeSausageShapedButLumpy,
  hasPoopTypeSausageWithCracks,
  hasPoopTypeSnakeSmooth,
  hasPoopTypeWatery,
  hasPoopTypeSoftBlobsClearEdges
} from '../commonSrc/questionnaire/numberedNamedQuestionUtil';
//import { currentStoolTypeNumber } from '../commonSrc/questionnaire/numberedNamedQuestionUtil';

const log = getLogger('question.CheckAllThatApplyQuestionComponent')



export default function CheckAllThatApplyQuestionComponent (props: any) {
  const label: string = "Which of the following best describes your current stool symptom?"
  const attrName: string = 'currentPoopTypeNumber'
  const tooltipText: string = props.tooltipText
  const onDiagnosticAnswersChanged = props.onDiagnosticAnswersChanged
  log.debug(`props = ${JSON.stringify(props)}`)
  log.debug(`label = ${label}`)

  const dispatch = useDispatch()


  const realtimeHasPoopTypeSeparateHardLumps: boolean = useSelector((state: SbhcCompositeState) => {
    return hasPoopTypeSeparateHardLumps(state.questionnaire)
  })

  const realtimeHasPoopTypeSausageShapedButLumpy: boolean = useSelector((state: SbhcCompositeState) => {
    return hasPoopTypeSausageShapedButLumpy(state.questionnaire)
  })

  const realtimeHasPoopTypeSausageWithCracks: boolean = useSelector((state: SbhcCompositeState) => {
    return hasPoopTypeSausageWithCracks(state.questionnaire)
  })

  const realtimeHasPoopTypeSnakeSmooth: boolean = useSelector((state: SbhcCompositeState) => {
    return hasPoopTypeSnakeSmooth(state.questionnaire)
  })

  const realtimeHasPoopTypeFluffyRaggedMushy: boolean = useSelector((state: SbhcCompositeState) => {
    return hasPoopTypeFluffyRaggedMushy(state.questionnaire)
  })

  const realtimeHasPoopTypeWatery: boolean = useSelector((state: SbhcCompositeState) => {
    return hasPoopTypeWatery(state.questionnaire)
  })

  const realtimeHasPoopTypeSoftBlobsClearEdges: boolean = useSelector((state: SbhcCompositeState) => {
    return hasPoopTypeSoftBlobsClearEdges(state.questionnaire)
  })


  // const currentValue: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => {
  //   return selectYesOrNo(attrName, state)
  // })

  // https://stackoverflow.com/questions/27784212/how-to-use-radio-buttons-in-reactjs
  const onChange = async (event: React.ChangeEvent<HTMLInputElement> | any, attr: string): Promise<void> => {
    log.debug(`onChange() - Entering with attr = ${attr}, event.target.value = ${safeStringify(event.target.value)},  event.target.checked = ${safeStringify(event.target.checked)},  event = ${safeStringify(event)}`)
    const booleanValue: boolean | null = isTrueLike(event.target.value)
    const checkedValue: boolean = event.target.checked

    log.debug(`onChange(): booleanValue = ${booleanValue} (${typeof booleanValue}),   checkedValue = ${checkedValue} (${typeof checkedValue})`)

    // Toggle! 
    dispatch(setYesOrNo({eventValue: checkedValue ? YesOrNoCodeEnum.YES : YesOrNoCodeEnum.NO, attrName: attr}))
    
    onDiagnosticAnswersChanged()
  }

  const onClickTooltip = function(event: any): void {
    handleTooltipClick(event, 'currentStoolTypeNumber')
  }

  return (
    <>
      <div className="poop-type-number-question-component">
        <fieldset>
          <label>
            Which of the following best describes your current symptom stool?
          </label>
          <div className="radio-group">
            <label>
              <input type='checkbox' className='d-none' name="hasPoopTypeSeparateHardLumps" value={YesOrNoCodeEnum.YES} checked={realtimeHasPoopTypeSeparateHardLumps === true} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event, 'hasPoopTypeSeparateHardLumps', )}/>
              <div className="icon-then-span">
                <img src="poop-1.svg" style={{cursor: 'pointer'}} alt="" /><br/>
                <span>Separate hard lumps</span>
              </div>
            </label>
            <label>
              <input type='checkbox' className='d-none' name="hasPoopTypeSausageShapedButLumpy" value={YesOrNoCodeEnum.YES} checked={realtimeHasPoopTypeSausageShapedButLumpy === true} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event, 'hasPoopTypeSausageShapedButLumpy')}/>
              <div className="icon-then-span">
                <img src="poop-2.svg" style={{cursor: 'pointer'}} alt="" /><br/>
                <span>Sausage-shaped but lumpy</span>
              </div>
            </label>
          </div>
          <div className="radio-group">
            <label>
              <input type='checkbox' className='d-none' name="hasPoopTypeSausageWithCracks" value={YesOrNoCodeEnum.YES} checked={realtimeHasPoopTypeSausageWithCracks === true} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event, 'hasPoopTypeSausageWithCracks')}/>
              <div className="icon-then-span">
                <img src="poop-3.svg" style={{cursor: 'pointer'}} alt="" /><br/>
                <span>Like a sausage but with cracks on surface</span>
              </div>
            </label>
            <label>
              <input type='checkbox' className='d-none' name="hasPoopTypeSnakeSmooth" value={YesOrNoCodeEnum.YES} checked={realtimeHasPoopTypeSnakeSmooth === true} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event, 'hasPoopTypeSnakeSmooth')}/>
              <div className="icon-then-span">
                <img src="poop-4.svg" style={{cursor: 'pointer'}} alt="" /><br/>
                <span>Like a sausage or snake, smooth and soft</span>
              </div>
            </label>
            <label>
              <input type='checkbox' className='d-none' name="hasPoopTypeSoftBlobsClearEdges" value={YesOrNoCodeEnum.YES} checked={realtimeHasPoopTypeSoftBlobsClearEdges === true} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event, 'hasPoopTypeSoftBlobsClearEdges')}/>
              <div className="icon-then-span">
                <img src="poop-5.svg" style={{cursor: 'pointer'}} alt="" /><br/>
                <span>Soft blobs with clear-cut edges</span>
              </div>
            </label>
          </div>
          <div className="radio-group">
            <label>
              <input type='checkbox' className='d-none' name="hasPoopTypeFluffyRaggedMushy" value={YesOrNoCodeEnum.YES} checked={realtimeHasPoopTypeFluffyRaggedMushy === true} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event, 'hasPoopTypeFluffyRaggedMushy')}/>
              <div className="icon-then-span">
                <img src="poop-6.svg" style={{cursor: 'pointer'}} alt="" /><br/>
                <span>Fluffy pieces with ragged edges, a mushy stool</span>
              </div>
            </label>
            <label>
              <input type='checkbox' className='d-none' name="hasPoopTypeWatery" value={YesOrNoCodeEnum.YES}  checked={realtimeHasPoopTypeWatery === true} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event, 'hasPoopTypeWatery')}/>
              <div className="icon-then-span">
                <img src="poop-7.svg" style={{cursor: 'pointer'}} alt="" /><br/>
                <span>Watery, no solid pieces, entirely liquid</span>
              </div>
            </label>
          </div>
        </fieldset> 
      </div>
    </>
  )
}