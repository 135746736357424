import { injectable } from "inversify";
import { WhoAmISvc } from "./WhoAmISvc";

import { AuthenticatedUserDetails, WhoAmIWriteService } from "./WhoAmIWriteService";
import safeStringify from "../string/safeStringify";
import * as Rx from "rxjs";
import * as stringUtil from "../commonSrc/string/stringUtil"
import { getLogger } from "../log/getLogger";
import { d4l } from "../commonSrc/log/logUtil";



const LOG = getLogger('WhoAmISvcImpl')

@injectable()
export class WhoAmISvcImpl implements WhoAmISvc, WhoAmIWriteService {
  private authenticatedUserDetails: undefined | AuthenticatedUserDetails
  private subject: Rx.Subject<void> = new Rx.Subject()
  private observable: Rx.Observable<void> = this.subject.asObservable()

  public logout(): void {
    LOG.info(() => `logout(): Logging out User ID ${d4l(this.authenticatedUserDetails)}...`)
    this.authenticatedUserDetails = undefined   
    this.subject.next()
  }

  public amILoggedIn(): boolean {
    return this.authenticatedUserDetails?.authenticatedFlag || false
  }

  public getMyUserId(): string | undefined  /* may or may not be a canonical UUID */ {
    return this.authenticatedUserDetails?.userId

  }

  public getMyUsername(): string | undefined /* username or email address of some kind */ {
    // const jwtToken = this.getMyAuthToken()
    // const parsedJwtIdToken: any | null = this.parseJwt(jwtToken as string)
    // const oauth2Username: string | undefined = (parsedJwtIdToken != null) ? parsedJwtIdToken["cognito:username"] : undefined
    // return oauth2Username
    return this.authenticatedUserDetails?.nickname
  }

  public getMyEmailAddress(): string | undefined /* undefned or the user's email address */ {
    // const jwtToken = this.getMyAuthToken()
    // const parsedJwtIdToken: any | null = this.parseJwt(jwtToken as string)
    // const email: string | undefined = (parsedJwtIdToken != null) ? parsedJwtIdToken["email"] : undefined
    // return email
    return this.authenticatedUserDetails?.email
  }


  public getMyAuthToken(): string | undefined
  {
    // return window.sessionStorage.getItem(`id_token`) || undefined;
    return this.authenticatedUserDetails?.authToken
  }

  public defGetMyAuthToken(): string
  {
    const retval: string = this.authenticatedUserDetails?.authToken as unknown as string
    if (stringUtil.isEmpty(retval)) {
      LOG.error(`defGetMyAuthToken(): Expected to have an authToken but there was none`)
      throw new Error(`Expected to have an authToken but there was none`)
    }
    return retval
  }

  // private parseJwt(jwtToken: string): JSON | null {
  //   if (typeof jwtToken === 'undefined' || jwtToken == null) { return jwtToken as null; }
  //   try {
  //     return JSON.parse(atob(jwtToken.split('.')[1]));
  //   } catch (e) {
  //     LOG.error(`parseJwt(): Failed to parse jwtToken = ${jwtToken}.   Reason: ${e}`)
  //     return null;
  //   }
  // };
  
  public ensureAuthenticatedUserDetailsAreUpToDate(authenticatedUserDetails: AuthenticatedUserDetails): void {
    LOG.debug(`ensureAuthenticatedUserDetailsAreUpToDate(): Entering with authenticatedUserDetails = ${safeStringify(authenticatedUserDetails)}`)
    
    let existingAuthenticatedUserDetails: AuthenticatedUserDetails | undefined = this.authenticatedUserDetails
    
    
    let loggedInOneSecondAgoFlag: boolean = false;
    let loggedInOneSecondFromNow: boolean = false;

    if (existingAuthenticatedUserDetails != null && existingAuthenticatedUserDetails.authenticatedFlag === true) {
      loggedInOneSecondAgoFlag = true
    }
    if (authenticatedUserDetails != null && authenticatedUserDetails.authenticatedFlag === true) {
      loggedInOneSecondFromNow = true
    }
    
    if (loggedInOneSecondAgoFlag !== true && loggedInOneSecondFromNow === true) {
      LOG.info(`ensureAuthenticatedUserDetailsAreUpToDate(): Just became AUTHENTICATED.  Sign-in success.  yay!`)
    }



    const old1: string = JSON.stringify(existingAuthenticatedUserDetails)
    const new1: string = JSON.stringify(authenticatedUserDetails)
    
    if ( old1 !== new1 ) {
      LOG.debug(`ensureAuthenticatedUserDetailsAreUpToDate(): User auth state has changed.  old = ${d4l(old1)},  new = ${d4l(new1)}`)
      try {
        let clone: AuthenticatedUserDetails = JSON.parse(new1)
        this.authenticatedUserDetails = clone
        this.subject.next()
      } catch(err) {
        LOG.error(`ensureAuthenticatedUserDetailsAreUpToDate():  clone probs.  err = ${err}`)
      }
    }

    if (loggedInOneSecondFromNow == false) {
      LOG.debug(`ensureAuthenticatedUserDetailsAreUpToDate():  I remain NOT authenticated`)
    }
  }

  public getStateChangeObservable(): Rx.Observable<void> {
    return this.observable
  }

}