import { SbNotification } from "./SbNotification";
import { SbNotificationConstructionAttr } from "./SbNotificationConstructionAttr";

// circular import?
import { SbNotificationMessageGenerator } from "./SbNotificationMessageGenerator";

export class SbNotificationImpl implements SbNotification {
  private readonly code: string | undefined;
  private readonly sbNotificationMessageGenerator: SbNotificationMessageGenerator

  constructor(attr: SbNotificationConstructionAttr, sbNotificationMessageGenerator: SbNotificationMessageGenerator) {
    if (attr.hasOwnProperty('code')) {
      this.code = attr.code
    }
    this.sbNotificationMessageGenerator = sbNotificationMessageGenerator
  }

  public getCode(): string | undefined {
    return this.code
  }

  public getHtmlMessage(): string {
    return this.sbNotificationMessageGenerator.generateHtmlMessage(this)
  }
}