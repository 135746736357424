import safeStringify from '../string/safeStringify'
import React, { useState, useReducer, useEffect } from 'react'
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import { StateScalarAttribute } from '../commonSrc/questionnaire/allQuestionnaireAttributes';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { CurrentPreviousNeverCodeEnum, CurrentPreviousNeverLabelEnum } from '../commonSrc/constant/currentPreviousNeverConstant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { setRawString, selectRawString } from '../questionnaire/questionnaireSlice';
import { getLogger } from '../log/getLogger'
const log = getLogger('question.FeetAndInchesComponent')

export default function FeetAndInchesComponent (props: any) {
  const label: string = props.label  // Nausea
  const attrName: string = props.attrName // everNausea
  const tooltipText: string = props.tooltipText
  const onDiagnosticAnswersChanged = props.onDiagnosticAnswersChanged
  log.debug(`props = ${JSON.stringify(props)}`)
  log.debug(`label = ${label}`)
  log.debug(`attrName = ${attrName}`)

  const dispatch = useDispatch()


  const onChangeFeet = async (event: React.ChangeEvent<HTMLInputElement> | any): Promise<void> => {
    log.debug(`onChangeFeet() - Entering with event = ${safeStringify(event)}`)
    const totalInches = Number(event.target.value || 0) * 12 + Number(currentInches || 0)
    dispatch(setRawString({eventValue: totalInches, attrName: attrName}))
    onDiagnosticAnswersChanged()
  }

  const onChangeInches = async (event: React.ChangeEvent<HTMLInputElement> | any): Promise<void> => {
    log.debug(`onChangeInches() - Entering with event = ${safeStringify(event)}`)
    const totalInches = Number(currentFeet || 0) * 12 + Number(event.target.value || 0)
    dispatch(setRawString({eventValue: totalInches, attrName: attrName}))
    onDiagnosticAnswersChanged()
  }

  // const currentValue: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => {
  //   try {
  //     return selectRawString(attrName, state)
  //   } catch(err) {
  //     return undefined;
  //   }
  // })

  const currentFeet: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => {
    const rawString: string = selectRawString(attrName, state)
    log.debug(`currentFeet(): rawString = '${rawString}'  rawString.length = ${rawString?.length}`)
    if (rawString != null && rawString.length > 0) {
      try {
        const retval = Math.floor(Number(rawString) / 12).toString()
        log.debug(`currentFeet(): retval = '${retval}'`)
        return retval
      } catch(err) {
        log.error(`currentFeet(): err = '${err}'`)
        return ''
      }
    }
    log.debug(`currentFeet(): Returning default cuz rawString = '${rawString}'`)
    return ''; 
  })

  const currentInches: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => {
    const rawString = selectRawString(attrName, state)
    log.debug(`currentInches(): rawString = '${rawString}'`)

    if (rawString != null && rawString.length > 0) {
      try {
        return (Number(rawString) % 12).toString()
      } catch(e) {
        return ''
      }
    }
    return ''; 
  })

  const onClickTooltip = function(event: any): void {
    event.preventDefault();
    const currentDisplay = document.getElementById("" + attrName.toString() + '-tip')!.style.display;
    const nextTooltipDisplay = (currentDisplay === "none") ? "block" : "none";
    document.getElementById("" + attrName.toString() + '-tip')!.style.display = nextTooltipDisplay
  }

  // const onCloseTooltip = function(event: any): void {
  //   event.preventDefault();
  //   document.getElementById("" + attrName.toString() + '-tip')!.style.display = 'none'
  // }

  return (
    <>
      <div className="feet-and-inches-component">
        <div className="row">
          <div className="col-6">
            <select className="form-select" aria-label="Default select example" value={currentFeet ?? ''} onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await onChangeFeet(event)}>
              <option value="">Feet</option>
              <option value="2">2'</option>
              <option value="3">3'</option>
              <option value="4">4'</option>
              <option value="5">5'</option>
              <option value="6">6'</option>
              <option value="7">7'</option>
              <option value="8">8'</option>
            </select>
          </div>
          <div className="col-6">
            <select className="form-select" aria-label="Default select example" value={currentInches ?? ''} onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await onChangeInches(event)}>
              <option value="">Inches</option>
              <option value="0">0"</option>
              <option value="1">1"</option>
              <option value="2">2"</option>
              <option value="3">3"</option>
              <option value="4">4"</option>
              <option value="5">5"</option>
              <option value="6">6"</option>
              <option value="7">7"</option>
              <option value="8">8"</option>
              <option value="9">9"</option>
              <option value="10">10"</option>
              <option value="11">11"</option>
            </select>
          </div>
        </div>
      </div>
    </>
  )
}