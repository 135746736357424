import { TOTAL_NUM_STEPS } from "../commonSrc/constant/stepDetail"

export default function IntakeQuestionnaireProgressComponent (props: any) {
  const percentComplete: string = `${props.stepNumber / TOTAL_NUM_STEPS * 100}%`
  // console.log(`percentComplete = ${percentComplete}`)

  return (

    <>
      <div className="progress-block">
        <header>
          <h2>Intake Questionnaire</h2>
          <button>Step {props.stepNumber} out of {TOTAL_NUM_STEPS}</button>
          </header>
        <div className="progress-bar">
          <div className="progress-bar-inner" style={{ width: percentComplete}}></div>
        </div>
      </div>

    </>
  )
}