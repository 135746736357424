import React from "react";
import { WhoAmISvc } from '../auth/WhoAmISvc'
import diContainer from '../inversify.config'
import DI_TYPES from '../diTypes'
import { Route, Navigate, Outlet } from 'react-router-dom';
import { getLogger } from '../log/getLogger'
import { d4l } from "../commonSrc/log/logUtil";

const LOG = getLogger('auth.RouteRequiresLogin')


// https://stackoverflow.com/questions/43164554/how-to-implement-authenticated-routes-in-react-router-4/43171515#43171515
// https://stackoverflow.com/questions/69864165/error-privateroute-is-not-a-route-component-all-component-children-of-rou
// https://stackoverflow.com/questions/47476186/when-user-is-not-logged-in-redirect-to-login-reactjs
const RouteRequiresLogin = () => {
  const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc);
  const loggedInFlag: boolean = whoAmISvc.amILoggedIn();

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  if (loggedInFlag) {
    LOG.debug(`RouteRequiresLogin(): Logged in as expected`)
    return <Outlet />
  } else {
    LOG.debug(`RouteRequiresLogin(): Gated path requires sign-in`)
    return <Navigate to="/signin" />;
  }

};

export default RouteRequiresLogin;