import { getLogger } from '../log/getLogger'
import DI_TYPES from "../diTypes";
import { HypothesisDecorationSvc } from "../hypothesis/HypothesisDecorationSvc";
import diContainer from '../inversify.config'
import { HypothesisCodeEnum } from '../commonSrc/hypothesis/HypothesisCodeEnum';
import { useDeveloperMode } from '../developer/useDeveloperMode';

const log = getLogger('hypothesis.ShowOneHypothesisComponent')

export default function ShowOneHypothesisComponent (props: any) {
  const [developerMode] = useDeveloperMode();

  const hypothesisDecorationSvc: HypothesisDecorationSvc = diContainer.get<HypothesisDecorationSvc>(DI_TYPES.HypothesisDecorationSvc)

  const hypothesis: any = props.hypothesis 
  // Deal with codes that have spaces
  const code: HypothesisCodeEnum = hypothesis.hypothesisCode
  const codeNoSpaces: HypothesisCodeEnum = hypothesis.hypothesisCode ? hypothesis.hypothesisCode.replace(/\s/g, '_') : ''

  const onDiagnosticAnswersChanged = props.onDiagnosticAnswersChanged
  log.debug(`props = ${JSON.stringify(props)}`)
  log.debug(`code = ${code}`)
  log.debug(`codeNoSpaces = ${codeNoSpaces}`)

  const headerId = `flush-${codeNoSpaces}-heading`
  const contentId = `flush-${codeNoSpaces}-collapsed`
  const parentId = props.parentId
  const definitePossibility: boolean = hypothesis.definitePossibility
  const redFlagsPresent: boolean = hypothesis.redFlagsPresent
  const reasonCode: boolean = hypothesis.reasonCode

  return (
    <>
    {developerMode &&
      <div className="developer-mode">
        hypothesis/definitePossibility/redFlagsPresent/reasonCode = 
        <code>{code}/{definitePossibility==true ? 'TRUE' : 'FALSE'}/{redFlagsPresent? 'TRUE' : 'FALSE'}/{reasonCode}</code>
      </div>
    }
    { 
      definitePossibility === true && 
      <div className="accordion-item">
        <h2 className="accordion-header" id={headerId}>
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${contentId}`} aria-expanded="false" aria-controls={contentId}>
            {hypothesisDecorationSvc.getNameForHypothesisCode(code)}
          </button>
        </h2>
        <div id={contentId} className="accordion-collapse collapse" aria-labelledby={headerId} data-bs-parent={`#${parentId}-BOGUS`}>
          <div className="accordion-body">
            <div style={{margin: '20px'}}>
              {/* <div>
                <span  style={{fontFamily: 'monospace'}}>Is this a definite possibility?</span>
                <span style={{marginLeft: '14px'}}>{definitePossibility ? 'Perhaps.' : 'Probably not.  Your symptoms don\'t seem to suggest this.'}</span>
              </div>
              <div>
                {redFlagsPresent
                  ? <div className="alert alert-danger" role="alert">Something in your symptoms suggests to us that blah blah red flags... go see the doctor.</div> 
                  : <span>No red flags... perhaps this is something we can help with.</span>
                }
              </div> */}
              <div className="condition-description">
                {code === HypothesisCodeEnum.GLUTEN_SENSITIVITY && 
                  <>K - still need content for Gluten Sensitivity</>
                }
                {code === HypothesisCodeEnum.BILE_SALT_MALABSORPTION && 
                  <>Bile salts are a part of bile, which is the fluid produced by the liver and stored in the gallbladder that helps with the digestion of dietary fats. Bile salts emulsify fat into smaller droplets to assist with absorption. When someone malabsorbs bile, it results in symptoms like watery diarrhea and fat-soluble vitamin deficiencies.</>
                }
                {code === HypothesisCodeEnum.CONSTIPATION && 
                  <>Constipation is a common GI disorder characterized by some or all of these symptoms: having less than three bowel movements per week, straining during bowel movements, lumpy and hard stools, feeling like your bowel movements are incomplete, feeling like there is a blockage in your anorectal area, and/or having to use your hands to facilitate a bowel movement. Constipation is different from IBS-C in that it doesn't typically cause pain.</>
                }
                {code === HypothesisCodeEnum.LACTOSE_FRUCTOSE_SUCROSE_MALABSORPTION && 
                  <>Fructose, sucrose, and lactose intolerances are common in functional gut disorders. Our ability to absorb these sugars can be impacted by various factors, including lacking enzymes that break them down, which can lead to GI symptoms like bloating and diarrhea. Frustrating, we know, but with an accurate diagnosis you’ll be able to get the right diet and nutritional supplement recommendations to feel better fast.</>
                }
                {code === HypothesisCodeEnum.BLOATING && 
                  <>Bloating is the feeling that you have an inflated balloon in your belly (if you know, you know!). Bloating can come with or without distention, which is when your abdomen actually appears to be expanding outward. Some level of bloating is considered normal (think about how good gut bacteria love to ferment certain foods, which supplies energy to our gut cells), but too much may mean we've got something going on that needs a little more attention so you can get some relief.</>
                }
                {code === HypothesisCodeEnum.GERD && 
                  <>Gastroesophageal reflux (aka GERD) is characterized by the contents of your stomach flowing back into your esophagus. This reflux happens as a result of your lower esophageal sphincter (or muscle valve between your stomach and esophagus) doesn't close properly, especially when certain foods increase pressure on it or increase stomach acid production. </>
                }
                {code === HypothesisCodeEnum.IBS_C && 
                  <>Irritable bowel syndrome (IBS) is a type of functional gastrointestinal disorder characterized by recurring abdominal pain and a change in stool frequency and/or form. The exact cause of IBS is unknown, but experts think it may be related to having a more sensitive digestive nervous system, altered GI motility, low grade inflammation, changes in your gut microbiota, and other factors. IBS-C is a form of IBS that is constipation predominant, meaning you have hard, dry stools most of the time and rarely have loose or liquid stools (which we know can totally impact your mood and energy!). While understanding the types of food that trigger symptoms is important, managing IBS-C also involves supporting your gut-brain axis (or the two-way communication between your gut and brain). </>
                }
                {code === HypothesisCodeEnum.IBS_D && 
                  <>Irritable bowel syndrome (IBS) is a type of functional gastrointestinal disorder characterized by recurring abdominal pain and a change in stool frequency and/or form. The exact cause of IBS is unknown, but experts think it may be related to having a more sensitive digestive nervous system, altered GI motility, low grade inflammation, changes in your gut microbiota, and other factors. IBS-D is a form of IBS that is diarrhea predominant, meaning you have loose or liquid stools most of the time and rarely have hard, dry stools (which we know can make things like traveling really tough). While understanding the types of food that trigger symptoms is important, managing IBS-D also involves supporting your gut-brain axis (or the two-way communication between your gut and brain). </>                
                }
                {code === HypothesisCodeEnum.IBS_M && 
                  <>Irritable bowel syndrome (IBS) is a type of functional gastrointestinal disorder characterized by recurring abdominal pain and a change in stool frequency and/or form. The exact cause of IBS is unknown, but experts think it may be related to having a more sensitive digestive nervous system, altered GI motility, low grade inflammation, changes in your gut microbiota, and other factors. IBS-M is a form of IBS that alternates between diarrhea and constipation (which we know can make things really confusing for you!). While understanding the types of food that trigger symptoms is important, managing IBS-M also involves supporting your gut-brain axis (or the two-way communication between your gut and brain). </>
                }
                {code === HypothesisCodeEnum.SIBO && 
                  <>Small Intestinal Bacterial Overgrowth (SIBO) is a condition in which bacteria inappropriately grow in your small intestine, and it can be associated with a variety of conditions including IBS, hypothyroidism, and certain connective tissue and systemic disorders (including scleroderma) to name a few. Symptoms can include bloating, distention, increased gas, diarrhea and/or constipation, and abdominal pain, and it often comes with nutrient malabsorption, including fat which often leads to floating and foul-smelling stool. </>
                }
                {code === HypothesisCodeEnum.CELIAC && 
                  <>Celiac disease is an autoimmune disorder that is characterized by a genetically-based sensitivity to a protein found in wheat, barley, and rye known as gluten. When someone with Celiac disease eats something with gluten, their immune system is triggered to attack the lining of your small intestine. This damages something called your villi, which are tiny finger-like projections on this lining where nutrient absorption happens. The only way to heal and prevent this damage, as well as resolve your symptoms and treat potential complications like nutrient deficiencies and reduced bone mineral density, is following a life-long strict gluten-free diet but, don't worry, it's not as bad as it sounds. We have come a long way and there are loads of g-free alternatives to your favorite foods. </>
                }
                {code === HypothesisCodeEnum.FUNCTIONAL_DYSPEPSIA && 
                  <>Functional dyspepsia is characterized by indigestion without any clear cause. Indigestion feels like pain or discomfort in your upper abdomen (think right above your rib bone), bloating, burping, and/or nausea, but many people lovingly refer to it as feeling "blah" after eating.</>
                }
              </div>
            </div>
          </div>
        </div>
      </div> 
    }
    </>   
  )
}