export enum YesOrNoCodeEnum { 
  UNSET = "unset",
  YES = "yes", 
  NO = "no", 
}  

export enum YesOrNoLabelEnum { 
  UNSET = "- Choose -",
  YES = "Yes", 
  NO = "No",  
}  