// CREATE TYPE up_to_3plus_times_per_week AS ENUM ('weekly', 'monthly', '3plus_times_per_week');

export enum AlwaysSometimesNeverCodeEnum { 
  UNSET = "unset", 
  ALWAYS = "always", 
  SOMETIMES = "sometimes", 
  NEVER = "never", 
}  

export enum AlwaysSometimesNeverLabelEnum { 
  UNSET = "Choose", 
  ALWAYS = "Always", 
  SOMETIMES = "Sometimes", 
  NEVER = "Never", 
}  

export function isAsnAlwaysOrSometimes(code: AlwaysSometimesNeverCodeEnum | string): boolean {
  return (code === AlwaysSometimesNeverCodeEnum.ALWAYS) || (code === AlwaysSometimesNeverCodeEnum.SOMETIMES)
}