export enum OneToSixPlusMonthsCodeEnum { 
  UNSET = "unset",
  ONE_MONTH = "1 month", 
  THREE_MONTHS = "3 months", 
  SIX_PLUS_MONTHS = "6+ months", 
}  

export function isOneMonth( value: OneToSixPlusMonthsCodeEnum | string ): boolean {
  return value === OneToSixPlusMonthsCodeEnum.ONE_MONTH
}

export function isThreeMonths( value: OneToSixPlusMonthsCodeEnum | string ): boolean {
  return value === OneToSixPlusMonthsCodeEnum.THREE_MONTHS
}

export function isSixPlusMonths( value: OneToSixPlusMonthsCodeEnum | string ): boolean {
  return value === OneToSixPlusMonthsCodeEnum.SIX_PLUS_MONTHS
}

export enum OneToSixPlusMonthsLabelEnum { 
  UNSET = "unset",
  ONE_MONTH = "1 month", 
  THREE_MONTHS = "3 months", 
  SIX_PLUS_MONTHS = "6+ months",
}