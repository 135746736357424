import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getLogger } from '../log/getLogger'
const LOG = getLogger('Auth0SigninComponent.tsx');

export default function Auth0SignInComponent() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect( () => {
    LOG.debug(`useEffect::callback: Entering.  isAuthenticated = ${isAuthenticated}`)
    if (!isAuthenticated) {
      loginWithRedirect()    
    } else {
      LOG.debug(`useEffect::callback: Already authenticated`)
    }
  }, []);

  return (
    <></>
  );
}
