export enum PbmCodeEnum { 
  UNSET = "unset",
  STOMACH_FEELS_EMPTY = "stomach-feels-empty", 
  STILL_FEELS_LIKE_NEED_TO_POOP = "still-feels-like-need-to-poop", 
}  

export enum PbmLabelEnum { 
  UNSET = "- Choose -",
  STOMACH_FEELS_EMPTY = "My stomach feels empty", 
  STILL_FEELS_LIKE_NEED_TO_POOP = "I still feel like I need to poop more but nothing is coming out",  
}  
