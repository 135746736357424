import logo from '../logo.svg'
import { Counter } from '../counter/Counter'
import SignIn from '../auth/SignIn'
import { Route, Routes, Link, useSearchParams } from 'react-router-dom'
import QuestionnaireComponent from '../questionnaire/QuestionnaireComponent'
import AuthenticatedComponent from '../authenticate/AuthenticatedComponent'
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import CognitoSignIn from '../auth/CognitoSignIn'
import ProfileComponent from '../auth/ProfileComponent'
import { WhoAmISvc } from '../auth/WhoAmISvc'
import diContainer from '../inversify.config'
import DI_TYPES from '../diTypes'
import RouteRequiresLogin from '../auth/RouteRequiresLogin'
import DefaultLayoutComponent from '../layout/DefaultLayoutComponent'
import RiPageComponent from '../ri/RiPageComponent'
import SignOutWidgetComponent from '../auth/SignOutWidgetComponent'
import CognitoSignUp from '../auth/CognitoSignUp'
import InitialHypothesisComponent from '../hypothesis/InitialHypothesisComponent'
import DevModeComponent from '../developer/DevModeComponent'
import { getLogger } from '../log/getLogger'
import { useDeveloperMode } from '../developer/useDeveloperMode'
import { isHasNotTrue, isHasTrue, isTrueLike, isFalseLike } from "../commonSrc/boolean/booleanUtil";
import UpdateInsuranceComponent from '../insurance/UpdateInsuranceComponent'
import UpdateMedicationComponent from '../medication/UpdateMedicationComponent'
import AgreementComponent from '../agreement/AgreementComponent'
import TermsAndConditionsComponent from '../term/TermsAndConditionsComponent'
import PrivacyPolicyComponent from '../privacy/PrivacyPolicyComponent'
import { PaidSearchLandingPageComponent } from '../PaidSearchLandingPageComponent'
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoginWithAuth0Button from '../auth/LoginWithAuth0Button'
import Auth0CallbackPageComponent from '../auth/Auth0CallbackPageComponent'
import GotAuth0CodeAndStateComponent from '../auth/GotAuth0CodeAndStateComponent'
import { WhoAmIWriteService } from "../auth/WhoAmIWriteService";
import * as Rx from "rxjs";


const LOG = getLogger('DeveloperAuthDetailComponent.tsx')


export function DeveloperAuthDetailComponent () {
  // useEffect(() => {
  //   window.location.href = "/questionnaire" as string  
  // }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const codeParam = searchParams.get("code")
  const stateParam = searchParams.get("state")


  const [getter_UNUSED, setForcedRerenderAt] = useState('');


  const { user, isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth0();
  const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)

  const observable: Rx.Observable<void> = whoAmISvc.getStateChangeObservable()
  let subscription: Rx.Subscription = observable.subscribe(() => {
    handleAutenticatedUserStateChange()
  })

  const handleAutenticatedUserStateChange = () => {
    LOG.info(`handleAutenticatedUserStateChange():  Entering`);
    
    // hopefully this triggers a page refresh
    setForcedRerenderAt(new Date().toISOString())

  }

  const tryNoopGetAccessTokenSilently = async () => {
    try {
      if (isAuthenticated) {
        /* const authToken = */ await getAccessTokenSilently()
      }
    } catch(error) {
      LOG.warn(`tryNoopGetAccessTokenSilently():  Swallowing error: ${error}`, error);
    }
  }

  useEffect(() => {
    LOG.debug(`DeveloperAuthDetailComponent()::useEffect:  Entering`);
    tryNoopGetAccessTokenSilently()
    setForcedRerenderAt(new Date().toISOString())
  
    if (error) {
      setLoginStatusMessage(`Oops... ${error.message}`);
    }
    else if (isLoading) {
      setLoginStatusMessage("Completing login...");
    }
    
    return () => {
      LOG.debug(`DeveloperAuthDetailComponent()::useEffect:  Unmounting...`);
      subscription.unsubscribe()
    }
  }, [getAccessTokenSilently, user?.sub]);

  const [loginStatusMessage, setLoginStatusMessage] = useState('');






  return (
    <>
      <div>{ loginStatusMessage }</div>
      <br/><br/>
      <h1>DeveloperAuthDetailComponent - This is developer-only stuff.  Please ignore for now</h1>
      <div style={{fontFamily: "monospace"}}>
      <div>
        code = <code>{codeParam}</code>
      </div>
      <div>
        state = <code>{stateParam}</code>
      </div>
      <div>
        isAuthenticated = <code>{isAuthenticated ? 'TRUE' : 'FALSE'}</code>
      </div>
      <div>
        whoAmISvc.amILoggedIn() = <code>{whoAmISvc.amILoggedIn() ? 'TRUE' : 'FALSE'}</code>
      </div>      
      <div>
        user.email = <code>{user?.email}</code>
      </div>
      <div>
        user.name = <code>{user?.name}</code>
      </div>
      <div>
        isLoading = <code>{isLoading}</code>
      </div>
      <div>
        error = <code>{`${error}`}</code>
      </div>
      <div>
        user = <code>{user ? JSON.stringify(user) : undefined}</code>
      </div>
      <div>
        user.sub = <code>{user?.sub}</code>
      </div>
      <div>
        whoAmISvc.getMyAuthToken() = <code>{whoAmISvc.getMyAuthToken()}</code>
      </div>   
      <div>
        whoAmISvc.getMyEmailAddress() = <code>{whoAmISvc.getMyEmailAddress()}</code>
      </div>  

      <footer>
       <p>Copyright 2023 Second Brain Healthcare</p>   
       <ul>
         <li><Link to={`/terms-and-conditions`}>Terms &amp; Conditions</Link></li>
         <li><Link to={`/privacy-policy`}>Privacy Policy</Link></li>
         <li><Link className="nav-link nav-link-developer-mode" to={`/ri`}>ri</Link></li>
         <li><Link className="nav-link nav-link-developer-mode" to={`/devmode`}>devmode</Link></li>
         <li><Link to={`/`}>Home</Link></li>
         <li><Link to={`/questionnaire`}>Questionnaire</Link></li>
         <li><Link to={`/signin`}>Sign in</Link></li>
         <li><Link to={`/hipaa-notice`}>HIPAA Notice</Link></li>
         <li><Link to={`/communication-policy`}>Communication Policy</Link></li>
         <li><Link to={`/telehealth-informed-consent`}>Telehealth Informed Consent</Link></li>
         <li>
            <Link to={`/signout`}>Sign Out</Link>
          </li>
       </ul>     
      </footer>

      </div>
    </>
  )
}