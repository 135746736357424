import { useAuth0 } from "@auth0/auth0-react"
import { getLogger } from './log/getLogger'
import React, { useEffect, useState } from "react";
import { WhoAmISvc } from './auth/WhoAmISvc';
import diContainer from './inversify.config'
import DI_TYPES from "./diTypes";
import * as bearerUtil from './commonSrc/auth/bearerUtil'
import { setSignature, selectSignature, selectUserSpecifiedSignatureDate, setUserSpecifiedSignatureDate } from './user/userAttributesSlice'
import safeStringify from './string/safeStringify';
import { useDispatch, useSelector } from 'react-redux';
import { SbhcCompositeState } from './state/SbhcCompositeState';
import * as stringUtil from "./commonSrc/string/stringUtil"
import { WhoAmIWriteService } from './auth/WhoAmIWriteService'
import { booleanUtil, d4l } from "@jgithub/ts-gist-pile";
import { NextPageFlowFinderService } from "./NextPageFlowFinderService";
import { useLocation, Location, useNavigate } from "react-router-dom";

// const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc);

const LOG = getLogger('RedirectAsNeededComponent')




export default function RedirectAsNeededComponent (props: any) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location: Location = useLocation();

  const { user, isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth0();
  const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
  const whoAmIWriteService: WhoAmIWriteService = diContainer.get<WhoAmIWriteService>(DI_TYPES.WhoAmIWriteService)

  let numTimesThroughLogic: number = 0
  const autoPerform: boolean = booleanUtil.isTruelike(props.autoPerform)

  const finishedRunningThroughLogic = (goWhere: string | null) => {
    if (props.afterRunOnce != null) {
      props.afterRunOnce(++numTimesThroughLogic, goWhere);     
    }       
  }

  const currentSignatureInState: string = useSelector((state: SbhcCompositeState) => {
    return selectSignature(state)
  })

  const currentUserSpecifiedSignatureDate: string = useSelector((state: SbhcCompositeState) => {
    return selectUserSpecifiedSignatureDate(state)
  })

  // const nextPageFlowFinderService: NextPageFlowFinderService = diContainer.get<NextPageFlowFinderService>(DI_TYPES.NextPageFlowFinderService);


  async function redirectMeAsNeeded(): Promise<void> {
    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)

    const ensureWhoamiSvcIsUpToDate = async () => {
      try {
        if (isAuthenticated) {
          LOG.debug(`ensureWhoamiSvcIsUpToDate():  I am AUTHENTICATED`);
  
            const authToken = await getAccessTokenSilently({
              // authorizationParams: {
              //   audience: 'https://sbhc-api', // Value in Identifier field for the API being called.
              //   // scope: 'whatever', // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
              // }
            });
  
            LOG.debug(`ensureWhoamiSvcIsUpToDate():  authToken = ${authToken}`)
            // const parsed = parseJwt(authToken)
            // LOG.debug(`ensureWhoamiSvcIsUpToDate():  parsed = ${JSON.stringify(parsed)}`)
  
            whoAmIWriteService.ensureAuthenticatedUserDetailsAreUpToDate({
              authenticatedFlag: true,
              authToken: authToken,
              userId: user?.sub,
              email: user?.email,
              name: user?.name,
              nickname: user?.nickname
            })
        } else {
          LOG.debug(`ensureWhoamiSvcIsUpToDate():  I remain NOT authenticated`);
          whoAmIWriteService.ensureAuthenticatedUserDetailsAreUpToDate({
            authenticatedFlag: false,
            authToken: undefined,
            userId: undefined,
            email: undefined,
            name: undefined,
            nickname: undefined
          })
        }
      }
      catch (error) {
        LOG.error(`ensureWhoamiSvcIsUpToDate():  error = ${(error)}`, error);
      }
    }


    await ensureWhoamiSvcIsUpToDate()
    LOG.debug(`useEffect(): whoAmISvc.amILoggedIn() = ${d4l(whoAmISvc.amILoggedIn())},  currentUserSpecifiedSignatureDate = ${d4l(currentUserSpecifiedSignatureDate)}`);

    let goWhere = null

    if (whoAmISvc.amILoggedIn()) {
      if (stringUtil.isBlank(currentUserSpecifiedSignatureDate)) {
        goWhere = "/agreement"
        // navigate(`/agreement`, { replace: true });
      } else {
        // navigate(`/home`, { replace: true });
        goWhere = "/"
      }
    } else {
      // navigate(`/home`, { replace: true });
      goWhere = "/"
    }


    LOG.debug(`redirectMeAsNeeded(): current location = ${location}`)

    LOG.debug(`redirectMeAsNeeded(): current location = ${d4l(location)},  goWhere = ${d4l(goWhere)},  autoPerform = ${d4l(autoPerform)}`)

    if (autoPerform && stringUtil.isPresent(goWhere)) {
      if (goWhere === location.pathname) {
        LOG.debug(`redirectMeAsNeeded(): Already where I want to go.  goWhere = ${d4l(goWhere)},  location.pathname = ${d4l(location.pathname)}`)
      } else {
        navigate(goWhere, { replace: true });
      }
    }

    finishedRunningThroughLogic(goWhere)
  }

  useEffect(() => {
    redirectMeAsNeeded()    
  }, [getAccessTokenSilently, user?.sub]);

  return (
    <>
    </>
  )
}