import safeStringify from '../string/safeStringify'
import React, { useState, useReducer, useEffect } from 'react'
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import { getLogger } from '../log/getLogger'
import { StateScalarAttribute } from '../commonSrc/questionnaire/allQuestionnaireAttributes';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { setOneToTenValue, selectOneToTenValue } from '../questionnaire/questionnaireSlice';

const log = getLogger('question.OneToTenQuestionComponent')

export default function OneToTenQuestionComponent (props: any) {
  const percentComplete: string = `${props.stepNumber / 15 * 100}%`
  const label: string = props.label  // Nausea
  const attrName: string = props.attrName // everNausea
  const onDiagnosticAnswersChanged = props.onDiagnosticAnswersChanged
  log.debug(`props = ${JSON.stringify(props)}`)
  log.debug(`label = ${label}`)

  const dispatch = useDispatch()




  const onChange = async (event: React.ChangeEvent<HTMLInputElement> | any): Promise<void> => {
    log.debug(`onChange() - Entering with event.target.value = ${event.target.value} (${typeof event.target.value}),  event = ${safeStringify(event)}`)
    dispatch(setOneToTenValue({eventValue: event.target.value as any, attrName: attrName}))
    // dispatch(setUpperLowerBoth({eventValue: event.target.value, attrName: attrName}))
    onDiagnosticAnswersChanged()
  }

  const currentValue: StateScalarAttribute<number> = useSelector((state: SbhcCompositeState) => {
    return selectOneToTenValue(attrName, state)
  })

  return (
    <>
      <div className="one-to-ten-question-component">
        <fieldset>
          <label>{label}</label>
          <input
            type="range" className="form-range no-border" min="0" max="10" name={attrName} value={currentValue}
                  // onChange={(event) => dispatch({ type: "ON_CHANGE", payload: event.target.value })
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event)}
          />
        </fieldset> 
      </div>
    </>
  )
}