import { QuestionnaireSliceOfState } from "../questionnaire/QuestionnaireSliceOfState"

// export type TThunkApi = any
export interface TThunkApi {
  getState: () => any
}

export type TUndefinedParams = undefined

// export interface TReduxAction {
//   type: string;
//   payload?: any
// }

export type TReduxAction = any

// TODO: which one?
// export type Null ishableString = string | undefined | null
// export type UndefinableString = string | undefined
// export type NullableString = string | null
// export type TReact InputValue = string | number | readonly string[] | undefined
export const DefaultReactInputValue = '' 

// export type TStringSelector = string | undefined
// export type TStringSelector = string | null
// export type TStringSelector = string | undefined | null


export interface TypedReduxAction<T> {
  type: string;
  payload?: T
}

export type StateScalarAttribute<T> = T

export type QuestionnaireAnswersDataFromTheBackend = QuestionnaireSliceOfState

// camelCase
export interface FetchDiagnosticAnswersResponse {
  data?: QuestionnaireAnswersDataFromTheBackend
}

export interface SaveDiagnosticAnswersResponse {
  data?: {
    id: string /* canonical UUID */ 
  }
}

export type ClearMyQuestionnaireDangerWillRobinsonResponse = any
export type FetchInitialHypothesisResponse = any
export type ClearMyFeedbackDangerWillRobinsonResponse = any
export type UnsubmitDangerWillRobinsonResponse = any
export type ClearMyUserAttributesDangerWillRobinsonResponse = any
export type ClearMyUserAttributesResponse = any


export interface SbUser {
  username: string 
}

export interface UserAttributesSliceOfState {
   insuranceCompanyName: string,
   insurancePolicyholderName: string,
   insuranceMemberId: string,
   insuranceGroupNumber: string,
   medication: string,
   signature: string,

   // yep, string
   userSpecifiedSignatureDate: string

   legalName: string
}

export const SIGNATURE_DATE_USER_ATTRIBUTE_KEY = 'userSpecifiedSignatureDate'

export interface ExistingUserAttributesResponse {
   data?: UserAttributesSliceOfState
}


export enum AllAttrEnum { 
  // Step 2
  everNausea, 
  everVomiting, 
  everAbdominalPain, 
  everBloating,
  everBurping,
  everDyspepsia,
  everFlatulence,
   everAbdominalDistension,
  everRoiling,

  // Step 3
  hasDifficultyOrPainSwallowing,
  everRegurgitation,
  


     // TODO: Should these be nullable?  nullable?  empty string?  Defaulted to?
  // TODO: should these first few be numbers?
  heightInInches,
  weightInLbs,
  ageInYears,
  //    everNausea,
  // everVomiting,
  // everAbdominalPain,
  abdominalPainHowOften,
  abdominalPainWhere,
  // everBloating,
  // everBurping,
  // everDyspepsia,
  // everFlatulence,
  // everAbdominalDistension,
     hasConstipation,
  hasDiarrhea,
  hasSteatorrhea,
   asnRelatedToBowelMovement,
  asnRelievedByBowelMovement,
  asnRelievedOrRelatedToDefecation,
  hasBowelMovementFrequencyChangedLast6Months,
  bowelMovementHowOften,
  hasNarrowingStoolLast6Months,
  // currentStoolTypeNumber,
  hasPoopTypeSeparateHardLumps,
  hasPoopTypeSausageShapedButLumpy,
  hasPoopTypeSausageWithCracks,
  hasPoopTypeSnakeSmooth,
  hasPoopTypeFluffyRaggedMushy,
  hasPoopTypeWatery,
  hasPoopTypeSoftBlobsClearEdges,
    hasMidnightDiarrhea,
  hasStrainingBowelMovement,
  hasHardOrLumpyStools,
  hasSensationIncompleteBowelMovement,
  hasSensationOfAnorectalBlockageOrObstruction,
  hasFewerThan3SpontaneousBowelMovementsWeekly,
  fsrLooseStools,
  hasManeuversToAchieveBowelMovement,
  bowelTroubleHowOften,
  hasCurrentlyBloodInStool,
     everUnexpectedWeightLoss10In3, 
  everExcessiveWeightGain, 
  everWaterRetention, 
  // everCravingCertainFoods, 
  // everFoodAddiction, 
  hasSymptomsWorsenWithGlutenIngestion, 
  hasEarlyFullnessDuringMeal,
  earlyFullnessSymptomsHowLong,
  hasEpigastricPainOrBurning,
  epigastricPainOrBurningHowLong,
     stressLevel1To10, 
  anxietyLevel1To10, 
  // everAngerIrritability, 
  // everMoodSwings, 
  everDepression, 
     everSluggishness, 
  everRestlessness, 
  everBrainFog, 
  everSleepDisturbances, 
  // everHyperactivity, 
     // everAcne, 
  everFlushing, 
  everHivesRashes, 
  everExcessiveSweating, 
  everHairLoss,  
  everEasyBruising,   
     everStuffyNose, 
  everSinusProblems, 
  everHayFever, 
     everWateryOrItchyEyes, 
  everBagsOrDarkCirclesUnderEyes, 
  everSwollenRedOrStickyEyelids, 
  everBlurredVision, 
  //    hasDifficultyOrPainSwallowing, 
  // everRegurgitation,
  everSourOrBitterTaste,
   hasWorseLargeMeals, 
  hasWorseLayingDown,
  hasWorseAcidic,
   hasReliefTums, 
  hasReliefTolaids,
  hasReliefMylanta,
  hasReliefH2Blocker,
   hasReliefProtonPumpInhibitor,
   regularlyConsumeChicoryRootOrInulin,
   regularlyConsumeSugarAlcohols,
   everLiquidFromBottomToiletOrNot,
   bestDescriptionAfterBowelMovement,
   whatBothersYouMost,
  hasEsophogealCancer,
   everFrequentCoughing, 
  everSwollenAndOrOrDiscoloredTongue, 
  everFrequentNeedToClearThroat, 
  everCankerSores, 
  everExcessiveMucus, 
  everHorseness, 
  everTonguePain, 
  hasHiatalHernia, 
     everItchyEars, 
  everDrainageFromEarOrWaxyBuildup, 
  everEarAchesOrInfections, 
  everRingingInEars, 
     everHeadaches, 
  everFaintnessOrLightheadedness, 
  everDizziness, 
  // 
//   everPoorMemory, 
//   everDififficultyInMakingDecisions, 
//   everConfusion, 
//   everPoorConcentration, 
//   everSlurredSpeech, 
//   everPoorCoordination,  
// }
    everIrregularHeartbeat, 
  everRapidOrPoundingHeartbeat, 
  everChestPain, 
     everShortnessOfBreath, 
  everAsthmaBronchitis, 
  everDifficultyBreathing, 
  // Twice
  // everHyperactivity, 
     everPainsOrAchesInJoints, 
  // everPainsOrAchesInMuscles, 
  everArthritisJointSwelling, 
  // everFeelingOfWeaknessOrTired, 
  everStiffOrLimitationOfMovement, 
     hasFamilyHistoryOfGiCancer, 
  hasHypertension, 
  hasHyperlipidemia, 
  hasType2Diabetes, 
  hasType1Diabetes, 
  hasAlcoholUse, 
  hasOpiodUse, 
  hasBowelObstruction, 
  hasObesity, 
  hasRadiationToIntestines, 
  hasTerminalIlealResection, 
  hasIleocecalValveResection, 
  hasAmyloidosis, 
  hasScleroderma, 
  hasBowelStrictureOrAdhesions, 
  hasSmallBowelDiverticulosis, 
  hasAbdominalSurgeryHistory, 
  hasIleocectomyHistory, 
  hasCommonVariableImmunodeficiency, 
  hasIgaDeficency, 
  hasHivOrAids, 
  hasLongTermProtonPumpInhibiterUse, 
  hasAtrophicGastritis, 
  hasPancreaticDisease, 
  hasLiverDisease, 
  hasCholecystectomy, 
  // hasDysphagia, 
   // everRegurgitation, 
  // everSourOrBitterTaste, 
  // hasEsophogealCancer, 
   everFeversOrChills, 
  everLossOfAppetite, 
  // hasHiatalHernia, 
  everBoneFractureOrOsteoporosis, 
  hasFamilyHistoryOfCeliac, 
  hasThyroidDisease,   
     everFrequentIllness, 
  everFrequentUrgeToUrinate, 
  everGenitalItchOrDischarge, 
  // everExcessiveSweatingAgain, 
  hasHistoryOfAnemia, 
  hasPalpableMassOnAbdomen,   
  hasStructuralIssueStomachOrEsophagus,   
     abdominalPainHowLong, 
  hasPrimaryCareConversation, 
  countPrimaryCareConversations,
  hasGiDoctorConversation,
  countGiConversations,
  question_106CurrentlyBloodInStool, 
// everUnexpectedWeightLoss10In3, 
// everExcessiveWeightGain, 
// everWaterRetention, 
everCravingCertainFoods, 
everFoodAddiction, 
// hasSymptomsWorsenWithGlutenIngestion, 
stressLevel, 
// anxietyLevel1To10, 
everAngerIrritability, 
everMoodSwings, 
// everDepression, 
// everSluggishness, 
// everRestlessness, 
// everBrainFog, 
// everSleepDisturbances, 
everHyperactivity, 
everAcne, 
// everFlushing, 
// everHivesRashes, 
// everExcessiveSweating, 
// everHairLoss, 
// everFrequentIllness, 
// everFrequentUrgeToUrinate, 
// everGenitalItchOrDischarge, 
// everExcessiveSweatingAgain, 
// hasHistoryOfAnemia, 
// hasDifficultyOrPainSwallowing, 
// hasPalpableMassOnAbdomen, 
// everStuffyNose, 
// everSinusProblems, 
// everHayFever, 
// everWateryOrItchyEyes, 
// everBagsOrDarkCirclesUnderEyes, 
// everSwollenRedOrStickyEyelids, 
// everBlurredVision, 
// everFrequentCoughing, 
// everSwollenAndOrOrDiscoloredTongue, 
// everFrequentNeedToClearThroat, 
// everCankerSores, 
// everExcessiveMucus, 
// everHorseness, 
// everTonguePain, 
// everItchyEars, 
// everDrainageFromEarOrWaxyBuildup, 
// everEarAchesOrInfections, 
// everRingingInEars, 
// everHeadaches, 
// everFaintnessOrLightheadedness, 
// everDizziness, 
everPoorMemory, 
everDififficultyInMakingDecisions, 
everConfusion, 
everPoorConcentration, 
everSlurredSpeech, 
everPoorCoordination, 
// everIrregularHeartbeat, 
// everRapidOrPoundingHeartbeat, 
// everChestPain, 
// everShortnessOfBreath, 
// everAsthmaBronchitis, 
// everDifficultyBreathing, 
// everHyperactivity, 
// everPainsOrAchesInJoints, 
everPainsOrAchesInMuscles, 
// everArthritisJointSwelling, 
everFeelingOfWeaknessOrTired, 
// everStiffOrLimitationOfMovement, 
// hasFamilyHistoryOfGiCancer, 
// hasHypertension, 
// hasHyperlipidemia, 
// hasType2Diabetes, 
// hasType1Diabetes, 
// hasAlcoholUse, 
// hasOpiodUse, 
// hasBowelObstruction, 
// hasObesity, 
// hasRadiationToIntestines, 
// hasTerminalIlealResection, 
// hasIleocecalValveResection, 
// hasAmyloidosis, 
// hasScleroderma, 
// hasBowelStrictureOrAdhesions, 
// hasSmallBowelDiverticulosis, 
// hasAbdominalSurgeryHistory, 
// hasIleocectomyHistory, 
// hasCommonVariableImmunodeficiency, 
// hasIgaDeficency, 
// hasHivOrAids, 
// hasLongTermProtonPumpInhibiterUse, 
// hasAtrophicGastritis, 
// hasPancreaticDisease, 
// hasLiverDisease, 
// hasCholecystectomy, 
hasDysphagia, 
// everRegurgitation, 
// everSourOrBitterTaste, 
// hasEsophogealCancer, 
// everFeversOrChills, 
// everLossOfAppetite, 
// hasHiatalHernia, 
// everBoneFracture, 
// hasFamilyHistoryOfCeliac, 
// hasThyroidDisease, 

}  

export const PAGE_CODE_HOMEPAGE = ""
export const PAGE_CODE_PAID_SEARCH_LANDING = "g"

export type SubtypeObject = { predicate: boolean, nested: Array<AllAttrEnum> }