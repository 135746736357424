import { injectable } from "inversify";
import { ClearMyFeedbackDangerWillRobinsonResponse, ClearMyQuestionnaireDangerWillRobinsonResponse, QuestionnaireAnswersDataFromTheBackend, SaveDiagnosticAnswersResponse, UnsubmitDangerWillRobinsonResponse } from "../commonSrc/constant/type";
import { getLogger } from "../log/getLogger";
import { CurrentDiagnosticAnswerSaver } from "./CurrentDiagnosticAnswerSaver";
import diContainer from '../inversify.config'
import { WhoAmISvc } from "../auth/WhoAmISvc";
import DI_TYPES from "../diTypes";

const LOG = getLogger('CurrentDiagnosticAnswerSaverRealImpl')


@injectable()
export class CurrentDiagnosticAnswerSaverRealImpl implements CurrentDiagnosticAnswerSaver {
  
  public async save(diagnosticAnswersDataFromTheBackend: QuestionnaireAnswersDataFromTheBackend): Promise<SaveDiagnosticAnswersResponse> {
    LOG.debug(`save(): Entering with diagnosticAnswersDataFromTheBackend = ${JSON.stringify(diagnosticAnswersDataFromTheBackend)}`)
    
    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
    const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
    const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
    const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

    const headers: any = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    }

    if (whoAmISvc.amILoggedIn()) {
      headers["Authorization"] = `Bearer ${whoAmISvc.defGetMyAuthToken()}`
    } else {
      LOG.warn(`save(): Unauthorized 401.  User is NOT signed in.`)
    }

    const requestOptions = {
      method: 'POST',
      // mode: 'cors',
      headers: headers,
      body: JSON.stringify(diagnosticAnswersDataFromTheBackend)
    };
    
    const url = (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:9000') + `${process.env.REACT_APP_BACKEND_RELATIVE_API_PATH}` + '/questionnaire'
    LOG.debug(`save(): Sending POST to ${url}`)

    const response: Response = await fetch(url, requestOptions)
    const payload = await response.json();
    return payload as SaveDiagnosticAnswersResponse
  }

  public async clearMyQuestionnaireDangerWillRobinson(): Promise<ClearMyQuestionnaireDangerWillRobinsonResponse> {
    LOG.warn(`clearMyQuestionnaireDangerWillRobinson(): Entering`)
    
    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
    const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
    const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
    const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

    const headers: any = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    }

    if (whoAmISvc.amILoggedIn()) {
      headers["Authorization"] = `Bearer ${whoAmISvc.defGetMyAuthToken()}`
    } else {
      LOG.warn(`clearMyQuestionnaireDangerWillRobinson(): Unauthorized 401.  User is NOT signed in.`)
    }

    const requestOptions = {
      method: 'POST',
      // mode: 'cors',
      headers: headers,
      body: JSON.stringify({})
    };
    
    const url = (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:9000') + `${process.env.REACT_APP_BACKEND_RELATIVE_API_PATH}` + '/clearMyQuestionnaireDangerWillRobinson'
    LOG.debug(`clearMyQuestionnaireDangerWillRobinson(): Sending POST to ${url}`)

    const response: Response = await fetch(url, requestOptions)
    const payload = await response.json();
    return payload as ClearMyQuestionnaireDangerWillRobinsonResponse
  }

  public async clearMyFeedbackDangerWillRobinson(): Promise<ClearMyFeedbackDangerWillRobinsonResponse> {
    LOG.warn(`clearMyFeedbackDangerWillRobinson(): Entering`)
    
    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
    const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
    const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
    const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

    const headers: any = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    }

    if (whoAmISvc.amILoggedIn()) {
      headers["Authorization"] = `Bearer ${whoAmISvc.defGetMyAuthToken()}`
    } else {
      LOG.warn(`clearMyFeedbackDangerWillRobinson(): Unauthorized 401.  User is NOT signed in.`)
    }

    const requestOptions = {
      method: 'POST',
      // mode: 'cors',
      headers: headers,
      body: JSON.stringify({})
    };
    
    const url = (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:9000') + `${process.env.REACT_APP_BACKEND_RELATIVE_API_PATH}` + '/clearMyFeedbackDangerWillRobinson'
    LOG.debug(`clearMyFeedbackDangerWillRobinson(): Sending POST to ${url}`)

    const response: Response = await fetch(url, requestOptions)
    const payload = await response.json();
    return payload as ClearMyFeedbackDangerWillRobinsonResponse
  }



  public async unsubmitDangerWillRobinson(): Promise<UnsubmitDangerWillRobinsonResponse> {
    LOG.warn(`unsubmitDangerWillRobinson(): Entering`)
    
    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
    const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
    const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
    const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

    const headers: any = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    }

    if (whoAmISvc.amILoggedIn()) {
      headers["Authorization"] = `Bearer ${whoAmISvc.defGetMyAuthToken()}`
    } else {
      LOG.warn(`unsubmitDangerWillRobinson(): Unauthorized 401.  User is NOT signed in.`)
    }

    const requestOptions = {
      method: 'POST',
      // mode: 'cors',
      headers: headers,
      body: JSON.stringify({})
    };
    
    const url = (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:9000') + `${process.env.REACT_APP_BACKEND_RELATIVE_API_PATH}` + '/unsubmitDangerWillRobinson'
    LOG.debug(`unsubmitDangerWillRobinson(): Sending POST to ${url}`)

    const response: Response = await fetch(url, requestOptions)
    const payload = await response.json();
    return payload as UnsubmitDangerWillRobinsonResponse
  }
}