import { NextPageFlowFinderService } from "./NextPageFlowFinderService";
import { getLogger } from './log/getLogger'
import { inject, injectable } from "inversify";
import { WhoAmISvc } from "./auth/WhoAmISvc";
import diContainer from './inversify.config'
import DI_TYPES from "./diTypes";
import safeStringify from "./string/safeStringify";
import { PAGE_CODE_HOMEPAGE } from "./commonSrc/constant/type";
import { NextPageFlowDetail } from "./container/NextPageFlowDetail";
const LOG = getLogger('NextPageFlowFinderServiceImpl')


@injectable()
export class NextPageFlowFinderServiceImpl implements NextPageFlowFinderService {
  constructor(
    @inject(DI_TYPES.WhoAmISvc) private readonly whoAmISvc: WhoAmISvc
  ) {
    LOG.debug(`new()" Entering`)
  }
  
  /** These return codes */
  public shouldRedirectAndIfSoWhere(): NextPageFlowDetail | undefined {
    if (this.whoAmISvc.amILoggedIn()) {
      return new NextPageFlowDetail({ whatPageShouldIBeLookingAt: PAGE_CODE_HOMEPAGE })
    }
    return undefined
  }

  public whatPageAmILookingAt(): string {
    throw new Error("Not implemented yet")
  }
}