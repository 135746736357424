import safeStringify from '../string/safeStringify'
import React, { useState, useReducer, useEffect } from 'react'
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import { getLogger } from '../log/getLogger'
import { StateScalarAttribute } from '../commonSrc/questionnaire/allQuestionnaireAttributes';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { setEverString, selectEverString } from '../questionnaire/questionnaireSlice';
import { CurrentPreviousNeverCodeEnum, CurrentPreviousNeverLabelEnum } from '../commonSrc/constant/currentPreviousNeverConstant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { handleTooltipClick } from '../tooltip/tooltipUtil';

const log = getLogger('question.CurrentPreviousNeverQuestionComponent')

export default function CurrentPreviousNeverQuestionComponent (props: any) {
  const percentComplete: string = `${props.stepNumber / 15 * 100}%`
  const label: string = props.label  // Nausea
  const attrName: string = props.attrName // everNausea
  const tooltipText: string = props.tooltipText
  const onDiagnosticAnswersChanged = props.onDiagnosticAnswersChanged
  log.debug(`props = ${JSON.stringify(props)}`)
  log.debug(`label = ${label}`)

  const dispatch = useDispatch()


  const onChange = async (event: React.ChangeEvent<HTMLInputElement> | any): Promise<void> => {
    log.debug(`onChange() - Entering with event = ${safeStringify(event)}`)
    dispatch(setEverString({eventValue: event.target.value, attrName: attrName}))
    onDiagnosticAnswersChanged()
  }

  const currentValue: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => {
    return selectEverString(attrName, state)
  })

  const onClickTooltip = function(event: any): void {
    handleTooltipClick(event, attrName)
  }

  // const onCloseTooltip = function(event: any): void {
  //   event.preventDefault();
  //   document.getElementById("" + attrName.toString() + '-tip')!.style.display = 'none'
  // }

  return (
    <>
      <div className="current-previous-never-question-component">
        <fieldset>
          <label>
            {label}
            {tooltipText != null && tooltipText.length > 0 && <>&nbsp;<a onClick={onClickTooltip}><FontAwesomeIcon className="tooltip-click-me-icon" icon={faQuestionCircle}/></a></>}
          </label>
          <div id={attrName.toString() + "-tip"} className="tooltip-text" style={{display: 'none'}}>
            {tooltipText}
          </div>
          <div className="radio-group">
            <label>
              <input type='radio' name={attrName} value={CurrentPreviousNeverCodeEnum.CURRENT} checked={currentValue === CurrentPreviousNeverCodeEnum.CURRENT} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event)}/>
              <span>{CurrentPreviousNeverLabelEnum.CURRENT}</span>
            </label>
            <label>
              <input type='radio' name={attrName} value={CurrentPreviousNeverCodeEnum.PREVIOUS} checked={currentValue === CurrentPreviousNeverCodeEnum.PREVIOUS} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event)}/>
              <span>{CurrentPreviousNeverLabelEnum.PREVIOUS}</span>
            </label>
            <label>
              <input type='radio' name={attrName} value={CurrentPreviousNeverCodeEnum.NEVER} checked={currentValue === CurrentPreviousNeverCodeEnum.NEVER} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event)}/>
              <span>{CurrentPreviousNeverLabelEnum.NEVER}</span>
            </label>
          </div>
        </fieldset> 
      </div>
    </>
  )
}