import safeStringify from '../string/safeStringify'
import React, { useState, useReducer, useEffect } from 'react'
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import { getLogger } from '../log/getLogger'
import { StateScalarAttribute } from '../commonSrc/questionnaire/allQuestionnaireAttributes';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { setEverString, selectEverString, selectRawString } from '../questionnaire/questionnaireSlice';
import { CurrentPreviousNeverCodeEnum, CurrentPreviousNeverLabelEnum } from '../commonSrc/constant/currentPreviousNeverConstant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { handleTooltipClick } from '../tooltip/tooltipUtil';
import { OneToSixPlusMonthsCodeEnum, OneToSixPlusMonthsLabelEnum } from '../commonSrc/constant/oneToSixPlusMonthsConstant';
import * as cssConstant from '../constant/cssConstant'

const log = getLogger('question.ConditionalNestedQuestionComponent')

export default function ConditionalNestedQuestionComponent (props: any) {
  const conditional: boolean = props.conditional  
  const useContainer: boolean = props.useContainer  


  return (
    <>
      <div className={conditional ? '' : cssConstant.DISPLAY_NONE}>
        <div className="conditional-q">
          { 
            useContainer === true &&
            <div className="card">
              <div className="card-body">
                {props.children}
              </div>
            </div>
          }
          { 
            useContainer !== true &&
            <>{props.children}</>
          }
        </div>
      </div>
    </>
  )
}