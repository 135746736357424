import * as cookieUtil from '../commonSrc/cookie/cookieUtil';
import { useState } from 'react';
import { getLogger } from '../log/getLogger'
const LOG = getLogger('useProgressiveDisclosureMemory')



/**
 * Custom Hook
 * @returns
 */
export const useFirstNameMemory = () => {
  const getter = (): string => {
    let value = cookieUtil.getCookie("firstName")
    if (value != null) {
      value = atob(value)
    }
    LOG.debug(`useFirstNameMemory::getter() - Returning ${value}`)
    return value
  }
  const [rememberedFirstName, setRememberedFirstName] = useState(getter() || '');

  const updateFn = (value: string) => {
    setRememberedFirstName(value);
    document.cookie = `firstName=${btoa(value)}; expires=Thu, 18 Dec 2030 12:00:00 UTC; path=/; SameSite=Lax`;
  };

  // https://stackoverflow.com/questions/65680316/this-expression-is-not-callable-not-all-constituents-of-type-string-search
  return [rememberedFirstName, updateFn] as const;
};

export const useLastNameMemory = () => {
  const getter = (): string => {
    let value = cookieUtil.getCookie("lastName")
    if (value != null) {
      value = atob(value)
    }
    LOG.debug(`useLastNameMemory::getter() - Returning ${value}`)
    return value
  }
  const [rememberedLastName, setRememberedLastName] = useState(getter() || '');

  const updateFn = (value: string) => {
    setRememberedLastName(value);
    document.cookie = `lastName=${btoa(value)}; expires=Thu, 18 Dec 2030 12:00:00 UTC; path=/; SameSite=Lax`;
  };

  // https://stackoverflow.com/questions/65680316/this-expression-is-not-callable-not-all-constituents-of-type-string-search
  return [rememberedLastName, updateFn] as const;
};

export const useEmailMemory = () => {
  const getter = (): string => {
    let value = cookieUtil.getCookie("email")
    if (value != null) {
      value = atob(value)
    }
    LOG.debug(`useEmailMemory::getter() - Returning ${value}`)
    return value
  }
  const [rememberedEmail, setRememberedEmail] = useState(getter() || '');

  const updateFn = (value: string) => {
    setRememberedEmail(value);
    document.cookie = `email=${btoa(value)}; expires=Thu, 18 Dec 2030 12:00:00 UTC; path=/; SameSite=Lax`;
  };

  // https://stackoverflow.com/questions/65680316/this-expression-is-not-callable-not-all-constituents-of-type-string-search
  return [rememberedEmail, updateFn] as const;
};


export const usePhoneMemory = () => {
  const getter = (): string => {
    let value = cookieUtil.getCookie("phone")
    if (value != null) {
      value = atob(value)
    }
    LOG.debug(`usePhoneMemory::getter() - Returning ${value}`)
    return value
  }
  const [rememberedPhone, setRememberedPhone] = useState(getter() || '');

  const updateFn = (value: string) => {
    setRememberedPhone(value);
    document.cookie = `phone=${btoa(value)}; expires=Thu, 18 Dec 2030 12:00:00 UTC; path=/; SameSite=Lax`;
  };

  // https://stackoverflow.com/questions/65680316/this-expression-is-not-callable-not-all-constituents-of-type-string-search
  return [rememberedPhone, updateFn] as const;
};