export function StudyEnrollmentComplete () {




  return (
    <>
      <h1>Thanks</h1>

      <p>All done.  Thanks for submitting the enrollment forms.  Someone from SBHC will be in contact with you shortly (from our proton.me) account</p>
    </>
  )
}