
import diContainer from '../inversify.config'
import { WhoAmISvc } from "../auth/WhoAmISvc";
import DI_TYPES from "../diTypes";

export default function ProfileComponent () {
  const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
  const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
  const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
  const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

  return (
    <>
      <h1>Your Profile</h1>

      <div>loggedInFlag = {loggedInFlag ? 'TRUE' : 'FALSE'}</div>
      <div>oauth2Username = {oauth2Username}</div>
      <div>oauth2UserId = {oauth2UserId}</div>
    </>
  )
}