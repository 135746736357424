import safeStringify from '../string/safeStringify'
import React, { useState, useReducer, useEffect } from 'react'
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import { getLogger } from '../log/getLogger'
import { StateScalarAttribute } from '../commonSrc/questionnaire/allQuestionnaireAttributes';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { setEverString, selectEverString, selectRawString } from '../questionnaire/questionnaireSlice';
import { CurrentPreviousNeverCodeEnum, CurrentPreviousNeverLabelEnum } from '../commonSrc/constant/currentPreviousNeverConstant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { handleTooltipClick } from '../tooltip/tooltipUtil';
import { OneToSixPlusMonthsCodeEnum, OneToSixPlusMonthsLabelEnum } from '../commonSrc/constant/oneToSixPlusMonthsConstant';

const log = getLogger('question.OneToSixPlusMonthsQuestionComponent')

export default function OneToSixPlusMonthsQuestionComponent (props: any) {
  const label: string = props.label  // Nausea
  const attrName: string = props.attrName // everNausea
  const tooltipText: string = props.tooltipText
  const onDiagnosticAnswersChanged = props.onDiagnosticAnswersChanged

  const dispatch = useDispatch()


  const onChange = async (event: React.ChangeEvent<HTMLInputElement> | any): Promise<void> => {
    log.debug(`onChange() - Entering with event = ${safeStringify(event)}`)
    dispatch(setEverString({eventValue: event.target.value, attrName: attrName}))
    onDiagnosticAnswersChanged()
  }

  const currentValue: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => {
    return selectRawString(attrName, state)
  })

  const onClickTooltip = function(event: any): void {
    handleTooltipClick(event, attrName)
  }

  // const onCloseTooltip = function(event: any): void {
  //   event.preventDefault();
  //   document.getElementById("" + attrName.toString() + '-tip')!.style.display = 'none'
  // }

  return (
    <>
      <div className="one-to-six-plus-months-question-component">
        <fieldset>
          <label>
            {label}
            {tooltipText != null && tooltipText.length > 0 && <>&nbsp;<a onClick={onClickTooltip}><FontAwesomeIcon className="tooltip-click-me-icon" icon={faQuestionCircle}/></a></>}
          </label>
          <div id={attrName.toString() + "-tip"} className="tooltip-content" style={{display: 'none'}}>
            {tooltipText}
          </div>
          <div className="radio-group">
            <label>
              <input type='radio' name={attrName} value={OneToSixPlusMonthsCodeEnum.ONE_MONTH} checked={currentValue === OneToSixPlusMonthsCodeEnum.ONE_MONTH} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event)}/>
              <span>{OneToSixPlusMonthsLabelEnum.ONE_MONTH}</span>
            </label>
            <label>
              <input type='radio' name={attrName} value={OneToSixPlusMonthsCodeEnum.THREE_MONTHS} checked={currentValue === OneToSixPlusMonthsCodeEnum.THREE_MONTHS} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event)}/>
              <span>{OneToSixPlusMonthsLabelEnum.THREE_MONTHS}</span>
            </label>
            <label>
              <input type='radio' name={attrName} value={OneToSixPlusMonthsCodeEnum.SIX_PLUS_MONTHS} checked={currentValue === OneToSixPlusMonthsCodeEnum.SIX_PLUS_MONTHS} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChange(event)}/>
              <span>{OneToSixPlusMonthsLabelEnum.SIX_PLUS_MONTHS}</span>
            </label>
          </div>
        </fieldset> 
      </div>
    </>
  )
}