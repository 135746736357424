
import { useSearchParams } from "react-router-dom";
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useLocation, Location } from "react-router-dom";
import diContainer from '../inversify.config'
import { WhoAmISvc } from "../auth/WhoAmISvc";
import DI_TYPES from "../diTypes";
import { SignInSvc } from "../auth/SignInSvc";
import { setIdToken } from "../auth/authenticationSlice";
import { getLogger } from "../log/getLogger";
import { fetchExistingDiagnosticAnswers } from '../questionnaire/questionnaireSlice'
import store from '../store'
import { Link } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { WhoAmIWriteService } from "./WhoAmIWriteService";
import { DeveloperAuthDetailComponent } from "../developer/DeveloperAuthDetailComponent";
import { useNavigate } from "react-router";
import { CombinedState, Dispatch } from 'redux'
import { setSignature, selectSignature } from '../user/userAttributesSlice'
import { setUserSpecifiedSignatureDate, selectUserSpecifiedSignatureDate } from '../user/userAttributesSlice'
import { useDispatch, useSelector } from 'react-redux';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import * as stringUtil from "../commonSrc/string/stringUtil"

const LOG = getLogger('auth.Auth0AuthenticationCallbackLandingPage')

export default function Auth0AuthenticationCallbackLandingPage () {
  const signInSvc: SignInSvc = diContainer.get<SignInSvc>(DI_TYPES.SignInSvc)
  const whoAmIWriteService: WhoAmIWriteService = diContainer.get<WhoAmIWriteService>(DI_TYPES.WhoAmIWriteService)
  const navigate = useNavigate();

  const { user, isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth0();

  const currentSignatureInState: string = useSelector((state: SbhcCompositeState) => {
    return selectSignature(state)
  })
  
  const currentUserSpecifiedSignatureDate: string = useSelector((state: SbhcCompositeState) => {
    return selectUserSpecifiedSignatureDate(state)
  })
  // This component represents the /signin/callback page
  // {
  //   const location: Location = useLocation();
  //   LOG.debug(`location = ${location}`)
  //   const oauth2JwtToken: string | null | undefined = signInSvc.getOauth2IdToken(location)
  //   LOG.debug(`Will dispatch oauth2JwtToken = ${oauth2JwtToken}`)
    
  //   const dispatch = useDispatch()
  //   dispatch(setIdToken(oauth2JwtToken));
  //   // (store.dispatch as any)(fetchExistingDiagnosticAnswers)
  //   (store.dispatch as any)(fetchExistingDiagnosticAnswers)
  // }





  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }
  // if (error) {
  //   return <div>Oops... {error.message}</div>;
  // }


  const captureAuthToken = async () => {
    try {
      if (isAuthenticated) {
        LOG.debug(`captureAuthToken():  I am AUTHENTICATED`);

          const authToken = await getAccessTokenSilently({
            // authorizationParams: {
            //   audience: 'https://sbhc-api', // Value in Identifier field for the API being called.
            //   // scope: 'whatever', // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
            // }
          });

          LOG.debug(`captureAuthToken():  authToken = ${authToken}`)
          // const parsed = parseJwt(authToken)
          // LOG.debug(`captureAuthToken():  parsed = ${JSON.stringify(parsed)}`)

          whoAmIWriteService.ensureAuthenticatedUserDetailsAreUpToDate({
            authenticatedFlag: true,
            authToken: authToken,
            userId: user?.sub,
            email: user?.email,
            name: user?.name,
            nickname: user?.nickname
          })
      } else {
        LOG.debug(`captureAuthToken():  I remain NOT authenticated`);
        whoAmIWriteService.ensureAuthenticatedUserDetailsAreUpToDate({
          authenticatedFlag: false,
          authToken: undefined,
          userId: undefined,
          email: undefined,
          name: undefined,
          nickname: undefined
        })
      }
    }
    catch (error) {
      LOG.error(`captureAuthToken():  error = ${(error)}`, error);
    }
  }


  useEffect(() => {
    loginHeavyLift()
  }, [getAccessTokenSilently, user?.sub])


  const loginHeavyLift = async function() {
    LOG.debug(`Auth0AuthenticationCallbackLandingPage()::useEffect:  Entering`);

    await captureAuthToken()
    // setTimeout( () ={
    //   captureAuthToken()
    // }, 2000)
    
    if (whoAmISvc.amILoggedIn()) {
      LOG.debug(`loginHeavyLift(): I am AUTHENTICATED.`)
      /**
       * I just logged in.   Bounce me somewhere?
       * 
       */
      
       // navigate(`/questionnaire`, { replace: true });
      if (stringUtil.isBlank(currentUserSpecifiedSignatureDate)) {
        navigate(`/a`, { replace: true });
      }
    } else {
      LOG.debug(`loginHeavyLift(): NOT authenticated.`)
    }
  };








  const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
  const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
  let oauth2Username: string | undefined = whoAmISvc.getMyUsername()
  const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

  let pendingRedirectTimeout: any;

  // setTimeout( () => {
  //   window.location.href = "/"
  // }, 700);

  // useEffect(() => {
  //   // window.location.href = window.location.origin  
  //   oauth2Username = whoAmISvc.getMyUsername()
  // }, []);

  useEffect(() => {
    pendingRedirectTimeout = setTimeout(()=> {
      navigate(`/`, { replace: true });
    }, 3000)  
  }, []);

  const cancelPendingRedirectTimer = function() {
    clearTimeout(pendingRedirectTimeout);
  }

  return (
    <>
      <div><a onClick={cancelPendingRedirectTimer}><div className="loading">Completing sign-in</div></a></div>

      {/* <br/><br/><br/><br/>

      <div className="card">
        <div className="card-body">
          <DeveloperAuthDetailComponent/>
        </div>
      </div> */}
    </>
  )
}