import React from 'react'
import ReactDOM from 'react-dom'
import './custom.scss';
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider as ReduxProvider } from 'react-redux'
import { Provider as InverifyProvider } from 'inversify-react';
import store from './store'
import { AnyAction } from 'redux';
import diContainer from './inversify.config'
import { fetchExistingDiagnosticAnswers } from './questionnaire/questionnaireSlice'
import { fetchExistingUserAttributes } from './user/userAttributesSlice'
import { CombinedState, Dispatch } from 'redux'
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { WhoAmISvc } from './auth/WhoAmISvc';
import { useAuth0 } from "@auth0/auth0-react"
import DI_TYPES from './diTypes';
import { getLogger } from './log/getLogger'
const LOG = getLogger('index.tsx')


// https://redux.js.org/tutorials/fundamentals/part-6-async-logic
// store.dispatch((dispatch: any, getState: any) => fetchExistingDiagnosticAnswers(dispatch, getState))
// store.dispatch((...: any[]) => fetchExistingDiagnosticAnswers(...))
// https://redux.js.org/tutorials/fundamentals/part-6-async-logic
// We only want to make this API call once, when the application loads for the first time. There's a few places we could put this:
// https://stackoverflow.com/questions/54371096/redux-thunk-property-type-missing-when-calling-action-through-store-dispatch
// https://stackoverflow.com/questions/43013204/how-to-dispatch-an-action-or-a-thunkaction-in-typescript-with-redux-thunk
// https://stackoverflow.com/questions/62238338/how-do-i-resolve-property-type-is-missing-in-type-asyncthunkaction-using-re
// const dispatch : AppDispatch = useDispatch();

// const { user, isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth0();
const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc);


// (store.dispatch as any)(fetchExistingDiagnosticAnswers)
(store.dispatch as any)((dispatch: Dispatch /* TODO: AppDispatch vs Dispatch */, getState: any) => {
  if (whoAmISvc.amILoggedIn()) {
    LOG.debug(`store.dispatch: preparing to call fetchExistingDiagnosticAnswers...`)
    fetchExistingUserAttributes(dispatch, getState)
    fetchExistingDiagnosticAnswers(dispatch, getState)
  } else {
    LOG.debug(`store.dispatch: NOT logged in.  Will NOT call fetchExistingDiagnosticAnswers...`)
  }
})
// (store.dispatch as any)(fetchExistingDiagnosticAnswers, fetchExistingUserAttributes)
// (store.dispatch as any)(fetchExistingUserAttributes)


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
        useCookiesForTransactions={true} 
        // passwordlessMethod='link'
        authorizationParams={{
          // redirect_uri: window.location.origin,
          // redirect_uri: '/signin/auth0/callback',
          // useCookiesForTransactions: true,
          redirect_uri: process.env.REACT_APP_AUTH0_SUCCESS_REDIRECT_URL as string,
          audience:  process.env.REACT_APP_AUTH0_AUDIENCE as string, // Value in Identifier field for the API being called.
          scope: 'openid profile email'
        }}
      >
        <InverifyProvider container={diContainer}>
          <ReduxProvider store={store}>
            <App />
          </ReduxProvider>
        </InverifyProvider>
      </Auth0Provider>  
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()



