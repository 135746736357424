// CREATE TYPE frequently_sometimes_rarely AS ENUM ('frequently', 'sometimes', 'rarely');
export enum FrequentlySometimesRarelyCodeEnum { 
  UNSET = "unset", 
  FREQUENTLY = "frequently", 
  SOMETIMES = "sometimes", 
  RARELY = "rarely", 
}  

export enum FrequentlySometimesRarelyLabelEnum { 
  UNSET = "Choose", 
  FREQUENTLY = "Frequently", 
  SOMETIMES = "Sometimes", 
  RARELY = "Rarely", 
}  