import { getLogger } from '../log/getLogger'
import React, { useEffect, useState } from "react";
import { WhoAmISvc } from '../auth/WhoAmISvc';
import diContainer from '../inversify.config'
import DI_TYPES from "../diTypes";
import * as bearerUtil from '../commonSrc/auth/bearerUtil'
import { setMedication, selectMedication } from '../user/userAttributesSlice'
import safeStringify from '../string/safeStringify';
import { useDispatch, useSelector } from 'react-redux';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { current } from '@reduxjs/toolkit';

const LOG = getLogger('term.TermsAndConditionsComponent')


export default function TermsAndConditionsComponent () {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className="container-fluid text-section">
    <h1>Terms of Service</h1>
    <p>Last Updated: 6/18/22<br/><br/> These Terms of Service
  (“Terms”) apply to and govern your access to and use of any website, mobile website, social media site, software,
  email exchange of information, and any other digital platform, including any services, features, pages, and functions
  contained or offered therein, including any transactions, orders, sales, purchases, or the acquisition of goods or
  services (collectively the “Service”), that are owned, operated, or provided by a Second Brain Healthcare, Inc., or its
  subsidiaries, divisions, and affiliates (“Visana”). <br/><br/>Visana offers the Service, including all information,
  tools, services, goods, and products available through the Service such as the Visana mobile application, exercise
  tracking, symptom tracking, disease education, and support by Second Brain Healthcare’s coaching staff, to you conditioned upon
  your acceptance of all terms, conditions, polices, and notices stated herein or incorporated by reference. Please read
  these Terms carefully before using our Service or making any transaction, order, or purchase. By making any
  transaction, order, or purchase, or by visiting or otherwise using the Service in any manner, you acknowledge and
  accept without limitation or qualification, that you have read and understood these Terms and you agree to be bound by
  them. You also acknowledge, agree, and consent to the terms of our <a
    href="https://www.visanahealth.com/policies/privacy">Privacy Policy</a> which is incorporated herein by reference.
  If for any reason you do not accept and agree to these Terms or those set forth in the Privacy Policy, then accessing
  the Service is strictly prohibited and you must immediately exit.<br/><br/>By using the Service, you agree that you are
  at least the legal age of majority in the jurisdiction in which you reside or, if you are not, that you have obtained
  parental or guardian consent. The Service is not targeted for use by children under the age of eighteen, and if you
  are under the age of eighteen, you are not allowed to use the Service.&nbsp;<br/><br/>Visana may change these Terms at
  any time and at its sole discretion. Any changes to the Terms will be effective immediately upon posting on the
  Service. Your continued use of the Service after such posting will constitute your acceptance of and agreement to such
  changes. Therefore, you should frequently review these Terms of Use and the <a
    href="https://www.visanahealth.com/policies/privacy">Privacy Policy</a> to see if they have been changed.<br/><br/>If
  we make any material changes, and you have registered to use the Service, we will also send an email to you at the
  last email address you provided to us. Your continued use of the Service after our provision of notice to you will
  constitute your affirmative acceptance to the modified Terms. If you do not agree to, or cannot comply with, the Terms
  as amended, you must stop using the Service.<br/><br/>‍<strong>THESE TERMS AFFECT YOUR LEGAL RIGHTS, RESPONSIBILITIES,
    AND OBLIGATIONS, GOVERN YOUR USE OF THE SERVICE, ARE LEGALLY BINDING, LIMIT VISANA’S LIABILITY TO YOU, REQUIRE YOU
    TO INDEMNIFY VISANA, AND TO SETTLE CERTAIN DISPUTES THROUGH ARBITRATION. YOUR CONTINUED USE OF THE SERVICE AFFIRMS
    YOUR AGREEMENT TO THESE TERMS. IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS OR ANY FUTURE MODIFICATIONS OR
    AMENDMENTS TO THESE TERMS, DO NOT USE OUR SERVICE.<br/><br/>‍</strong>These Terms are written in the English language.
  We do not guarantee the accuracy of any translated versions of these Terms. To the extent any translated versions of
  these Terms conflict with the English language version, the English language version of these Terms shall
  control.&nbsp;<strong>VISANA IS NOT A MEDICAL PROVIDER AND DOES NOT PROVIDE EMERGENCY SERVICES OF ANY KIND. IF YOU ARE
    EXPERIENCING A MEDICAL EMERGENCY, YOU SHOULD DIAL “911” IMMEDIATELY.<br/><br/>‍</strong>You should not disregard or
  delay to seek medical advice based on anything that appears or does not appear on the Site. If you believe you have an
  emergency, call 9-1-1 immediately. You should seek emergency help or follow up care when recommended by a Provider or
  when otherwise needed. You should continue to consult with your primary provider and other healthcare professionals as
  recommended. Always seek the advice of a physician or other qualified healthcare provider concerning questions you
  have regarding a medical condition and before stopping, starting, or modifying any treatment or
  modification.<br/><br/>‍<strong>MEDICAL SERVICES AND RELATED ADVICE&nbsp;<br/><br/>‍</strong>VISANA DOES NOT PROVIDE
  MEDICAL ADVICE, MEDICAL SERVICES, OR MEDICAL CARE. Visana contracts with VH Medical Group (DE), P.A., (“VH Medical
  Group”) an independent, physician-owned medical group with a network of United States based Providers who provide
  clinical telehealth services. VH Medical Group Providers deliver clinical services via the Visana platform to their
  patients. We offer an online communication platform for these Providers and their patients to connect via the Site
  through the use of synchronous and asynchronous telecommunications technologies. The Site facilitates communication
  between patients and Providers. As set forth below, some of these communications may be by secure video,
  interactive-audio, text messages or other communicative mediums that may be insecure, and you specifically acknowledge
  and consent to the utilization of communicative mediums that may be less secure or vulnerable to unauthorized access
  associated with the Service.<br/><br/>Providers are independent of Visana and merely use our Site to communicate with
  you. Visana does not employ or contract with Providers. Providers are independently contracted or employed by VH
  Medical Group. Thus, Providers, and not Visana, are responsible for the quality and appropriateness of the care they
  render to you, as well as for the security and integrity of any communicative mediums used with you. <br/><br/>Neither
  Visana, nor any of its subsidiaries or affiliates or any third party who may promote the Site or Service or provide a
  link to the Service, shall be liable for any professional advice obtained from a Provider via the Site or Service or
  for any information obtained from the Site. Visana does not recommend or endorse any specific Providers, tests,
  physicians, medications, products, or procedures. By using our Service, you acknowledge and accept that your reliance
  on any Providers or information delivered by the Providers via the Site or Service is solely at your own risk, and you
  assume full responsibility for all risks associated herewith.<br/><br/>Visana does not make any representations or
  warranties about the training or skill of any Providers who deliver services via the Site or Service, or the security
  of any communicative mediums provided by Visana. You will be provided with available Providers based solely on the
  information you submit to the Site. You are ultimately responsible for choosing your particular Provider. <br/><br/>The
  content of the Site and the Service, including without limitation, text, copy, audio, video, photographs,
  illustrations, graphics and other visuals, is for informational purposes only and does not constitute professional
  medical advice, diagnosis, treatment, or recommendations of any kind by Visana. You should always seek the advice of
  your qualified health care professionals with any questions or concerns you may have regarding your individual needs
  and any medical conditions. All information provided by Visana, or in connection with any communications supported by
  Visana, is intended to be for general information purposes only, and is in no way intended to create a
  provider-patient relationship as defined by state or federal law. While Visana facilitates your selection of, and
  communications with, Providers, Visana does not provide medical services, and the doctor-patient relationship is
  between you and the VH Medical Group Provider you select. &nbsp;<br/><br/>‍<strong><em>Risks of Telehealth
      Services</em></strong> <br/>‍<br/>By using our Service, you acknowledge the potential risks associated with
  telehealth services. These risks include but are not limited to the following:&nbsp;Information transmitted may not be
  sufficient—for example, because of poor resolution of images—to allow for appropriate medical or health care decision
  making by the Provider;&nbsp;Delays in evaluation or treatment could occur due to failures of electronic
  equipment;&nbsp;A lack of access to your medical records may result in adverse drug interactions or allergic reactions
  or other judgment errors; and although the electronic systems we use incorporate network and software security
  protocols to protect the privacy and security of health information, those protocols could fail, causing a breach of
  privacy of your health information, and certain mediums of communication used to communicate with you- such as text
  messages- may be less secure than others that are commercially available. <br/><br/>‍<strong>Ownership of
    Service<br/><br/>‍</strong>The Service and all of its content, including without limitation all copyrights, patents,
  trademarks, service marks, and trade names, as well as all logos, text, design, graphics, logos, icons, images, audio
  clips, downloads, interfaces, code and software, as well as the selection and arrangement thereof, and all other
  intellectual property (collectively referred to as the “Content”), are all proprietary and owned or controlled by
  Visana, our licensors, and certain other third parties. All right, title, and interest in and to the Content available
  via the Service is the exclusive property of and owned by Visana, our licensors or certain other third parties, and is
  protected by United States and international copyright, trademark, trade dress, patent or other intellectual property
  laws to the fullest extent possible.<br/><br/>A limited, revocable, nontransferable license is granted to temporarily
  download one copy of the intellectual property displayed via the Service for personal, non-commercial transitory
  viewing only. This is not a transfer of title, right, or interest in the Service or Content. The license does not give
  you the right to, and you are strictly prohibited from, copying the Content, modifying the Content, using the Content
  for any commercial purpose, publicly displaying the Content, attempting to decompile or reverse engineer the Content,
  removing any copyright, trademark, or other proprietary notations from the Content, or otherwise infringing upon the
  intellectual property rights of Visana or its licensors. This license shall automatically terminate if you violate any
  of these restrictions, or any provision of the Terms, and may be terminated by Visana at any time for any or no
  reason. Upon termination of this license, you must destroy any downloaded materials in your possession whether in
  electronic or printed format. Except as expressly provided in these Terms, no assignments or license of intellectual
  property are granted by Visana.<br/><br/>You may use the Service only for lawful purposes and shall not: (1) send spam
  or otherwise duplicative or unsolicited messages in violation of applicable laws; (2) send or store infringing,
  obscene, threatening, libelous, or otherwise unlawful or tortious material, including material harmful to children or
  violative of third party privacy rights; (3) send or store material containing software viruses, worms, Trojan horses
  or other harmful computer code, files, scripts, agents or programs; (4) interfere with or disrupt the integrity or
  performance of the Service or its related systems or networks. We reserve the right to determine whether or not your
  use of the Service is acceptable and to immediately revoke your access to the Service at our sole
  discretion.<br/><br/>Visana owns and uses several trademarks on the Service, including but not limited to VISANA and
  related logos. All rights are reserved by Visana. All other trademarks, brands, and names are the property of their
  respective owners. Nothing contained in the Service should be construed as granting any license or right to use any
  trademark displayed on this site without the express written permission of Visana or such third-party that may own the
  trademark.<br/><br/>Some features of the Service may now or in the future allow you to provide content, such as written
  comments or reviews, to be published or displayed on public areas of the Service (“User Content”). All content
  submitted by you to the Service may be retained by us indefinitely, even after you terminate your account. By
  submitting any User Content, you grant to Visana a perpetual, worldwide, irrevocable, non-exclusive, royalty-free
  license to use the User Content in any manner, including to create derivative works, without any compensation or
  notice to you. Your User Content may be posted and transmitted to others at your own risk. We cannot control the
  actions of other users of the Service with whom you may choose to share your User Content.<br/><br/>‍<strong>Mobile
    Application License<br/><br/>‍</strong>&nbsp;If you access the Service via a mobile application, then we grant you a
  revocable, non-exclusive, non-transferable, limited right to install and use the mobile application on wireless
  electronic devices owned or controlled by you, and to access and use the mobile application on such devices strictly
  in accordance with these Terms.<br/><br/>You agree you will not (1) decompile, reverse engineer, disassemble, attempt to
  derive the source code of, or decrypt the application; (2) make any modification, adaptation, improvement,
  enhancement, translation, or derivative work from the application; (3) violate any applicable laws, rules, or
  regulations in connection with your access or use of the application; (4) remove, alter, or obscure any proprietary
  notice (including any notice of copyright or trademark) posted by us or any other licensors of the application; (5)
  use the application for any revenue generating endeavor, commercial enterprise, or other purpose for which it is not
  designed or intended; (6) make the application available over a network or other environment permitting access or use
  by multiple devices or users at the same time; (7) use the application for creating a product, service, or software
  that is, directly or indirectly, competitive with or in any way a substitute for the application; (8) use the
  application to send automated queries to any website or to send any unsolicited commercial email; or (9) use any
  proprietary information or any of our interfaces or our other intellectual property in the design, development,
  manufacture, licensing, or distribution of any applications, accessories, or devices for use with the
  application.<br/><br/>‍<strong>Email Messaging<br/><br/>‍</strong>The Services also offers access to messages via email
  alerts.&nbsp; You acknowledge that alerts will be sent to the email address you provide to Second Brain Healthcare. Such alerts
  may include protected health information (PHI) based on your account preferences, and whoever has access to the email
  address will also be able to see this information. You acknowledge that if you select to receive PHI via e-mail alert,
  you may receive such information through an unencrypted method of communication and that information contained in an
  unencrypted e-mail is at risk of being intercepted and read by, or disclosed to, unauthorized third parties, as your
  email service provider may not be a secure method of communication.<br/><br/>By enrolling to receive Second Brain Healthcare email
  alerts, you agree to these Terms of Service and <a href="https://www.visanahealth.com/policies/communication">Member
    Communication Policy</a>, which become effective upon your enrollment.<br/><br/>&nbsp;<strong>Disclaimer and
    Limitations of Liability<br/><br/>‍</strong>&nbsp;THE SERVICE AND ALL SERVICES, INFORMATION, GOODS, AND MATERIALS MADE
  AVAILABLE OR PURCHASED THROUGH THE SERVICE ARE PROVIDED TO YOU "AS IS" WITHOUT ANY EXPRESS REPRESENTATIONS OR
  WARRANTIES OF ANY KIND, AND WE DISCLAIM ALL STATUTORY OR IMPLIED REPRESENTATIONS, WARRANTIES, TERMS, AND CONDITIONS
  WITH RESPECT TO THE SERVICE AND ALL SERVICES, INFORMATION, GOODS, AND MATERIALS MADE AVAILABLE THROUGH THE SITE,
  INCLUDING THE REPRESENTATIONS AND WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR
  PURPOSE, NONINFRINGEMENT, AND TITLE. WE MAKE NO REPRESENTATION OR WARRANTY THAT THE SERVICE (OR ANY PART THEREOF) WILL
  BE ACCURATE, COMPLETE, OR ERROR-FREE, NOR THAT ANY PARTICULAR SOFTWARE OR HARDWARE, WILL BE COMPATIBLE WITH THE
  SERVICE, AND YOU HEREBY AGREE THAT IT IS YOUR SOLE RESPONSIBILITY TO (A) OBTAIN AND PAY FOR ANY SOFTWARE, HARDWARE AND
  SERVICES (INCLUDING INTERNET CONNECTIVITY) NEEDED TO ACCESS AND USE THE SERVICE AND (B) ENSURE THAT ANY SOFTWARE,
  HARDWARE, AND SERVICES THAT YOU USE WILL FUNCTION CORRECTLY WITH THE SERVICE. YOU AGREE THAT YOU MUST EVALUATE, AND
  THAT YOU BEAR ALL RISKS ASSOCIATED WITH, THE USE OF THE SERVICE, INCLUDING ANY RELIANCE ON THE ACCURACY, COMPLETENESS,
  OR USEFULNESS OF ANY INFORMATION OR MATERIALS MADE AVAILABLE THROUGH THE SERVICE.&nbsp;WE WILL NOT BE LIABLE FOR ANY
  INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES OF ANY KIND IN CONNECTION WITH THE
  SERVICE, NOR FOR ANY DAMAGES FOR LOSS OF PROFITS, LOSS OF USE, LOSS OF DATA, LOSS OF OTHER INTANGIBLES, LOSS OF
  SECURITY OF INFORMATION YOU HAVE PROVIDED IN CONNECTION WITH YOUR USE OF THE SERVICE, OR UNAUTHORIZED INTERCEPTION OF
  ANY SUCH INFORMATION BY THIRD PARTIES, EVEN IF ADVISED IN ADVANCE OF SUCH DAMAGES OR LOSSES. FURTHER, WE WILL NOT BE
  LIABLE FOR DAMAGES OF ANY KIND RESULTING FROM YOUR USE OF THE SERVICE OR FROM ANY INFORMATION OR MATERIALS ON THE
  SERVICE. YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE SERVICE IS TO STOP USING THE SERVICE. OUR MAXIMUM
  LIABILITY FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION,
  NEGLIGENCE), OR OTHERWISE SHALL BE THE TOTAL AMOUNT, IF ANY, PAID BY YOU TO US TO ACCESS AND USE THE SERVICE.&nbsp;IT
  IS POSSIBLE THAT APPLICABLE LAW MAY NOT ALLOW FOR LIMITATIONS ON CERTAIN IMPLIED WARRANTIES OR EXCLUSIONS OR
  LIMITATIONS OF CERTAIN DAMAGES; SOLELY TO THE EXTENT THAT SUCH LAW APPLIES TO YOU, SOME OR ALL OF THE ABOVE
  DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS. IF APPLICABLE LAW
  PROHIBITS THE LIMITATION OR EXCLUSION OF A PARTY’S LIABILITY WITH RESPECT TO DEATH OR PERSONAL INJURY CAUSED BY SUCH
  PARTY’S NEGLIGENCE, FRAUD, OR ANY OTHER MATTER, THEN SUCH PARTY’S LIABILITY WILL NOT BE LIMITED OR EXCLUDED TO THE
  EXTENT OF SUCH PROHIBITION UNDER SUCH APPLICABLE LAW.&nbsp;<br/><br/>If you are a California resident, you agree to
  consciously waive all claims, both known and unknown that may be later discovered, to the maximum extent permitted by
  law, including but not limited to that permitted by California Civil Code Section 1542, which states “[a] general
  release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her
  favor at the time of executing the release and that, if known by him or her, would have materially affected his or her
  settlement with the debtor or released party.”<br/><br/>‍<strong>Indemnification<br/><br/>‍</strong>Except to the extent
  prohibited under applicable law, you agree to indemnify, defend, and hold harmless Visana and its officers, directors,
  employees, and agents from and against any claims, losses, liabilities, damages, costs, or expenses, including
  attorneys’ fees and costs, that may arise from or in connection with (a) your use of, or activities in connection
  with, the Service, including use of any good, product, or service purchased through the Service, (b) violation of
  these Terms by you, and (c) the acts or omissions of any other User. If you fail to promptly indemnify and defend a
  covered claim, Visana shall have the right to defend itself, and in such case, you shall promptly reimburse Visana for
  all of its associated costs and expenses. Visana reserves the right, at its own expense, to assume the exclusive
  defense and control of any matter otherwise subject to your indemnification.<br/><br/>‍<strong>Account, Password, and
    Security<br/><br/>‍</strong>You may only create and hold one account for your personal use. You are the sole
  authorized user of your account and agree to provide true, accurate, current, and complete information about yourself
  in connection with your account. You are responsible for maintaining the security and confidentiality of any password
  and account number provided by you or Visana for accessing the Service. You are solely and fully responsible for all
  activities that occur under your password or account. Visana may assume that any communications it receives through
  your account have been made by you unless Visana receives notice otherwise. Visana expressly disclaims any liability
  from misuse of your account. Should you suspect that any unauthorized party may be using your password or account or
  you suspect any breach of security, contact Visana immediately at <a
    href="mailto:members@visanahealth.com">members@visanahealth.com</a>. You will not sell, transfer, or assign your
  account or any account rights. Accounts may only be set up by the individual that is the subject of the account, and
  who is of the age of majority. We generally do not review accounts for authenticity and are not responsible for any
  unauthorized accounts that may appear on the Service. For any dispute as to account creation or authenticity, we shall
  have the sole right, but are not obligated, to resolve such dispute as we determine appropriate in our sole and
  exclusive discretion, with or without notice.<br/><br/>YOU ARE SOLELY RESPONSIBLE FOR ALL ACCESS TO AND USE OF THE
  SERVICE BY ANYONE USING YOUR PASSWORD AND IDENTIFICATION WHETHER OR NOT SUCH ACCESS TO AND USE OF THE SERVICE IS
  ACTUALLY AUTHORIZED BY YOU, INCLUDING WITHOUT LIMITATION, ALL COMMUNICATIONS, TRANSMISSIONS, ORDERS, PAYMENTS,
  FINANCIAL TRANSACTIONS, AND OTHER OBLIGATIONS INCURRED THROUGH SUCH ACCESS OR USE.<br/><br/>‍<strong>Links to Other
    Websites<br/><br/>‍</strong>The Service may provide links to other third-party websites (“Linked Sites”). Visana has
  not reviewed all of the information on the Linked Sites, does not maintain Linked Sites, and cannot control the
  completeness, accuracy, or security of the content contained on any Linked Site. The content of, including materials
  and information contained on, any Linked Site is solely the responsibility of the provider of that Linked Site. Visana
  is not responsible for the contents of any such Linked Site, and the inclusion of any link to a Linked Site does not
  imply endorsement by Visana of the Linked Site. The views and opinions expressed in Linked Sites are those of the
  authors or third parties and do not necessarily reflect the official policy or position of Visana. If you decide to
  access any Linked Site, you do this entirely at your own risk. References to any names, marks, products, or services
  of any third parties, third-party information, or Linked Sites are provided solely as a convenience to you, and do not
  constitute or imply an endorsement, sponsorship, recommendation of, or affiliation with, the third party or its
  products and services. Visana makes no representation or warranty as to any Linked Site content, products, or
  services, and you agree that Visana shall not be responsible or liable, directly or indirectly, for any damage or loss
  caused or alleged to be caused by or in connection with use of or reliance on any such third-party content, products,
  or services available on or through any Linked Site or similar resource.<br/><br/>AS PERMITTED BY APPLICABLE LAW, WE
  SHALL UNDER NO CIRCUMSTANCES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL OR SPECIAL LOSS, OR OTHER DAMAGE, WHETHER
  ARISING FROM NEGLIGENCE, BREACH OF CONTRACT, DEFAMATION, INFRINGEMENT OF COPYRIGHT, OR OTHER INTELLECTUAL PROPERTY
  RIGHTS, CAUSED BY THE EXHIBITION, DISTRIBUTION, OR EXPLOITATION OF ANY INFORMATION OR CONTENT CONTAINED WITHIN THESE
  THIRD-PARTY SERVICES.<br/><br/>‍<strong>Content and Services Access through Visana<br/><br/>‍</strong>You may choose to
  allow a third-party service provider (such as a Personal Health Record or PHR) to retrieve, provide, modify or
  otherwise use health and other information in your account or otherwise share your information with the service
  provider. Once you enable a specific third-party service provider to access your account, the service provider may
  continue to access your account until you affirmatively disable access. Third-party service providers include both
  health care providers and other entities. It is your sole responsibility to review and approve each such third-party
  service before sharing your information through or otherwise accessing it.<br/><br/> USE OF THESE SERVICES AND RELIANCE
  ON THIS CONTENT IS SOLELY AT YOUR OWN RISK. VISANA HEALTH MAY NOT BE HELD LIABLE FOR ANY DAMAGES ARISING OUT OF OR
  RELATED TO YOUR USE OF ANY THIRD-PARTY SERVICE OR CONTENT.<br/><br/>‍<strong>Payment Terms and
    Fees<br/><br/>‍</strong>Certain features of the Service, including, without limitation, subscribing to a membership or
  the placing or receipt of service orders, may require you to make certain payments, including commissions or other
  fees. Any fees that Visana may charge you for your use of or access to the Service are due immediately when charged by
  Visana and are non-refundable. This no refund policy shall apply at all times regardless of your decision to terminate
  your usage, our decision to terminate your usage, disruption caused to our Service either planned, accidental or
  intentional, or any reason whatsoever. Visana reserves the right to determine and change any payment term, rate,
  mechanism, or structure at any time with notice to you. Visana, in its sole discretion, may offer credits or refunds
  on a case-by-case basis.Fees will be displayed in United States Dollars. By confirming your purchase at the end of the
  checkout process, you agree to accept and pay the fees as well as all applicable taxes and currency conversion
  fees.Visana may from time to time offer special promotions or offers. Promotional codes, discounts, and offers
  (“Promotions”) are limited in nature and may expire or be discontinued with or without notice. Promotions are void
  where prohibited by law. Promotions may not be copied, sold, or otherwise transferred. They are not redeemable for
  cash and are subject to cancellation or change at any time for any reason without notice. We reserve the right in our
  discretion to impose conditions on the offering of any Promotions. Other Promotions, including sweepstakes, contests,
  raffles, surveys, or games, may be governed by rules that are separate from or supplement these Terms. If you
  participate in any Promotions, please review the applicable rules. If the specific rules for a Promotion conflict with
  these Terms, the Promotion rules will govern.Visana may use third party services to process payments, such as Stripe,
  which have their own separate terms of service. While Visana will use commercially reasonable efforts to ensure the
  security of all payment information such as credit cards or bank account numbers, Visana expressly disclaims any
  liability for any damage that may result should any third parties gain access to such information, and you agree to
  hold Visana harmless for any damages that may result therefrom.You represent and warrant that you have the right to
  use any credit card or other means of payment that you provide to us. All billing information you provide to us must
  be current, complete, truthful, and accurate. You agree to promptly update your account and other information,
  including your email address and credit card numbers and expiration dates, so that we can complete your transactions
  and contact you as needed. Providing any untruthful or inaccurate information is a breach of these Terms and may
  result in cancellation of your order. Prior to accepting an order, we may also request additional information from
  you. Verification of information may be required prior to the acknowledgment or completion of any transaction. We
  reserve the right to refuse or cancel an order for any reason including limitations on availability, inaccuracies, or
  errors in service or pricing information, or problems identified by our credit and fraud avoidance department. If your
  order is canceled after your credit card (or other payment account) has been charged, we will issue a credit to your
  credit card (or other applicable payment account) in the amount of the charge. We will attempt to contact you if all
  or any portion of your order is canceled or if additional information is required to accept your order. Visana may
  request a pre-authorization for some orders placed online with a credit or debit card. This pre-authorization will not
  be billed to you; however, your card issuer may hold this amount for a short period. Your card issuer determines the
  length of time the pre-authorization is held. We do this to ensure that the card details are still valid and that you
  have sufficient funds to complete the transaction. We are not responsible for any fees or charges that your bank or
  credit card issuer may apply.If you are a California resident, in accordance with Cal. Civ. Code §1789.3, you may
  report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department
  of Consumer Affairs by contacting them in writing at 1625 North Market Blvd., Suite N 112 Sacramento, CA 95834, or by
  telephone at (800) 952-5210 or (916) 445-1254.<br/><br/>‍<strong>Termination and Suspension<br/><br/>‍</strong>Visana may
  terminate or suspend your right to use the Service at any time and for any reason. Without limitation, Visana may
  terminate or suspend your right to use the Service at its sole discretion, without refunding any unused fees or credit
  on your account, if you violate any term of this Agreement or any policy of Visana posted on the Service, or if Visana
  otherwise finds that you have engaged in unlawful or inappropriate behavior in connection with your use of the
  Service. In addition to terminating or suspending your account, Visana reserves the right to take appropriate legal
  action, including without limitation pursuing civil, criminal, and injunctive redress. Even after your right to use
  the Service is terminated or suspended, this Agreement will remain enforceable against you regarding your prior use of
  the Service.<br/><br/>‍<strong>Copyright Complaints and DMCA Copyright Agent<br/><br/>‍</strong>Visana complies with the
  copyright notice-and-takedown procedures set out in the United States Digital Millennium Copyright Act (DMCA), which
  applies to content reported and removed for violating U.S. copyrights. Please note that any notice or counter-notice
  you submit must be truthful and must be submitted under penalty of perjury. A false notice or counter-notice may give
  rise to personal liability. You may therefore want to seek the advice of legal counsel before submitting a notice or a
  counter-notice. We may share any notices and counter-notices submitted to us with others including your contact
  information, and by submitting any notices, you agree you have no expectation of <a
    href="https://drsquatch.com/pages/privacy-policy">privacy</a> in your submission.&nbsp;Content owners of copyrighted
  material or their representing agents may submit a DMCA notice to our registered Copyright Agent if they believe that
  infringing activity has taken place on our Service. The abuse team will only consider valid reports of infringement,
  and you may submit a complete DMCA notice that features all of the points described below only if the representing
  party sending the request is the content owner or the authorized agent acting on behalf of the copyright owner. If you
  are not sure if Visana has control over the allegedly infringed content, please obtain legal representation before
  contacting us. To be effective under the DMCA, any notification of claimed infringement must be in a written
  communication that includes substantially the following which must include a certification made under penalty of
  perjury:&nbsp;A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
  right that is allegedly infringed, as well as information sufficient for Visana to determine the legitimacy of the
  signature and the identity of the signatory;Identification of the copyrighted work claimed to have been infringed, or,
  if a single notification covers multiple copyrighted works at a single online site, a representative list of such
  works at that site, including citation to the applicable copyright registrations where available;Identification of the
  material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or
  access to which is to be disabled, and information reasonably sufficient to permit Visana to locate the material,
  including a timestamp and visible identification of the material in a screenshot or comparable medium, with all
  metadata intact, as well as a hyperlink or URL to the website or online content at issue;Information reasonably
  sufficient to permit Visana to contact the complaining party, including an email address, telephone number, and, if
  available, physical mail address;A statement that the complaining party has a good faith belief that use of the
  material in the manner complained of is not authorized by the copyright owner, its agent, or the law; andA statement
  that the information in the notification is accurate, and under penalty of perjury, that the complaining party is
  authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.&nbsp;Any such
  notifications of claimed infringement must be sent to the below contact with the subject line “DMCA
  NOTICE:”&nbsp;Visana Copyright Agent<br/>J&amp;L Stone, P.A.<br/>2411 NW 75<sup>th</sup> StreetBoca Raton, Florida
  33496<br/>561-901-1741<br/>jstone@JandLstonelaw.com&nbsp;Please note if any notification of claimed infringement does
  not meet the above requirements, Visana has no responsibility to respond to or act on any such defective notification
  of claimed infringement. &nbsp;If you receive a notification of claimed infringement, you may submit a counter
  notification to us under the DMCA. &nbsp;It must include the following, which includes a certification made under
  penalty of perjury:&nbsp;Your physical or electronic signature, as well as information sufficient for Visana to
  determine the legitimacy of the signature and the identity of the signatory;Identification of the material that has
  been removed or to which access has been disabled and the location at which the material appeared before it was
  removed or access to it was disabled;A statement under penalty of perjury that you have a good faith belief that the
  material was removed or disabled as a result of mistake or misidentification of the material to be removed or
  disabled; andYour name, address, and telephone number, and a statement that you consent to the jurisdiction of the
  Federal District court (i) in the judicial district where your address is located if the address is in the United
  States, or (ii) located in the Southern District of Florida, if your address is located outside the United States, and
  that you will accept service of process from the Complainant submitting the notice or the Complainant’s authorized
  agent.&nbsp;Any such counter notification must be sent to:&nbsp;Visana Copyright Agent<br/>J&amp;L Stone, P.A.<br/>2411
  NW 75<sup>th</sup> StreetBoca Raton, Florida
  33496<br/>561-901-1741<br/>jstone@JandLstonelaw.com<br/><br/><strong>Modifications to the Service<br/><br/>‍</strong>Visana
  reserves the right in its sole discretion to review, improve, modify, or discontinue, temporarily or permanently, the
  Service or any content or information on the Service with or without notice. Visana will not be liable to any party
  for any modification or discontinuance of the Service, in part or in its entirety.<br/><br/>‍<strong>Arbitration of All
    Claims on an Individual Basis<br/><br/>‍</strong>You and Visana agree that any and all disputes or claims that have
  arisen or may arise between you and Visana that relate in any way to your participation in, use, or access of the
  Service, including but not limited to services provided by a Visana Provider, or the actions of Visana or its agents,
  shall be resolved through confidential, final and binding arbitration rather than in court, with the exception of any
  dispute relating to the enforcement or validity of intellectual property rights. The Federal Arbitration Act (“FAA”)
  governs the interpretation and enforcement of this Agreement to arbitrate.If a dispute arises between you and Visana,
  we want to learn about and address your concerns and, if we are unable to do so to your satisfaction, to provide you
  with a neutral and cost-effective means of resolving the dispute quickly. Please contact us regarding disputes between
  you and Visana related to the Service.&nbsp;<br/><br/>UNLESS YOU AND VISANA AGREE OTHERWISE, EACH OF US MAY BRING CLAIMS
  AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS,
  REPRESENTATIVE ACTION OR PRIVATE ATTORNEY GENERAL PROCEEDING. ALSO, TO THE EXTENT AVAILABLE BY LAW, AND SUBJECT TO THE
  DAMAGE LIMITATIONS DISCUSSED HEREIN, THE ARBITRATOR MAY AWARD RELIEF ONLY IN FAVOR, AND FOR THE BENEFIT OF, THE
  INDIVIDUAL PARTY SEEKING RELIEF. <br/><br/>If a court decides that any part of this agreement to arbitrate is invalid or
  unenforceable, the other parts of this Section shall still apply. Specifically, if a court decides that applicable law
  precludes enforcement of any of this paragraph’s limitations as to a particular claim or a particular request for a
  remedy (such as a request for public injunctive relief), then that claim or that remedy request (and only that claim
  or that remedy request) may be severed from the arbitration and may be brought in court, subject to your and Visana’s
  right to appeal the court’s decision. All other claims shall be arbitrated.The arbitration shall be administered by
  JAMS pursuant to its Comprehensive Arbitration Rules and Procedures and held in Broward County, Florida or ay another
  mutually agreed location.The arbitrator’s award shall be confidential, final, and binding, and judgment on the award
  rendered by the arbitrator may be entered in any court having jurisdiction thereof.&nbsp;Payment of all filing,
  administration, and arbitrator fees will be governed by the JAMS’s rules which can be found online through the JAMS
  website at <a href="https://www.jamsadr.com/">https://www.jamsadr.com/</a>.&nbsp;<br/><br/>‍<strong>Governing Law and
    Venue<br/><br/>‍</strong>This Agreement will be is governed, construed, and enforced under the laws of the State of
  Delaware, without regard to choice of law principles. Any action arising under or related to this Agreement shall be
  filed in a state or federal court located the State of Delaware(except for small claims court actions which may be
  brought in the county where you reside). You hereby submit to the jurisdiction and venue of said courts and consent to
  service of process by email in any legal proceeding.<br/><br/>‍<strong>Severability<br/><br/>‍</strong>You and Visana
  agree that if any portion of these Terms is found illegal or unenforceable, that portion will be construed to be
  enforceable to the maximum extent permitted by law and the remainder of the Terms will remain valid and enforceable to
  the maximum extent permitted by law.<br/><br/>‍<strong>No Agency<br/><br/>‍</strong>No independent contractor, agency,
  partnership, joint venture, employer-employee, or franchiser-franchisee relationship is intended or created by these
  Terms.<br/><br/>‍<strong>Entire Agreement &amp; Notice<br/><br/>‍</strong>These Terms, including the Privacy Policy,
  constitute the entire agreement between you and Visana with respect to the Service and supersedes any previous version
  of these Terms. Failure by Visana to enforce any provision of these Terms will not be construed as a waiver of any
  provision or right. These Terms will inure to the benefit of Visana and its successors and assigns.You agree that we
  will provide notices and messages to you in the following ways: (1) within the Service, or (2) sent to the contact
  information you provided us (e.g., email, mobile number, physical address). You agree to keep your contact information
  up to date. You further agree that other than inquiries or notices where separate contact information is specified
  herein (such as DMCA or security reporting), you will contact us directly with any inquiries or notices regarding
  these Terms via email at <a
    href="mailto:members@visanahealth.com">members@visanahealth.com</a>.<br/>‍<br/>&nbsp;<strong>Questions and How to
    Contact Us</strong>If you have any questions regarding these Terms or otherwise need to contact us, please email us
  at contact@visanahealth.com or by United States mail at 5123 W 98th St #1345, &nbsp;Minneapolis, MN 55437.</p>

    </div>
    </>
  )
}