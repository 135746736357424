import { getLogger } from '../log/getLogger'
import React, { useEffect, useState } from "react";
import { WhoAmISvc } from '../auth/WhoAmISvc';
import diContainer from '../inversify.config'
import DI_TYPES from "../diTypes";
import * as bearerUtil from '../commonSrc/auth/bearerUtil'
// import * as bob from "@aws-sdk/client-cognito-identity-provider"
import { setInsuranceCompanyName, setInsuranceGroupNumber, setInsurancePolicyholderName, setInsuranceMemberId } from '../user/userAttributesSlice'
import { useNavigate } from "react-router";


import { useDispatch, useSelector } from 'react-redux';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { selectInsuranceCompanyName, selectInsurancePolicyholderName, selectInsuranceGroupNumber, selectInsuranceMemberId } from '../user/userAttributesSlice';
import { current } from '@reduxjs/toolkit';



// import {
//     fromCognitoIdentityPool,
// } from "@aws-sdk/credential-provider-cognito-identity";

// import {
//   AdminGetUserCommand,
//   CognitoIdentityProviderClient,
// } from "@aws-sdk/client-cognito-identity";

const LOG = getLogger('insurance.UpdateInsuranceComponent')

const userPoolId = "us-east-2_ddZu8Iroa"




export default function UpdateInsuranceComponent () {
  const navigate = useNavigate();

  const dispatch = useDispatch()

  const [dateOfBirth, setDateOfBirth] = useState();
  // const [insuranceInfo, setInsuranceInfo] = useState();


  // function parseJwt(jwtToken: string): JSON | null {
  //   if (typeof jwtToken === 'undefined' || jwtToken == null) { return jwtToken as null; }
  //   try {
  //     // https://stackoverflow.com/questions/23097928/node-js-throws-btoa-is-not-defined-error/47890385#47890385
  //     // return JSON.parse(atob(jwtToken.split('.')[1]));
  //     return JSON.parse(Buffer.from(jwtToken.split('.')[1], 'base64').toString('binary'))
  //   } catch (e) {
  //     LOG.error(`parseJwt(): Failed to parse jwtToken = ${jwtToken}.   Reason: ${e}`)
  //     return null;
  //   }
  // }; 

  useEffect(() => {
    // tryContactingCognito()
    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
    const jwtToken = whoAmISvc.getMyAuthToken()
    console.log(`jwtToken = ${jwtToken}`)
    const parsedJwtIdToken: any | null = bearerUtil.parseJwt(jwtToken as string)
    console.log(`parsedJwtIdToken = ${JSON.stringify(parsedJwtIdToken, null, 2)}`)
    setDateOfBirth(bearerUtil.getMyCustomDateOfBirth(jwtToken as string) as string as any)
    // setInsuranceInfo(currentInsuranceInfoInState as any)
  }, []);
  
  // https://stackoverflow.com/questions/75677036/aws-cognito-update-email-using-javascript-3-sdk-adminupdateuserattributescommand
  const attributes = [
    {
      Name: "custom:dob", // "given_name" and "family_name" update without issue
      Value: "2/2/1966", // temporarily hard-coded
    }
  ];


  // const currentInsuranceInfoInState: string = useSelector((state: SbhcCompositeState) => {
  //   return selectInsuranceInfo(state)
  // })
  
  const currentInsuranceCompanyNameInState: string = useSelector((state: SbhcCompositeState) => {
    return selectInsuranceCompanyName(state)
  })

  const currentInsurancePolicyholderNameInState: string = useSelector((state: SbhcCompositeState) => {
    return selectInsurancePolicyholderName(state)
  })

  const currentInsuranceGroupNumberInState: string = useSelector((state: SbhcCompositeState) => {
    return selectInsuranceGroupNumber(state)
  })

  const currentInsuranceMemberIdInState: string = useSelector((state: SbhcCompositeState) => {
    return selectInsuranceMemberId(state)
  })


  // const handleSave = async () => {
  //   const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
  //   const jwtToken = whoAmISvc.getMyAuthToken()

  //   const command = new UpdateUserAttributesCommand({
  //     // UserPoolId: userPoolId,
  //     // Username: "bob",
  //     AccessToken: jwtToken,
  //     UserAttributes: attributes,
  //   } as UpdateUserAttributesCommandInput);
  //   try {
  //     const response = await cognitoIdentityProviderClient.send(command);
  //     LOG.info(`handleSave(): response = ${JSON.stringify(handleSave)}`)
  //   } catch(err) {
  //     LOG.warn(`handleSave(): request failed.  err = ${err}`)
  //   }
  // }

  const handleSaveThenNext = async () => {
    await handleSave()
    navigate(`/medication`, { replace: true });
  }

  const handleSave = async () => {
    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
    const jwtToken = whoAmISvc.getMyAuthToken()
    const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
    const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
    const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

    const headers: any = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    }

    if (whoAmISvc.amILoggedIn()) {
      LOG.debug(`handleSave(): User is signed in.  jwtToken = ${whoAmISvc.getMyAuthToken()}`)
      headers["Authorization"] = `Bearer ${whoAmISvc.defGetMyAuthToken()}`
    } else {
      LOG.warn(`handleSave(): Unauthorized 401.  User is NOT signed in.`)
    }

    LOG.debug(`handleSave(): currentInsuranceCompanyNameInState = ${currentInsuranceCompanyNameInState}`)

    const requestBodyAsString = JSON.stringify({
      insuranceCompanyName: currentInsuranceCompanyNameInState,
      insuranceGroupNumber: currentInsuranceGroupNumberInState,
      insuranceMemberId: currentInsuranceMemberIdInState,
      insurancePolicyholderName: currentInsurancePolicyholderNameInState
    })

    const requestOptions = {
      method: 'POST',
      // mode: 'cors',
      headers: headers,
      body: requestBodyAsString
    };
    LOG.debug(`handleSave(): Sending POST request with body = ${requestBodyAsString}`)


    const url = (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:9000') + `${process.env.REACT_APP_BACKEND_RELATIVE_API_PATH}` + '/userAttributes'


    return fetch(url, requestOptions).then(response => response.json())
  }

  const tryContactingCognito = async () => {
    console.log(`tryContactingCognito()...`)
    // const getUserAsync = async() => {
    //   const response = await fetch("https://cognito-idp.us-east-2.amazonaws.com", {
    //     method: 'POST',
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({AccessToken: 'abc123'})
    //   });
    //   const jsonData = await response.json();
    //   console.log(jsonData);
    // }

    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
    const jwtToken = whoAmISvc.getMyAuthToken()

    // call the function
    // getUserAsync()
    // make sure to catch any error
    // .catch(console.error);
  }

  // const onChangeInsuranceInfo = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
  //   LOG.debug(`onChangeInsuranceInfo() - Entering with event = ${safeStringify(event)}`)
  //   // string vs number.  https://stackoverflow.com/questions/41773778/sending-number-instead-of-string-from-select-in-react-component
  //   dispatch(setInsuranceInfo(event.target.value))
  // }

  return (
    <>
    <header className="page-header">
      <div className="inner">
        <img src="Basic-Info.svg" alt="" />
        <div className="text-block">
          <h1>Join the Study</h1>
          <p>There is a little bit of paperwork we need to get you enrolled in the study.</p>
        </div>
      </div>
    </header>

    <section className="content-block-container">
    <div className="questionnaire-content">

      <div className="card">
        <header>
          <h2>Insurance</h2>
        </header>
        <div className="inner">
        
        {/* <button onClick={tryContactingCognito}>
          Click me!
        </button> */}
        {/* <div>
          <label htmlFor="dob">DOB MM/DD/YYYY:</label>
          <input id="dob" value={dateOfBirth} onInput={(evt: React.FormEvent<HTMLInputElement>) => setDateOfBirth((evt.target as HTMLInputElement).value as any)}/>
        </div> */}
        {/* <div>
          <label htmlFor="insuranceCompanyName"  className="form-label">Insurance Company</label>
          <textarea className="form-control" id="insuranceCompanyName" rows={2} value={currentInsuranceCompanyNameInState} onChange={(evt: React.FormEvent<HTMLTextAreaElement>) => { console.log(`onChange(): Now seeing ${(evt.target as HTMLTextAreaElement).value}`); dispatch(setInsuranceCompanyName((evt.target as HTMLTextAreaElement).value as any))}}/>
        </div> */}
        <div className="field">
          <label htmlFor="insuranceCompanyName">Your Insurance Company Name</label>
          <input className="form-control" id="insuranceCompanyName" value={currentInsuranceCompanyNameInState} onInput={(evt: React.FormEvent<HTMLInputElement>) => { console.log(`insuranceCompanyName = ${(evt.target as HTMLInputElement).value}.  Now calling setInsuranceCompanyName()...`); dispatch(setInsuranceCompanyName((evt.target as HTMLInputElement).value as any))}}/>
        </div>    
        <div className="field">
          <label htmlFor="insurancePolicyholderName">Policyholder Name</label>
          <input className="form-control" id="insurancePolicyholderName" value={currentInsurancePolicyholderNameInState} onInput={(evt: React.FormEvent<HTMLInputElement>) => dispatch(setInsurancePolicyholderName((evt.target as HTMLInputElement).value as any))}/>
        </div>
        <div className="field">
          <label htmlFor="insuranceMemberId">Member ID</label>
          <input className="form-control" id="insuranceMemberId" value={currentInsuranceMemberIdInState} onInput={(evt: React.FormEvent<HTMLInputElement>) => dispatch(setInsuranceMemberId((evt.target as HTMLInputElement).value as any))}/>
        </div>    
        <div className="field">
          <label htmlFor="insuranceGroupNumber">Group Number</label>
          <input className="form-control" id="insuranceGroupNumber" value={currentInsuranceGroupNumberInState} onInput={(evt: React.FormEvent<HTMLInputElement>) => dispatch(setInsuranceGroupNumber((evt.target as HTMLInputElement).value as any))}/>
        </div> 

        </div>
        </div>
        <div className="mt-4 button-block">
          <button className="btn btn-primary btn-monique" onClick={handleSaveThenNext}>
            Next
          </button>
        </div>
      </div>
    </section>
    </>
  )
}

// https://stackoverflow.com/questions/70666370/how-to-fetch-amazon-cognito-identity-id-user-identity-id-for-the-user-from-the
// https://boto3.amazonaws.com/v1/documentation/api/latest/reference/services/cognito-idp/client/update_user_pool_client.html#
// https://stackoverflow.com/questions/70666370/how-to-fetch-amazon-cognito-identity-id-user-identity-id-for-the-user-from-the
// https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/CognitoIdentityServiceProvider.html
// https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/

// // a client can be shared by different commands.
// const client = new CognitoIdentityProviderClient({ region: "REGION" });

// const params = {
//   /** input parameters */
// };
// const command = new AddCustomAttributesCommand(params);

// // async/await.
// try {
//   const data = await client.send(command);
//   // process data.
// } catch (error) {
//   // error handling.
// } finally {
//   // finally.
// }

// https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/
// https://github.com/awsdocs/aws-doc-sdk-examples/blob/main/javascript/example_code/cognito/admin-get-user.js
// https://docs.aws.amazon.com/sdk-for-javascript/v3/developer-guide/javascript_cognito-identity-provider_code_examples.html
// https://github.com/awsdocs/aws-doc-sdk-examples/tree/main/javascriptv3/example_code/cognito/#code-examples
// https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/CognitoIdentityServiceProvider.html
// https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/CognitoIdentityServiceProvider.html#getUser-property
// https://stackoverflow.com/questions/75513623/how-to-lookup-cognito-user-by-their-uuid-with-aws-sdk-v3
// https://stackoverflow.com/questions/62145271/how-to-get-access-token-in-aws-cognito-if-using-browser-based-javascript-sdk
// https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-the-id-token.html
// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_UpdateUserAttributes.html
// https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/modules/updateuserattributesrequest.html
// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_UpdateUserAttributes.html
// https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/CognitoIdentityServiceProvider.html
// https://stackoverflow.com/questions/41118350/how-to-get-a-custom-attribute-in-a-user-pool-using-aws-android-sdk
// https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/modules/updateuserattributesrequest.html#userattributes
// https://stackoverflow.com/questions/48700030/custom-attributes-missing-in-the-getuserattributes-response-aws-cognito

// const userData = { Username: username, Pool: userPool };
// const cognitoUser = new AWS.CognitoIdentityServiceProvider.CognitoUser(userData);
// cognitoUser.getUserAttributes(function(err, result) {
//                         if (err) {
//                             alert(err);
//                             return;
//                         }
//                         console.log(result)
//                         for (i = 0; i < result.length; i++) {
//                             console.log('attribute ' + result[i].getName() + ' has value ' + result[i].getValue());
//                         }
//                     });

// https://stackoverflow.com/questions/41118350/how-to-get-a-custom-attribute-in-a-user-pool-using-aws-android-sdk
// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GetUser.html
// https://docs.aws.amazon.com/code-library/latest/ug/cognito-identity-provider_example_cognito-identity-provider_AdminGetUser_section.html
// https://github.com/awsdocs/aws-doc-sdk-examples/tree/main/javascriptv3/example_code/cognito
// https://github.com/awsdocs/aws-doc-sdk-examples/blob/main/javascriptv3/example_code/cognito/actions/admin-get-user.js
// https://docs.aws.amazon.com/en_us/cognito/latest/developerguide/cognito-user-pools-app-integration.html
// https://stackoverflow.com/questions/55318873/is-it-possible-to-get-custom-attributes-on-aws-cognito-sign-up-form
// https://stackoverflow.com/questions/55318873/is-it-possible-to-get-custom-attributes-on-aws-cognito-sign-up-form
// https://stackoverflow.com/questions/70782171/is-there-any-way-to-get-the-user-details-from-aws-cognito-without-using-aws-key
// https://stackoverflow.com/questions/68283215/custom-attributes-in-cognito-access-token
// https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-the-id-token.html
// https://stackoverflow.com/questions/75677036/aws-cognito-update-email-using-javascript-3-sdk-adminupdateuserattributescommand
// https://stackoverflow.com/questions/75677036/aws-cognito-update-email-using-javascript-3-sdk-adminupdateuserattributescommand
// https://stackoverflow.com/questions/74353960/webpack-error-after-upgrading-node-module-parse-failed-unexpected-token
// https://stackoverflow.com/questions/74353960/webpack-error-after-upgrading-node-module-parse-failed-unexpected-token
// 003 shows up:  https://github.com/sbhcdev/febe/commit/4a760ae274633fc1a6bcdccc0a7d1074400d4793
// 003 gone again: https://github.com/sbhcdev/febe/commit/b668dd474a5878be308c2184dbc7dfbd68b4bab8