
// TODO: DRY
export type StateScalarAttribute<T> = T;

export interface Step1BasicAttributes {
  // TODO: Should these be nullable?  nullable?  empty string?  Defaulted to?
  // TODO: should these first few be numbers?
  heightInInches: StateScalarAttribute<number>,
  weightInLbs: StateScalarAttribute<number>,
  ageInYears: StateScalarAttribute<number>,
}

export interface Step2GutAttributes {
  everNausea: StateScalarAttribute<string>,
  everVomiting: StateScalarAttribute<string>,
  everAbdominalPain: StateScalarAttribute<string>,
  abdominalPainHowOften: StateScalarAttribute<string>,
  abdominalPainWhere: StateScalarAttribute<string>,
  everBloating: StateScalarAttribute<string>,
  everBurping: StateScalarAttribute<string>,
  everDyspepsia: StateScalarAttribute<string>,
  everFlatulence: StateScalarAttribute<string>,
  everAbdominalDistension: StateScalarAttribute<string>,
}

export interface Step3BowelAttributes {
  hasConstipation: StateScalarAttribute<boolean>,
  hasDiarrhea: StateScalarAttribute<boolean>,
  hasSteatorrhea: StateScalarAttribute<boolean>,

  asnRelatedToBowelMovement: StateScalarAttribute<string>,
  asnRelievedByBowelMovement: StateScalarAttribute<string>,
  asnRelievedOrRelatedToDefecation: StateScalarAttribute<string>,
  hasBowelMovementFrequencyChangedLast6Months: StateScalarAttribute<boolean>,
  bowelMovementHowOften: StateScalarAttribute<string>,
  hasNarrowingStoolLast6Months: StateScalarAttribute<boolean>,
  // currentStoolTypeNumber: StateScalarAttribute<number>,
  hasPoopTypeSeparateHardLumps: StateScalarAttribute<boolean>,
  hasPoopTypeSausageShapedButLumpy: StateScalarAttribute<boolean>,
  hasPoopTypeSausageWithCracks: StateScalarAttribute<boolean>,
  hasPoopTypeSnakeSmooth: StateScalarAttribute<boolean>,
  hasPoopTypeFluffyRaggedMushy: StateScalarAttribute<boolean>,
  hasPoopTypeWatery: StateScalarAttribute<boolean>,
  hasPoopTypeSoftBlobsClearEdges: StateScalarAttribute<boolean>,


  hasMidnightDiarrhea: StateScalarAttribute<boolean>,
  hasStrainingBowelMovement: StateScalarAttribute<boolean>,
  hasHardOrLumpyStools: StateScalarAttribute<boolean>,
  hasSensationIncompleteBowelMovement: StateScalarAttribute<boolean>,
  hasSensationOfAnorectalBlockageOrObstruction: StateScalarAttribute<boolean>,
  hasFewerThan3SpontaneousBowelMovementsWeekly: StateScalarAttribute<boolean>,
  fsrLooseStools: StateScalarAttribute<string>,
  hasManeuversToAchieveBowelMovement: StateScalarAttribute<boolean>,
  bowelTroubleHowOften: StateScalarAttribute<string>,
  hasCurrentlyBloodInStool: StateScalarAttribute<boolean>,
}

export interface Step4FoodWeightAttributes {
  everUnexpectedWeightLoss10In3: StateScalarAttribute<string>, 
  everExcessiveWeightGain: StateScalarAttribute<string>, 
  everWaterRetention: StateScalarAttribute<string>, 
  // everCravingCertainFoods: StateScalarAttribute<string>, 
  // everFoodAddiction: StateScalarAttribute<string>, 
  hasSymptomsWorsenWithGlutenIngestion: StateScalarAttribute<boolean>, 
  hasEarlyFullnessDuringMeal: StateScalarAttribute<boolean>,
  earlyFullnessSymptomsHowLong: StateScalarAttribute<string>,
  hasEpigastricPainOrBurning: StateScalarAttribute<boolean>,
  epigastricPainOrBurningHowLong: StateScalarAttribute<string>,
}

export interface Step5EmotionalAttributes {
  stressLevel1To10: StateScalarAttribute<number>, 
  anxietyLevel1To10: StateScalarAttribute<number>, 
  // everAngerIrritability: StateScalarAttribute<string>, 
  // everMoodSwings: StateScalarAttribute<string>, 
  everDepression: StateScalarAttribute<string>, 
}

export interface Step6EnergySleepAttributes {
  everSluggishness: StateScalarAttribute<string>, 
  everRestlessness: StateScalarAttribute<string>, 
  everBrainFog: StateScalarAttribute<string>, 
  everSleepDisturbances: StateScalarAttribute<string>, 
  // everHyperactivity: StateScalarAttribute<string>, 
}

export interface Step7SkinAttributes {
  // everAcne: StateScalarAttribute<string>, 
  everFlushing: StateScalarAttribute<string>, 
  everHivesRashes: StateScalarAttribute<string>, 
  everExcessiveSweating: StateScalarAttribute<string>, 
  everHairLoss: StateScalarAttribute<string>,  
  everEasyBruising: StateScalarAttribute<string>,   
}

export interface Step8NoseAttributes {
  everStuffyNose: StateScalarAttribute<string>, 
  everSinusProblems: StateScalarAttribute<string>, 
  everHayFever: StateScalarAttribute<string>, 
}

export interface Step9EyeAttributes {
  everWateryOrItchyEyes: StateScalarAttribute<string>, 
  everBagsOrDarkCirclesUnderEyes: StateScalarAttribute<string>, 
  everSwollenRedOrStickyEyelids: StateScalarAttribute<string>, 
  everBlurredVision: StateScalarAttribute<string>, 
}

export interface Step10MouthAttributes {
  hasDifficultyOrPainSwallowing: StateScalarAttribute<boolean>, 
  everRegurgitation: StateScalarAttribute<string>,
  everSourOrBitterTaste: StateScalarAttribute<string>,
  
  hasWorseLargeMeals: StateScalarAttribute<boolean>, 
  hasWorseLayingDown: StateScalarAttribute<boolean>,
  hasWorseAcidic: StateScalarAttribute<boolean>,

  hasReliefTums: StateScalarAttribute<boolean>, 
  hasReliefTolaids: StateScalarAttribute<boolean>,
  hasReliefMylanta: StateScalarAttribute<boolean>,
  hasReliefH2Blocker: StateScalarAttribute<boolean>,
  hasReliefProtonPumpInhibitor: StateScalarAttribute<boolean>,
  regularlyConsumeChicoryRootOrInulin: StateScalarAttribute<boolean>,
  regularlyConsumeSugarAlcohols: StateScalarAttribute<boolean>,
  everLiquidFromBottomToiletOrNot: StateScalarAttribute<boolean>,
  bestDescriptionAfterBowelMovement: StateScalarAttribute<string>,
  whatBothersYouMost: StateScalarAttribute<string>,
  hasEsophogealCancer: StateScalarAttribute<boolean>,

  everFrequentCoughing: StateScalarAttribute<string>, 
  everSwollenAndOrOrDiscoloredTongue: StateScalarAttribute<string>, 
  everFrequentNeedToClearThroat: StateScalarAttribute<string>, 
  everCankerSores: StateScalarAttribute<string>, 
  everExcessiveMucus: StateScalarAttribute<string>, 
  everHorseness: StateScalarAttribute<string>, 
  everTonguePain: StateScalarAttribute<string>, 
  hasHiatalHernia: StateScalarAttribute<boolean>, 
}

export interface Step11EarAttributes {
  everItchyEars: StateScalarAttribute<string>, 
  everDrainageFromEarOrWaxyBuildup: StateScalarAttribute<string>, 
  everEarAchesOrInfections: StateScalarAttribute<string>, 
  everRingingInEars: StateScalarAttribute<string>, 
}

export interface Step12HeadAttributes {
  everHeadaches: StateScalarAttribute<string>, 
  everFaintnessOrLightheadedness: StateScalarAttribute<string>, 
  everDizziness: StateScalarAttribute<string>, 
}

// export interface Step10Attributes {
//   everPoorMemory: StateScalarAttribute<string>, 
//   everDififficultyInMakingDecisions: StateScalarAttribute<string>, 
//   everConfusion: StateScalarAttribute<string>, 
//   everPoorConcentration: StateScalarAttribute<string>, 
//   everSlurredSpeech: StateScalarAttribute<string>, 
//   everPoorCoordination: StateScalarAttribute<string>,  
// }

export interface Step13HeartAttributes {
  everIrregularHeartbeat: StateScalarAttribute<string>, 
  everRapidOrPoundingHeartbeat: StateScalarAttribute<string>, 
  everChestPain: StateScalarAttribute<string>, 
}

export interface Step14RespiratoryAttributes {
  everShortnessOfBreath: StateScalarAttribute<string>, 
  everAsthmaBronchitis: StateScalarAttribute<string>, 
  everDifficultyBreathing: StateScalarAttribute<string>, 
  // Twice
  // everHyperactivity: StateScalarAttribute<string>, 
}

export interface Step15JointsMusclesAttributes {
  everPainsOrAchesInJoints: StateScalarAttribute<string>, 
  // everPainsOrAchesInMuscles: StateScalarAttribute<string>, 
  everArthritisJointSwelling: StateScalarAttribute<string>, 
  // everFeelingOfWeaknessOrTired: StateScalarAttribute<string>, 
  everStiffOrLimitationOfMovement: StateScalarAttribute<string>, 
}

export interface Step16HealthHistoryAttributes {
  hasFamilyHistoryOfGiCancer: StateScalarAttribute<boolean>, 
  hasHypertension: StateScalarAttribute<boolean>, 
  hasHyperlipidemia: StateScalarAttribute<boolean>, 
  hasType2Diabetes: StateScalarAttribute<boolean>, 
  hasType1Diabetes: StateScalarAttribute<boolean>, 
  hasAlcoholUse: StateScalarAttribute<boolean>, 
  hasOpiodUse: StateScalarAttribute<boolean>, 
  hasBowelObstruction: StateScalarAttribute<boolean>, 
  hasObesity: StateScalarAttribute<boolean>, 
  hasRadiationToIntestines: StateScalarAttribute<boolean>, 
  hasTerminalIlealResection: StateScalarAttribute<boolean>, 
  hasIleocecalValveResection: StateScalarAttribute<boolean>, 
  hasAmyloidosis: StateScalarAttribute<boolean>, 
  hasScleroderma: StateScalarAttribute<boolean>, 
  hasBowelStrictureOrAdhesions: StateScalarAttribute<boolean>, 
  hasSmallBowelDiverticulosis: StateScalarAttribute<boolean>, 
  hasAbdominalSurgeryHistory: StateScalarAttribute<boolean>, 
  hasIleocectomyHistory: StateScalarAttribute<boolean>, 
  hasCommonVariableImmunodeficiency: StateScalarAttribute<boolean>, 
  hasIgaDeficency: StateScalarAttribute<boolean>, 
  hasHivOrAids: StateScalarAttribute<boolean>, 
  hasLongTermProtonPumpInhibiterUse: StateScalarAttribute<boolean>, 
  hasAtrophicGastritis: StateScalarAttribute<boolean>, 
  hasPancreaticDisease: StateScalarAttribute<boolean>, 
  hasLiverDisease: StateScalarAttribute<boolean>, 
  hasCholecystectomy: StateScalarAttribute<boolean>, 
  // hasDysphagia: StateScalarAttribute<boolean>, 
  
  // everRegurgitation: StateScalarAttribute<boolean>, 
  // everSourOrBitterTaste: StateScalarAttribute<boolean>, 
  // hasEsophogealCancer: StateScalarAttribute<boolean>, 
  
  everFeversOrChills: StateScalarAttribute<string>, 
  everLossOfAppetite: StateScalarAttribute<string>, 
  // hasHiatalHernia: StateScalarAttribute<boolean>, 
  everBoneFractureOrOsteoporosis: StateScalarAttribute<string>, 
  hasFamilyHistoryOfCeliac: StateScalarAttribute<boolean>, 
  hasThyroidDisease: StateScalarAttribute<boolean>,   
}

export interface Step17OtherAttributes {
  everFrequentIllness: StateScalarAttribute<string>, 
  everFrequentUrgeToUrinate: StateScalarAttribute<string>, 
  everGenitalItchOrDischarge: StateScalarAttribute<string>, 
  // everExcessiveSweatingAgain: StateScalarAttribute<string>, 
  hasHistoryOfAnemia: StateScalarAttribute<boolean>, 
  hasPalpableMassOnAbdomen: StateScalarAttribute<boolean>,   
  hasStructuralIssueStomachOrEsophagus: StateScalarAttribute<boolean>,   
}

export interface Step18GutHistoryAttributes {
  abdominalPainHowLong: StateScalarAttribute<string>, 
  hasPrimaryCareConversation: StateScalarAttribute<boolean>, 
  countPrimaryCareConversations: StateScalarAttribute<number>
  hasGiDoctorConversation: StateScalarAttribute<boolean>,
  countGiConversations: StateScalarAttribute<number>,
}


// null as unknown as 

// vs slice-of-state state-slice vs QuestionnaireState Partial
export interface QuestionnaireAttributes 
  extends 
  Step1BasicAttributes, 
  Step2GutAttributes,
  Step3BowelAttributes,
  Step4FoodWeightAttributes,
  Step5EmotionalAttributes,
  Step6EnergySleepAttributes,
  Step7SkinAttributes,
  Step8NoseAttributes,
  Step9EyeAttributes,
  Step10MouthAttributes,
  Step11EarAttributes,
  Step12HeadAttributes,
  Step13HeartAttributes,
  Step14RespiratoryAttributes,
  Step15JointsMusclesAttributes,
  Step16HealthHistoryAttributes,
  Step17OtherAttributes,
  Step18GutHistoryAttributes
{  
  questionnaireSubmittedFlag: StateScalarAttribute<boolean>,   
}

const STEP1_BASIC_NULLIFIED_ATTRIBUTES = {
  heightInInches: null as unknown as StateScalarAttribute<number>,
  weightInLbs: null as unknown as StateScalarAttribute<number>,
  ageInYears: null as unknown as StateScalarAttribute<number>,
}

const STEP2_GUT_NULLIFIED_ATTRIBUTES = {
  everNausea: null as unknown as StateScalarAttribute<string>,
  everVomiting: null as unknown as StateScalarAttribute<string>,
  everAbdominalPain: null as unknown as StateScalarAttribute<string>,
  abdominalPainHowOften: null as unknown as StateScalarAttribute<string>,
  abdominalPainWhere: null as unknown as StateScalarAttribute<string>,
  everBloating: null as unknown as StateScalarAttribute<string>,
  everBurping: null as unknown as StateScalarAttribute<string>,
  everDyspepsia: null as unknown as StateScalarAttribute<string>,
  everFlatulence: null as unknown as StateScalarAttribute<string>,
  everAbdominalDistension: null as unknown as StateScalarAttribute<string>,
}

const STEP3_BOWEL_NULLIFIED_ATTRIBUTES = {
  hasConstipation: null as unknown as StateScalarAttribute<boolean>,
  hasDiarrhea: null as unknown as StateScalarAttribute<boolean>,
  hasSteatorrhea: null as unknown as StateScalarAttribute<boolean>,
  
  asnRelatedToBowelMovement: null as unknown as StateScalarAttribute<string>,
  asnRelievedByBowelMovement: null as unknown as StateScalarAttribute<string>,
  asnRelievedOrRelatedToDefecation: null as unknown as StateScalarAttribute<string>,
  hasBowelMovementFrequencyChangedLast6Months: null as unknown as StateScalarAttribute<boolean>,
  bowelMovementHowOften: null as unknown as StateScalarAttribute<string>,
  hasNarrowingStoolLast6Months: null as unknown as StateScalarAttribute<boolean>,
  // currentStoolTypeNumber: null as unknown as StateScalarAttribute<number>,
  hasPoopTypeSeparateHardLumps: null as unknown as StateScalarAttribute<boolean>,
  hasPoopTypeSausageShapedButLumpy: null as unknown as StateScalarAttribute<boolean>,
  hasPoopTypeSausageWithCracks: null as unknown as StateScalarAttribute<boolean>,
  hasPoopTypeSnakeSmooth: null as unknown as StateScalarAttribute<boolean>,
  hasPoopTypeFluffyRaggedMushy: null as unknown as StateScalarAttribute<boolean>,
  hasPoopTypeWatery: null as unknown as StateScalarAttribute<boolean>,
  hasPoopTypeSoftBlobsClearEdges: null as unknown as StateScalarAttribute<boolean>,
  


  hasMidnightDiarrhea: null as unknown as StateScalarAttribute<boolean>,
  hasStrainingBowelMovement: null as unknown as StateScalarAttribute<boolean>,
  hasHardOrLumpyStools: null as unknown as StateScalarAttribute<boolean>,
  hasSensationIncompleteBowelMovement: null as unknown as StateScalarAttribute<boolean>,
  hasSensationOfAnorectalBlockageOrObstruction: null as unknown as StateScalarAttribute<boolean>,
  hasFewerThan3SpontaneousBowelMovementsWeekly: null as unknown as StateScalarAttribute<boolean>,
  fsrLooseStools: null as unknown as StateScalarAttribute<string>,
  hasManeuversToAchieveBowelMovement: null as unknown as StateScalarAttribute<boolean>,
  bowelTroubleHowOften: null as unknown as StateScalarAttribute<string>,
  hasCurrentlyBloodInStool: null as unknown as StateScalarAttribute<boolean>,
}

const STEP4_FOOD_WEIGHT_NULLIFIED_ATTRIBUTES = {
  everUnexpectedWeightLoss10In3: null as unknown as StateScalarAttribute<string>, 
  everExcessiveWeightGain: null as unknown as StateScalarAttribute<string>, 
  everWaterRetention: null as unknown as StateScalarAttribute<string>, 
  // everCravingCertainFoods: null as unknown as StateScalarAttribute<string>, 
  // everFoodAddiction: null as unknown as StateScalarAttribute<string>, 
  hasSymptomsWorsenWithGlutenIngestion: null as unknown as StateScalarAttribute<boolean>, 
  hasEarlyFullnessDuringMeal: null as unknown as StateScalarAttribute<boolean>,
  earlyFullnessSymptomsHowLong: null as unknown as StateScalarAttribute<string>,
  hasEpigastricPainOrBurning: null as unknown as StateScalarAttribute<boolean>,
  epigastricPainOrBurningHowLong: null as unknown as StateScalarAttribute<string>,
}

const STEP5_EMOTIONAL_NULLIFIED_ATTRIBUTES = {
  stressLevel1To10: null as unknown as StateScalarAttribute<number>, 
  anxietyLevel1To10: null as unknown as StateScalarAttribute<number>, 
  // everAngerIrritability: null as unknown as StateScalarAttribute<string>, 
  // everMoodSwings: null as unknown as StateScalarAttribute<string>, 
  everDepression: null as unknown as StateScalarAttribute<string>, 
}

const STEP6_ENERGY_SLEEP_NULLIFIED_ATTRIBUTES = {
  everSluggishness: null as unknown as StateScalarAttribute<string>, 
  everRestlessness: null as unknown as StateScalarAttribute<string>, 
  everBrainFog: null as unknown as StateScalarAttribute<string>, 
  everSleepDisturbances: null as unknown as StateScalarAttribute<string>, 
  // everHyperactivity: null as unknown as StateScalarAttribute<string>, 
}

const STEP7_SKIN_NULLIFIED_ATTRIBUTES = {
  // everAcne: null as unknown as StateScalarAttribute<string>, 
  everFlushing: null as unknown as StateScalarAttribute<string>, 
  everHivesRashes: null as unknown as StateScalarAttribute<string>, 
  everExcessiveSweating: null as unknown as StateScalarAttribute<string>, 
  everHairLoss: null as unknown as StateScalarAttribute<string>,  
  everEasyBruising: null as unknown as StateScalarAttribute<string>,   
}

const STEP8_NOSE_NULLIFIED_ATTRIBUTES = {
  everStuffyNose: null as unknown as StateScalarAttribute<string>, 
  everSinusProblems: null as unknown as StateScalarAttribute<string>, 
  everHayFever: null as unknown as StateScalarAttribute<string>, 
}

const STEP9_EYE_NULLIFIED_ATTRIBUTES = {
  everWateryOrItchyEyes: null as unknown as StateScalarAttribute<string>, 
  everBagsOrDarkCirclesUnderEyes: null as unknown as StateScalarAttribute<string>, 
  everSwollenRedOrStickyEyelids: null as unknown as StateScalarAttribute<string>, 
  everBlurredVision: null as unknown as StateScalarAttribute<string>, 
}

const STEP10_MOUTH_NULLIFIED_ATTRIBUTES = {
  hasDifficultyOrPainSwallowing: null as unknown as StateScalarAttribute<boolean>, 
  everRegurgitation: null as unknown as StateScalarAttribute<string>,
  everSourOrBitterTaste: null as unknown as StateScalarAttribute<string>,
  
  hasWorseLargeMeals: null as unknown as StateScalarAttribute<boolean>, 
  hasWorseLayingDown: null as unknown as StateScalarAttribute<boolean>,
  hasWorseAcidic: null as unknown as StateScalarAttribute<boolean>,

  hasReliefTums: null as unknown as StateScalarAttribute<boolean>, 
  hasReliefTolaids: null as unknown as StateScalarAttribute<boolean>,
  hasReliefMylanta: null as unknown as StateScalarAttribute<boolean>,
  hasReliefH2Blocker: null as unknown as StateScalarAttribute<boolean>,
  hasReliefProtonPumpInhibitor: null as unknown as StateScalarAttribute<boolean>,
  regularlyConsumeChicoryRootOrInulin: null as unknown as StateScalarAttribute<boolean>,
  regularlyConsumeSugarAlcohols: null as unknown as StateScalarAttribute<boolean>,
  everLiquidFromBottomToiletOrNot: null as unknown as StateScalarAttribute<boolean>,
  bestDescriptionAfterBowelMovement: null as unknown as StateScalarAttribute<string>,
  whatBothersYouMost: null as unknown as StateScalarAttribute<string>,

  hasEsophogealCancer: null as unknown as StateScalarAttribute<boolean>,

  everFrequentCoughing: null as unknown as StateScalarAttribute<string>, 
  everSwollenAndOrOrDiscoloredTongue: null as unknown as StateScalarAttribute<string>, 
  everFrequentNeedToClearThroat: null as unknown as StateScalarAttribute<string>, 
  everCankerSores: null as unknown as StateScalarAttribute<string>, 
  everExcessiveMucus: null as unknown as StateScalarAttribute<string>, 
  everHorseness: null as unknown as StateScalarAttribute<string>, 
  everTonguePain: null as unknown as StateScalarAttribute<string>, 
  hasHiatalHernia: null as unknown as StateScalarAttribute<boolean>, 
}

const STEP11_EAR_NULLIFIED_ATTRIBUTES = {
  everItchyEars: null as unknown as StateScalarAttribute<string>, 
  everDrainageFromEarOrWaxyBuildup: null as unknown as StateScalarAttribute<string>, 
  everEarAchesOrInfections: null as unknown as StateScalarAttribute<string>, 
  everRingingInEars: null as unknown as StateScalarAttribute<string>, 
}

const STEP12_HEAD_NULLIFIED_ATTRIBUTES = {
  everHeadaches: null as unknown as StateScalarAttribute<string>, 
  everFaintnessOrLightheadedness: null as unknown as StateScalarAttribute<string>, 
  everDizziness: null as unknown as StateScalarAttribute<string>, 
}

// const STEP10_NULLIFIED_ATTRIBUTES = {
//   everPoorMemory: null as unknown as StateScalarAttribute<string>, 
//   everDififficultyInMakingDecisions: null as unknown as StateScalarAttribute<string>, 
//   everConfusion: null as unknown as StateScalarAttribute<string>, 
//   everPoorConcentration: null as unknown as StateScalarAttribute<string>, 
//   everSlurredSpeech: null as unknown as StateScalarAttribute<string>, 
//   everPoorCoordination: null as unknown as StateScalarAttribute<string>,  
// }

const STEP13_HEART_NULLIFIED_ATTRIBUTES = {
  everIrregularHeartbeat: null as unknown as StateScalarAttribute<string>, 
  everRapidOrPoundingHeartbeat: null as unknown as StateScalarAttribute<string>, 
  everChestPain: null as unknown as StateScalarAttribute<string>, 
}

const STEP14_RESPIRATORY_NULLIFIED_ATTRIBUTES = {
  everShortnessOfBreath: null as unknown as StateScalarAttribute<string>, 
  everAsthmaBronchitis: null as unknown as StateScalarAttribute<string>, 
  everDifficultyBreathing: null as unknown as StateScalarAttribute<string>, 
  // Twice
  // everHyperactivity: null as unknown as StateScalarAttribute<string>, 
}

const STEP15_JOINTS_MUSCLES_NULLIFIED_ATTRIBUTES = {
  everPainsOrAchesInJoints: null as unknown as StateScalarAttribute<string>, 
  // everPainsOrAchesInMuscles: null as unknown as StateScalarAttribute<string>, 
  everArthritisJointSwelling: null as unknown as StateScalarAttribute<string>, 
  // everFeelingOfWeaknessOrTired: null as unknown as StateScalarAttribute<string>, 
  everStiffOrLimitationOfMovement: null as unknown as StateScalarAttribute<string>, 
}

const STEP16_HEALTH_HISTORY_NULLIFIED_ATTRIBUTES = {
  hasFamilyHistoryOfGiCancer: null as unknown as StateScalarAttribute<boolean>, 
  hasHypertension: null as unknown as StateScalarAttribute<boolean>, 
  hasHyperlipidemia: null as unknown as StateScalarAttribute<boolean>, 
  hasType2Diabetes: null as unknown as StateScalarAttribute<boolean>, 
  hasType1Diabetes: null as unknown as StateScalarAttribute<boolean>, 
  hasAlcoholUse: null as unknown as StateScalarAttribute<boolean>, 
  hasOpiodUse: null as unknown as StateScalarAttribute<boolean>, 
  hasBowelObstruction: null as unknown as StateScalarAttribute<boolean>, 
  hasObesity: null as unknown as StateScalarAttribute<boolean>, 
  hasRadiationToIntestines: null as unknown as StateScalarAttribute<boolean>, 
  hasTerminalIlealResection: null as unknown as StateScalarAttribute<boolean>, 
  hasIleocecalValveResection: null as unknown as StateScalarAttribute<boolean>, 
  hasAmyloidosis: null as unknown as StateScalarAttribute<boolean>, 
  hasScleroderma: null as unknown as StateScalarAttribute<boolean>, 
  hasBowelStrictureOrAdhesions: null as unknown as StateScalarAttribute<boolean>, 
  hasSmallBowelDiverticulosis: null as unknown as StateScalarAttribute<boolean>, 
  hasAbdominalSurgeryHistory: null as unknown as StateScalarAttribute<boolean>, 
  hasIleocectomyHistory: null as unknown as StateScalarAttribute<boolean>, 
  hasCommonVariableImmunodeficiency: null as unknown as StateScalarAttribute<boolean>, 
  hasIgaDeficency: null as unknown as StateScalarAttribute<boolean>, 
  hasHivOrAids: null as unknown as StateScalarAttribute<boolean>, 
  hasLongTermProtonPumpInhibiterUse: null as unknown as StateScalarAttribute<boolean>, 
  hasAtrophicGastritis: null as unknown as StateScalarAttribute<boolean>, 
  hasPancreaticDisease: null as unknown as StateScalarAttribute<boolean>, 
  hasLiverDisease: null as unknown as StateScalarAttribute<boolean>, 
  hasCholecystectomy: null as unknown as StateScalarAttribute<boolean>, 
  // hasDysphagia: null as unknown as StateScalarAttribute<boolean>, 
  
  // everRegurgitation: null as unknown as StateScalarAttribute<boolean>, 
  // everSourOrBitterTaste: null as unknown as StateScalarAttribute<boolean>, 
  // hasEsophogealCancer: null as unknown as StateScalarAttribute<boolean>, 
  
  everFeversOrChills: null as unknown as StateScalarAttribute<string>, 
  everLossOfAppetite: null as unknown as StateScalarAttribute<string>, 
  // hasHiatalHernia: null as unknown as StateScalarAttribute<boolean>, 
  everBoneFractureOrOsteoporosis: null as unknown as StateScalarAttribute<string>, 
  hasFamilyHistoryOfCeliac: null as unknown as StateScalarAttribute<boolean>, 
  hasThyroidDisease: null as unknown as StateScalarAttribute<boolean>,   
}

const STEP17_OTHER_NULLIFIED_ATTRIBUTES = {
  everFrequentIllness: null as unknown as StateScalarAttribute<string>, 
  everFrequentUrgeToUrinate: null as unknown as StateScalarAttribute<string>, 
  everGenitalItchOrDischarge: null as unknown as StateScalarAttribute<string>, 
  // everExcessiveSweatingAgain: null as unknown as StateScalarAttribute<string>, 
  hasHistoryOfAnemia: null as unknown as StateScalarAttribute<boolean>, 
  hasPalpableMassOnAbdomen: null as unknown as StateScalarAttribute<boolean>,   
  hasStructuralIssueStomachOrEsophagus: null as unknown as StateScalarAttribute<boolean>,   
}

const STEP18_GUT_HISTORY_NULLIFIED_ATTRIBUTES = {
  abdominalPainHowLong: null as unknown as StateScalarAttribute<string>, 
  hasPrimaryCareConversation: null as unknown as StateScalarAttribute<boolean>, 
  countPrimaryCareConversations: null as unknown as StateScalarAttribute<number>,
  hasGiDoctorConversation: null as unknown as StateScalarAttribute<boolean>,
  countGiConversations: null as unknown as StateScalarAttribute<number>,
}

export const QUESTIONNAIRE_ATTRIBUTE_NULLED: QuestionnaireAttributes = {
  ...STEP1_BASIC_NULLIFIED_ATTRIBUTES,
  ...STEP2_GUT_NULLIFIED_ATTRIBUTES,
  ...STEP3_BOWEL_NULLIFIED_ATTRIBUTES,
  ...STEP4_FOOD_WEIGHT_NULLIFIED_ATTRIBUTES,
  ... STEP5_EMOTIONAL_NULLIFIED_ATTRIBUTES,
  ... STEP6_ENERGY_SLEEP_NULLIFIED_ATTRIBUTES, 
  ... STEP7_SKIN_NULLIFIED_ATTRIBUTES,
  ... STEP8_NOSE_NULLIFIED_ATTRIBUTES,
  ... STEP9_EYE_NULLIFIED_ATTRIBUTES,
  ... STEP10_MOUTH_NULLIFIED_ATTRIBUTES,
  ... STEP11_EAR_NULLIFIED_ATTRIBUTES,
  ... STEP12_HEAD_NULLIFIED_ATTRIBUTES,
  ... STEP13_HEART_NULLIFIED_ATTRIBUTES,
  ... STEP14_RESPIRATORY_NULLIFIED_ATTRIBUTES,
  ... STEP15_JOINTS_MUSCLES_NULLIFIED_ATTRIBUTES,
  ... STEP16_HEALTH_HISTORY_NULLIFIED_ATTRIBUTES,
  ... STEP17_OTHER_NULLIFIED_ATTRIBUTES,
  ... STEP18_GUT_HISTORY_NULLIFIED_ATTRIBUTES,
  questionnaireSubmittedFlag: null as unknown as StateScalarAttribute<boolean>,   
}
Object.freeze(QUESTIONNAIRE_ATTRIBUTE_NULLED)


export const QUESTIONNAIRE_ATTRIBUTES_LIST: Array<string> = Object.keys(QUESTIONNAIRE_ATTRIBUTE_NULLED)
Object.freeze(QUESTIONNAIRE_ATTRIBUTES_LIST)

