export enum CurrentPreviousNeverCodeEnum { 
  UNSET = "unset",
  CURRENT = "current", 
  PREVIOUS = "previous", 
  NEVER = "never", 
}  

export function everYesCurrently( value: CurrentPreviousNeverCodeEnum | string ): boolean {
  return value === CurrentPreviousNeverCodeEnum.CURRENT
}

export function everCurrentOrPreviously( value: CurrentPreviousNeverCodeEnum | string ): boolean {
  return (value === CurrentPreviousNeverCodeEnum.CURRENT) || (value === CurrentPreviousNeverCodeEnum.PREVIOUS)
}

export function everPreviousOrNever( value: CurrentPreviousNeverCodeEnum | string ): boolean {
  return (value === CurrentPreviousNeverCodeEnum.PREVIOUS) || (value === CurrentPreviousNeverCodeEnum.NEVER)
}

export enum CurrentPreviousNeverLabelEnum { 
  UNSET = "- Choose -",
  CURRENT = "Current symptom", 
  PREVIOUS = "Past symptom", 
  NEVER = "Never", 
}  